import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress, Grid, Button } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { TextField } from "@material-ui/core";

function FAQDetail() {
  const [isReady, setIsReady] = useState(-1);
  const [faq, setFaq] = useState({
    id: "",
    faq_title: "",
    faq_content: "",
  });

  const onChangeTitle = (e) => {
    setFaq({ ...faq, faq_title: e.target.value });
  };
  const onChangeContent = (e) => {
    setFaq({ ...faq, faq_content: e.target.value });
  };

  let { id_faq } = useParams();

  useEffect(() => {
    getFaqDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFaqDetail = async () => {
    let requestURL = window.$apiURL.toString() + "faqs/" + id_faq;
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    setFaq(data);
    if (responsePromise.status === 404) {
      window.location.replace("/404");
    } else {
      if (localStorage.getItem("cbs_perform_token_is_super_admin") != null) {
        setIsReady(0);
      } else {
        setIsReady(1);
      }
    }
  };

  const updateFaq = async () => {
    let formData = new FormData();
    formData.set("faq_title", faq.faq_title);
    formData.set("faq_content", faq.faq_content);
    let requestURL = window.$apiURL.toString() + "faqs/coach/" + id_faq;
    let requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
      body: formData,
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    // eslint-disable-next-line no-unused-vars
    const data = await responsePromise.json();
    window.location.replace("/FAQ/" + id_faq);
  };

  const deleteFaq = async () => {
    let requestURL = window.$apiURL.toString() + "faqs/coach/" + id_faq;
    let requestOptions = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    // eslint-disable-next-line no-unused-vars
    const data = await responsePromise.json();
    window.location.replace("/FAQ");
  };

  if (isReady === -1) {
    return (
      <div style={{ paddingTop: "50%" }}>
        <CircularProgress style={{ color: "#857A73" }} />
      </div>
    );
  } else if (isReady === 0) {
    return (
      <div>
        <Container component="main" maxWidth="xs" className="divClients">
          <Typography
            component="h3"
            variant="h4"
            style={{ paddingBottom: "10%", paddingTop: "10%" }}
          >
            Mise à jour de la FAQ {faq.id}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant={"outlined"}
                fullWidth
                helperText={"Titre de la FAQ"}
                onChange={(e) => onChangeTitle(e)}
                value={faq.faq_title}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant={"outlined"}
                fullWidth
                helperText={"Contenu de la FAQ"}
                onChange={(e) => onChangeContent(e)}
                multiline
                rows={15}
                value={faq.faq_content}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={updateFaq}
                style={{ backgroundColor: "#857A73" }}
                variant={"contained"}
                color={"primary"}
              >
                Mettre à jour la FAQ
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={deleteFaq}
                style={{ backgroundColor: "#857A73" }}
                variant={"contained"}
                color={"primary"}
              >
                Supprimer la FAQ
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  } else if (isReady === 1) {
    return (
      <div>
        <Container component="main" maxWidth="xs" className="divClients">
          <Typography
            component="h4"
            variant="h4"
            style={{ paddingBottom: "20%", paddingTop: "10%" }}
          >
            {faq.faq_title}
          </Typography>
          <Typography
            variant="body1"
            align="left"
            style={{
              paddingBottom: "20%",
              paddingTop: "10%",
              whiteSpace: "pre-wrap",
            }}
          >
            {faq.faq_content}
          </Typography>
        </Container>
      </div>
    );
  } else {
    return (
      <div style={{ paddingTop: "50%" }}>
        <CircularProgress style={{ color: "#857A73" }} />
      </div>
    );
  }
}
export default FAQDetail;
