import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import "../../../css/Profile.css";
import Typography from "@material-ui/core/Typography";
import { Grid, MenuItem, TextField, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import b64toBlob from "b64-to-blob";
import pp_placeholder from "../../../Images/profile-placeholder.png";
import CircularProgress from "@material-ui/core/CircularProgress";

function ProfileCoach() {
  const [isReady, setIsReady] = useState(-1);

  const [coa_nom, setCoa_nom] = useState("");
  const [coa_nomErrorText, setCoa_nomErrorText] = useState("");
  const [coa_nomHasError, setCoa_nomHasError] = useState(false);

  const [coa_prenom, setCoa_prenom] = useState("");
  const [coa_prenomErrorText, setCoa_prenomErrorText] = useState("");
  const [coa_prenomHasError, setCoa_prenomHasError] = useState(false);

  const [email, setEmail] = useState("");
  const [emailErrorText, setEmailErrorText] = useState("");
  const [emailHasError, setEmailHasError] = useState(false);

  const [coa_ville, setCoa_ville] = useState("");

  const [coa_description, setCoa_description] = useState("");

  const [profilePicture, setProfilePicture] = useState("");
  const [profilePictureError, setProfilePictureError] = useState("");

  const [modifyProfile, setModifyProfile] = useState(false);

  function setModify() {
    setModifyProfile(true);
  }

  // Permet de stocker les modifications du champ nom
  const onChangeCoa_nom = (e) => {
    setCoa_nom(e.target.value);
    setCoa_nomHasError(false);
  };

  // Permet de stocker les modifications du champ prénom
  const onChangeCoa_prenom = (e) => {
    setCoa_prenom(e.target.value);
    setCoa_prenomHasError(false);
  };

  // Permet de stocker les modifications du champ email
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    setEmailHasError(false);
  };

  // Permet de stocker les modifications du champ ville
  const onChangeCoa_ville = (e) => {
    setCoa_ville(e.target.value);
  };

  // Permet de stocker les modifications du champ description
  const onChangeCoa_description = (e) => {
    setCoa_description(e.target.value);
  };

  // Permet de stocker l'image du coach
  const handlePPUpload = (e) => {
    e.preventDefault();
    setProfilePictureError("");
    if (!e.target.files[0].name.match(/.(jpg|jpeg|png)$/i)) {
      e.target.value = null;
      setProfilePictureError(
        "Le type de fichier ne peut être que .jpg .jpeg .png"
      );
    } else {
      if (e.target.files[0].size > 10e6) {
        setProfilePictureError(
          "La taille du fichier ne peut pas dépasser 10 mb"
        );
      } else {
        setProfilePicture(e.target.files[0].name);
      }
    }
  };

  // Permet de vérifier que les champs sont bien remplis correctement
  function validate() {
    let errors = false;
    if (coa_nom.length === 0) {
      errors = true;
      setCoa_nomHasError(true);
      setCoa_nomErrorText("Le nom de famille doit être rempli !");
    }
    if (coa_prenom.length === 0) {
      errors = true;
      setCoa_prenomHasError(true);
      setCoa_prenomErrorText("Le prénom doit être rempli !");
    }
    if (
      !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
        email.toString()
      )
    ) {
      errors = true;
      setEmailHasError(true);
      setEmailErrorText("Le format de l'email n'est pas correct !");
    }
    if (email.length === 0) {
      errors = true;
      setEmailHasError(true);
      setEmailErrorText("Email doit être rempli !");
    }
    return errors;
  }

  // Permet de sauvegarder les changements du profil dans la base de donnée
  const saveChanges = async (e) => {
    e.preventDefault();
    const errors = validate();
    if (!errors) {
      let updateProfileformData = document.getElementById("updateForm");
      let formData = new FormData(updateProfileformData);
      let errors = validate();
      if (!errors) {
        e.preventDefault();
        let requestURL = window.$apiURL.toString() + "coachs";
        let requestOptions = {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization:
              "Bearer " + localStorage.getItem("cbs_perform_token"),
          },
          body: formData,
        };
        const responsePromise = await fetch(requestURL, requestOptions);
        // eslint-disable-next-line no-unused-vars
        const data = await responsePromise.json();
        window.location.replace("/profilecoach");
      }
    }
  };

  const [coach, setCoach] = useState({});

  // Permet de récupérer les coachs disponibles
  const getCoach = async () => {
    let requestURL = window.$apiURL.toString() + "coachs";
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    setCoach(data);
    setCoa_nom(data.coa_nom);
    setCoa_prenom(data.coa_prenom);
    setEmail(data.email);
    setCoa_ville(data.coa_ville);
    setCoa_description(data.coa_description);
    setIsReady(1);
  };
  let pp;
  if (isReady === 1) {
    if (coach.file !== null) {
      let blob = b64toBlob(coach.file, "image/" + coach.fileExtention);
      pp = URL.createObjectURL(blob);
    } else {
      pp = null;
    }
  }

  useEffect(() => {
    getCoach();
  }, []);

  // Affichage de la page
  if (isReady === 1) {
    return (
      <div>
        <Container component="main" maxWidth="xs" className="divProfile">
          <CssBaseline />
          <Typography component="h1" variant="h3">
            Votre profil
          </Typography>
          <br />
          <br />
          <form id="updateForm" autoComplete="on">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  disabled={!modifyProfile}
                  autoComplete="lName"
                  name="coa_nom"
                  value={coa_nom}
                  helperText={
                    coa_nomHasError ? coa_nomErrorText : "Nom de Famille"
                  }
                  error={coa_nomHasError}
                  onChange={(e) => onChangeCoa_nom(e)}
                  required
                  fullWidth
                  id="lastName"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={!modifyProfile}
                  variant="outlined"
                  onChange={(e) => onChangeCoa_prenom(e)}
                  autoComplete="fName"
                  name="coa_prenom"
                  value={coa_prenom}
                  required
                  helperText={
                    coa_prenomHasError ? coa_prenomErrorText : "Prénom"
                  }
                  error={coa_prenomHasError}
                  fullWidth
                  id="firstName"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={!modifyProfile}
                  variant="outlined"
                  required
                  fullWidth
                  value={email}
                  onChange={(e) => onChangeEmail(e)}
                  helperText={emailHasError ? emailErrorText : "Email"}
                  error={emailHasError}
                  id="email"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={!modifyProfile}
                  variant="outlined"
                  onChange={(e) => onChangeCoa_ville(e)}
                  value={coa_ville}
                  helperText={"Votre ville"}
                  fullWidth
                  name="coa_ville"
                  select
                >
                  <MenuItem value={"geneve"}>Genève</MenuItem>
                  <MenuItem value={"lausanne"}>Lausanne</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={!modifyProfile}
                  variant="outlined"
                  required
                  fullWidth
                  multiline
                  rows={2}
                  value={coa_description}
                  onChange={(e) => onChangeCoa_description(e)}
                  helperText={"Vous en quelques mots"}
                  id="coa_description"
                  name="coa_description"
                />
              </Grid>
              <Grid item xs={12}>
                {coach.file !== null ? (
                  <img src={pp} width={"80%"} alt={""} />
                ) : (
                  <img src={pp_placeholder} width={"80%"} alt={""} />
                )}
              </Grid>
              <Grid item xs={3}>
                <input
                  disabled={!modifyProfile}
                  accept=".jpg, .jpeg, .png"
                  style={{ display: "none" }}
                  id="pp_id"
                  multiple
                  name="coa_profile_picture"
                  type="file"
                  onChange={(e) => handlePPUpload(e)}
                />
                <label htmlFor="pp_id">
                  <Button
                    disabled={!modifyProfile}
                    id="pp_button"
                    color="primary"
                    variant="contained"
                    component="span"
                    style={{
                      height: "56px",
                      width: "80px",
                      backgroundColor: "#857A73",
                    }}
                  >
                    Choisir
                  </Button>
                </label>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  disabled={!modifyProfile}
                  error={profilePictureError !== ""}
                  helperText={
                    profilePictureError !== null ? profilePictureError : ""
                  }
                  placeholder="Photo de profil"
                  variant="outlined"
                  value={profilePicture !== null ? profilePicture : " "}
                />
              </Grid>
              <Grid item xs={12}>
                {!modifyProfile ? (
                  <Button
                    style={{ backgroundColor: "#857A73" }}
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={setModify}
                  >
                    {" "}
                    Modifier le Profil
                  </Button>
                ) : (
                  <Button
                    style={{ backgroundColor: "#857A73" }}
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={(e) => saveChanges(e)}
                  >
                    {" "}
                    Enregister les modifications
                  </Button>
                )}
              </Grid>
              <Grid item xs={12}>
                <Link
                  to="/ChangePasswordCoach"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    style={{ backgroundColor: "#857A73" }}
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    {" "}
                    Changer le mot de passe
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link to={"/FacturesCoach/"} style={{ textDecoration: "none" }}>
                  <Button
                    style={{ backgroundColor: "#857A73" }}
                    variant={"contained"}
                    color={"primary"}
                    fullWidth
                  >
                    Vos factures
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </form>
        </Container>
      </div>
    );
  } else {
    return (
      <div style={{ paddingTop: "50%" }}>
        <CircularProgress style={{ color: "#857A73" }} />
      </div>
    );
  }
}
export default ProfileCoach;
