import React, { useState, useEffect } from "react";
import Collapsible from "react-collapsible";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import {
  CircularProgress,
  Grid,
  Button,
  Container,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { makeStyles } from "@material-ui/core/styles";

import AffichageObjectifs from "../Coach/DefiManagement/AffichageObjectifs";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function DefiDetail() {
  const [isReady, setIsReady] = useState(-1);
  // Bien écrire les noms des champs comme dans la BDD (sensible à la CASSE)
  const [defi, setDefi] = useState({
    DEF_ID: null,
    DEF_NOM: "",
    DEF_DESCRIPTION: "",
    DEF_NBSEMAINES: 1,
    DEF_CONSEILS: "",
    DEF_VISIBLE: 1,
    DEF_DATE_VISIBLE: "2021-01-01",
    COA_ID: null,
  });
  const [objectifs, setObjectifs] = useState([]);

  const [statistiques, setStatistiques] = useState([]);
  const [statsObjectifs, setStatsObjectifs] = useState([]);

  const classes = useStyles();

  let { defiID } = useParams();

  useEffect(() => {
    getDefiDetail();
    getObjectifsDefi();
    getStatsDefi();
    getStatsObjectif();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // requêtes pour accéder aux informations de la bdd
  const getDefiDetail = async () => {
    let requestURL = window.$apiURL.toString() + "defis/" + defiID;
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    setDefi(data);
    if (responsePromise.status === 404) {
      window.location.replace("/404");
    } else {
      if (localStorage.getItem("cbs_perform_token_is_super_admin") != null) {
        setIsReady(0);
      } else {
        setIsReady(1);
      }
    }
  };

  const getObjectifsDefi = async () => {
    let requestURL = window.$apiURL.toString() + "objectifs/coachs/" + defiID;
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    if (responsePromise.ok) {
      setObjectifs(data);
    }
  };

  const getStatsDefi = async () => {
    let requestURL =
      window.$apiURL.toString() + "defis/coachs/statistiquesClients/" + defiID;
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    setStatistiques(data);
    if (responsePromise.status === 404) {
      window.location.replace("/404");
    }
  };

  const getStatsObjectif = async () => {
    let requestURL =
      window.$apiURL.toString() +
      "defis/coachs/statistiquesObjectifs/" +
      defiID;
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    if (responsePromise.ok) {
      setStatsObjectifs(data);
    }
    if (responsePromise.status === 404) {
      window.location.replace("/404");
    }
  };

  const updateDefi = async () => {
    let formData = new FormData();
    for (let key in defi) {
      formData.set(key, defi[key]);
    }
    let requestURL = window.$apiURL.toString() + "defis/coachs/" + defiID;
    let requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
      body: formData,
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    // eslint-disable-next-line no-unused-vars
    const data = await responsePromise.json();
    if (responsePromise.ok) {
      updateObjectifs();
    }
  };

  const updateObjectifs = async () => {
    let formData = new FormData();
    let objectifsToSave = [];
    for (let objectifToSave in objectifs) {
      if (objectifs[objectifToSave].OBJ_SEMAINE <= defi.DEF_NBSEMAINES) {
        objectifsToSave.push(objectifs[objectifToSave]);
      }
    }
    formData.append("data", JSON.stringify(objectifsToSave));
    let requestURL =
      window.$apiURL.toString() +
      "objectifs/coachs/updateAllObjectifsDefi/" +
      defiID;
    let requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
      body: formData,
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    // eslint-disable-next-line no-unused-vars
    if (responsePromise.ok) {
      window.location.replace("/defis/" + defiID);
    }
  };

  const deleteDefi = async () => {
    let requestURL = window.$apiURL.toString() + "defis/coachs/" + defiID;
    let requestOptions = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    // eslint-disable-next-line no-unused-vars
    const data = await responsePromise.json();
    window.location.replace("/defis");
  };

  const inscriptionDefi = async () => {
    let requestURL = window.$apiURL.toString() + "inscription/" + defiID;
    let requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    // eslint-disable-next-line no-unused-vars
    const data = await responsePromise.json();
    window.location.replace("/user/defi/" + defiID);
  };

  const deinscriptionDefi = async () => {
    let requestURL = window.$apiURL.toString() + "desinscription/" + defiID;
    let requestOptions = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    // eslint-disable-next-line no-unused-vars
    const data = await responsePromise.json();
    window.location.replace("/defis/" + defiID);
  };

  const onChangeTitle = (e) => {
    // ...defi permet de reprendre les autres champs de l'objet Défi.
    setDefi({ ...defi, DEF_NOM: e.target.value });
  };

  const onChangeDescription = (e) => {
    setDefi({ ...defi, DEF_DESCRIPTION: e.target.value });
  };

  const onChangeSemaines = (e) => {
    setDefi({ ...defi, DEF_NBSEMAINES: e.target.value });
  };

  const onChangeConseils = (e) => {
    setDefi({ ...defi, DEF_CONSEILS: e.target.value });
  };

  const onChangeDate = (e) => {
    setDefi({ ...defi, DEF_DATE_VISIBLE: e.target.value });
  };

  if (isReady === -1) {
    return (
      <div style={{ paddingTop: "50%" }}>
        <CircularProgress style={{ color: "#857A73" }} />
      </div>
    );
  } else if (isReady === 0) {
    return (
      <div>
        <Container component="main" maxWidth="md" className="divClients">
          <Link
            to={"/defis/"}
            style={{
              textDecoration: "none",
              color: "#857A73",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <ArrowBackIcon />
          </Link>
          <Typography
            component="h3"
            variant="h4"
            style={{ paddingBottom: "5%" }}
          >
            Mise à jour du défi <br />
            {defi.DEF_NOM}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                variant={"outlined"}
                fullWidth
                label={"Nom"}
                onChange={(e) => onChangeTitle(e)}
                value={defi.DEF_NOM}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                variant={"outlined"}
                fullWidth
                label={"Description"}
                onChange={(e) => onChangeDescription(e)}
                multiline
                rows={5}
                value={defi.DEF_DESCRIPTION}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                variant={"outlined"}
                type={"number"}
                fullWidth
                inputProps={{ min: 1 }}
                label={"Nombre de semaines"}
                onChange={(e) => onChangeSemaines(e)}
                value={defi.DEF_NBSEMAINES}
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <AffichageObjectifs
              nbSemaines={defi.DEF_NBSEMAINES}
              objectifs={objectifs}
              setObjectifs={setObjectifs}
            />
            <Grid item xs={1}></Grid>
            <Grid item xs={12}>
              <TextField
                variant={"outlined"}
                fullWidth
                label={"Conseils (optionnels)"}
                onChange={(e) => onChangeConseils(e)}
                multiline
                rows={5}
                value={defi.DEF_CONSEILS}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                type={"date"}
                variant={"outlined"}
                fullWidth
                label={"Visible à partir de "}
                onChange={(e) => onChangeDate(e)}
                value={defi.DEF_DATE_VISIBLE}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={updateDefi}
                style={{ backgroundColor: "#857A73" }}
                variant={"contained"}
                color={"primary"}
              >
                Mettre à jour
              </Button>
            </Grid>
            <Grid item xs={12} style={{ paddingBottom: "5%" }}>
              <Button
                onClick={deleteDefi}
                style={{ backgroundColor: "#857A73" }}
                variant={"contained"}
                color={"primary"}
              >
                Supprimer
              </Button>
            </Grid>
          </Grid>

          <Collapsible
            trigger={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <Typography
                  component="h4"
                  variant="h5"
                  style={{ paddingBottom: "2%" }}
                >
                  Statistiques du défi <br />
                  {defi.DEF_NOM}
                </Typography>
                <ArrowDropDownIcon fontSize="large" />
              </div>
            }
          >
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="statistiques table">
                <TableHead style={{ backgroundColor: "#857A73" }}>
                  <TableRow>
                    <TableCell style={{ color: "white" }}>Client</TableCell>
                    <TableCell align="right" style={{ color: "white" }}>
                      Début de la participation
                    </TableCell>
                    <TableCell align="right" style={{ color: "white" }}>
                      Progression
                    </TableCell>
                    <TableCell align="right" style={{ color: "white" }}>
                      Points
                    </TableCell>
                    <TableCell align="right" style={{ color: "white" }}>
                      Objectifs inaccomplis
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {statistiques.map((row) => (
                    <TableRow key={row.cli_nom}>
                      <TableCell component="th" scope="row">
                        {row.cli_nom} {row.cli_prenom}
                      </TableCell>
                      <TableCell align="right">{row.PAR_DEBUT}</TableCell>
                      <TableCell align="right">
                        {row.jours}/{row.joursTotal}
                      </TableCell>
                      <TableCell align="right">
                        {row.nbPointsObtenus}/{row.nbPointsPossible}
                      </TableCell>
                      <TableCell align="right">
                        {row.objectifsEchoues}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Collapsible>
          <Collapsible
            trigger={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "2%",
                  paddingTop: "5%",
                  cursor: "pointer",
                }}
              >
                <Typography component="h4" variant="h5">
                  Statistiques des objectifs
                </Typography>
                <ArrowDropDownIcon fontSize="large" />
              </div>
            }
          >
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                aria-label="statistiques objectifs table"
              >
                <TableHead style={{ backgroundColor: "#857A73" }}>
                  <TableRow>
                    <TableCell style={{ color: "white" }}>Objectif</TableCell>
                    <TableCell align="right" style={{ color: "white" }}>
                      Accompli
                    </TableCell>
                    <TableCell align="right" style={{ color: "white" }}>
                      Nombre de fois proposé dans le défi
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {statsObjectifs.map((row) => (
                    <TableRow key={row.OBJ_NOM}>
                      <TableCell component="th" scope="row">
                        {row.OBJ_NOM}
                      </TableCell>
                      <TableCell align="right">{row.pourcentage}</TableCell>
                      <TableCell align="right">{row.propose}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Collapsible>
        </Container>
      </div>
    );
  } else if (isReady === 1) {
    return (
      <div>
        <Container component="main" maxWidth="xs" className="divClients">
          <Link
            to={"/defis/"}
            style={{
              textDecoration: "none",
              color: "#857A73",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <ArrowBackIcon />
          </Link>
          <Typography
            component="h4"
            variant="h4"
            style={{ paddingBottom: "20%", paddingTop: "10%" }}
          >
            {defi.DEF_NOM}
          </Typography>
          <Typography
            variant="body1"
            align="right"
            style={{
              paddingBottom: "20%",
              paddingTop: "10%",
              whiteSpace: "pre-wrap",
            }}
          >
            {defi.DEF_DESCRIPTION}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                onClick={inscriptionDefi}
                style={{ backgroundColor: "#857A73" }}
                variant={"contained"}
                color={"primary"}
              >
                Inscription
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={deinscriptionDefi}
                style={{ backgroundColor: "#857A73" }}
                variant={"contained"}
                color={"primary"}
              >
                Désincription
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  } else {
    return (
      <div style={{ paddingTop: "50%" }}>
        <CircularProgress style={{ color: "#857A73" }} />
      </div>
    );
  }
}
export default DefiDetail;
