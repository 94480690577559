import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import "../../../css/Facture.css";
import Typography from "@material-ui/core/Typography";
import { Grid, MenuItem, TextField, Button } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

function CreerFacture() {
  useEffect(() => {
    getUser();
    getCoach();
  }, []);

  //Données du client
  let { id_client } = useParams();
  const [userLastName, setUserLastName] = useState("");
  const [userName, setUserName] = useState("");

  //Données du coach
  const [coa_isSuperAdmin, setCoaIsSuperAdmin] = useState(false);

  //Données de la factures
  const [detail, setDetail] = useState("");
  const [detailHasError, setDetailHasError] = useState(false);
  const [detailErrorText, setDetailErrorText] = useState("");

  const [montant, setMontant] = useState("");
  const [montantHasError, setMontantHasError] = useState(false);
  const [montantErrorText, setMontantErrorText] = useState("");

  const [type, setType] = useState("");
  const [typeHasError, setTypeHasError] = useState(false);
  const [typeErrorText, setTypeErrorText] = useState("");

  // Gestion de la page de dialogue
  const [open, setOpen] = React.useState(false);

  // Ouvre une boîte de dialogue pour confirmer la création d'une facture
  const handleDialogOpen = () => {
    setOpen(true);
  };
  // Ferme une boîte de dialogue
  const handleDialogClose = () => {
    setOpen(false);
  };

  // Permet de stocker les changements du champs montant
  const onChangeMontant = (e) => {
    setMontant(e.target.value);
    setMontantHasError(false);
  };

  //Permet de stocker les changements du champs detail
  const onChangeDetail = (e) => {
    setDetail(e.target.value);
    //setDetailHasError(false);
  };

  //Permet de stocker les changements du champs type
  const onChangeType = (e) => {
    setType(e.target.value);
    setTypeHasError(false);
  };

  // Permet de récupérer les informations d'un client en particulier
  const getUser = async () => {
    let requestURL = window.$apiURL.toString() + "coachs/username/" + id_client;
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    if (responsePromise.ok) {
      setUserLastName(data.cli_nom);
      setUserName(data.cli_prenom);
    }
  };

  // Permet de récupérer les informations du coach connecté
  const getCoach = async () => {
    let requestURL = window.$apiURL.toString() + "coachs";
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    if (data.isSuperAdmin === 1) {
      setCoaIsSuperAdmin(true);
    }
  };

  // Permet de vérifier que les informations obligatoires sont correctement entrées
  function validate() {
    let errors = false;
    if (detail.length === 0) {
      errors = true;
      setDetailHasError(true);
      setDetailErrorText("Un montant doit être défini !");
    }
    if (montant < 0) {
      errors = true;
      setMontantHasError(true);
      setMontantErrorText("Montant erroné!");
    }
    if (type.length === 0) {
      errors = true;
      setTypeHasError(true);
      setTypeErrorText("Le champ type doit être rempli!");
    }
    return errors;
  }

  // Permet d'enregister la facture dans la base de données
  const savePayment = async (e) => {
    e.preventDefault();
    const errors = validate();
    if (!errors) {
      e.preventDefault();

      // Données nécessaires pour créer une facture
      let formData = new FormData();
      formData.set("cli_id", id_client);
      formData.set("detail", detail);
      formData.set("montant", montant);
      formData.set("type", type);

      let requestURL =
        window.$apiURL.toString() + "payments/coach/add/" + id_client;
      const requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
        },
        body: formData,
      };

      const responsePromise = await fetch(requestURL, requestOptions);
      const data = await responsePromise.json();
      if (responsePromise.ok) {
        // Création du User_coach_exchange avec l'id de la facture après la création de la facture
        let formDataFaUCE = new FormData();
        formDataFaUCE.set("subject", "payment");
        formDataFaUCE.set("cli_id", id_client);
        formDataFaUCE.set("pay_id", data.id);

        //Ajout de la facture dans un UCE
        let requestURLFactureUCE =
          window.$apiURL.toString() + "coachs/exchanges/facture";
        let requestOptionsFactureUCE = {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization:
              "Bearer " + localStorage.getItem("cbs_perform_token"),
          },
          body: formDataFaUCE,
        };
        const responsePromiseFactureUCE = await fetch(
          requestURLFactureUCE,
          requestOptionsFactureUCE
        );
        if (responsePromiseFactureUCE.ok) {
          window.location.replace("/FacturesClient/" + id_client);
        }
      }
    }
    handleDialogClose();
  };

  return (
    <div>
      <Container component="main" maxWidth="xs" className="divFacture">
        <CssBaseline />
        <Typography
          component="h1"
          variant="h4"
          style={{ paddingBottom: "10%" }}
        >
          Création d'une facture
        </Typography>
        <Typography
          component="h1"
          variant="h5"
          style={{ paddingBottom: "10%" }}
        >
          Au nom de {userName} {userLastName}
        </Typography>

        <form id="paymentForm" autoComplete="on">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                name="detail"
                onChange={(e) => onChangeDetail(e)}
                value={detail}
                required
                fullWidth
                id="detail"
                label="Détail de la facture"
                helperText={detailHasError ? detailErrorText : ""}
                error={detailHasError}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                onChange={(e) => onChangeMontant(e)}
                name="montant"
                value={montant}
                type={"number"}
                required
                helperText={montantHasError ? montantErrorText : ""}
                error={montantHasError}
                fullWidth
                id="montant"
                label="Montant"
              />
            </Grid>
            {coa_isSuperAdmin ? (
              <Grid item xs={12}>
                <TextField
                  variant={"outlined"}
                  select
                  onChange={(e) => onChangeType(e)}
                  label={"Type paiement"}
                  id="type"
                  helperText={typeErrorText !== "" ? typeErrorText : ""}
                  error={typeErrorText !== ""}
                >
                  <MenuItem value={"paypal"}>Paypal</MenuItem>
                  <MenuItem value={"facture"}>Facture</MenuItem>
                  <MenuItem value={"other"}>Autre</MenuItem>
                </TextField>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <TextField
                  variant={"outlined"}
                  select
                  onChange={(e) => onChangeType(e)}
                  label={"Type paiement"}
                  id="type"
                  helperText={typeErrorText !== "" ? typeErrorText : ""}
                  error={typeErrorText !== ""}
                >
                  <MenuItem value={"facture"}>Facture</MenuItem>
                  <MenuItem value={"other"}>Autre</MenuItem>
                </TextField>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                style={{ backgroundColor: "#857A73" }}
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleDialogOpen}
              >
                {" "}
                Créer la facture
              </Button>
              <Dialog
                open={open}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ maxWidth: "100%" }}
              >
                <DialogContent
                  style={{
                    maxWidth: "90%",
                    paddingRight: "5%",
                    paddingLeft: "5%",
                  }}
                >
                  <DialogContentText
                    id="alert-dialog-description"
                    style={{ color: "black", fontSize: 20 }}
                  >
                    Voulez-vous créer cette facture?
                  </DialogContentText>
                </DialogContent>
                <DialogActions
                  style={{
                    maxWidth: "92%",
                    paddingRight: "4%",
                    paddingLeft: "4%",
                  }}
                >
                  <Button onClick={handleDialogClose} color="primary" autofocus>
                    Annuler
                  </Button>
                  <Button onClick={savePayment} style={{ color: "#009900" }}>
                    Oui
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
            <Grid item xs={12}>
              <Link
                to={"/DetailClient/" + id_client}
                style={{ textDecoration: "none" }}
              >
                <Button
                  style={{ backgroundColor: "#857A73" }}
                  variant={"contained"}
                  color={"primary"}
                  fullWidth
                >
                  Retour
                </Button>
              </Link>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
}

export default CreerFacture;
