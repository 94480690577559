import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { sha256 } from "js-sha256";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
  },
});
export class NavDrawer extends React.Component {
  //Permet de déconnecter l'utilisateur
  logoutHandler() {
    let URL = window.$apiURL;
    let rqst = URL + "logout";
    fetch(rqst, {
      crossDomain: true,
      method: "POST",
    }).then((response) => {
      localStorage.removeItem("cbs_perform_token");
      localStorage.removeItem("cbs_perform_token_is_coach");
      localStorage.removeItem("cbs_perform_token_is_super_admin");
      window.location.replace("/");
    });
  }

  render() {
    if (localStorage.getItem("cbs_perform_token") != null) {
      if (localStorage.getItem("cbs_perform_token_is_coach") !== null) {
        if (
          localStorage.getItem("cbs_perform_token_is_coach") ===
          sha256(localStorage.getItem("cbs_perform_token").substring(162, 243))
        ) {
          if (
            localStorage.getItem("cbs_perform_token_is_super_admin") !== null &&
            localStorage.getItem("cbs_perform_token_is_super_admin") ===
              sha256(
                localStorage.getItem("cbs_perform_token").substring(606, 750)
              )
          ) {
            //logged coach menu
            return (
              <Drawer
                anchor="left"
                open={this.props.drawerOpened}
                onClose={this.props.toggleDrawer(false)}
              >
                <div
                  style={{
                    overflow: "hidden",
                    height: "100%",
                    paddingTop: "10%",
                    background: "#181001",
                    opacity: "100%",
                    width: "100%",
                    textAlign: "center",
                    overflowX: "hidden",
                  }}
                  onClick={this.props.toggleDrawer(false)}
                  onKeyDown={this.props.toggleDrawer(false)}
                >
                  <ThemeProvider theme={theme}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} style={{ disableRipple: true }}>
                        <Button
                          color="primary"
                          onClick={() => window.location.replace("/")}
                          style={{
                            backgroundColor: "#857A73",
                            width: "80%",
                            opacity: "80%",
                            disableRipple: true,
                          }}
                        >
                          Accueil
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          color="primary"
                          onClick={() =>
                            window.location.replace("/ProfileCoach")
                          }
                          style={{
                            backgroundColor: "#857A73",
                            width: "80%",
                            opacity: "80%",
                            disableRipple: true,
                          }}
                        >
                          Profil
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          color="primary"
                          onClick={() => window.location.replace("/Clients")}
                          style={{
                            backgroundColor: "#857A73",
                            width: "80%",
                            opacity: "80%",
                            disableRipple: true,
                          }}
                        >
                          Clients
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          color="primary"
                          onClick={() => window.location.replace("/Requests")}
                          style={{
                            backgroundColor: "#857A73",
                            width: "80%",
                            opacity: "80%",
                            disableRipple: true,
                          }}
                        >
                          Demandes
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          color="primary"
                          onClick={() => window.location.replace("/management")}
                          style={{
                            backgroundColor: "#857A73",
                            width: "80%",
                            opacity: "80%",
                            disableRipple: true,
                          }}
                        >
                          Admin
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          onClick={this.logoutHandler}
                          color="primary"
                          style={{
                            backgroundColor: "#857A73",
                            width: "80%",
                            opacity: "80%",
                            disableRipple: true,
                          }}
                        >
                          Déconnexion
                        </Button>
                      </Grid>
                    </Grid>
                  </ThemeProvider>
                </div>
              </Drawer>
            );
          } else {
            //logged coach menu
            return (
              <Drawer
                anchor="left"
                open={this.props.drawerOpened}
                onClose={this.props.toggleDrawer(false)}
                width="80%"
              >
                <div
                  style={{
                    overflow: "hidden",
                    height: "100%",
                    paddingTop: "10%",
                    background: "#181001",
                    opacity: "100%",
                    width: "100%",
                    textAlign: "center",
                    overflowX: "hidden",
                  }}
                  onClick={this.props.toggleDrawer(false)}
                  onKeyDown={this.props.toggleDrawer(false)}
                >
                  <ThemeProvider theme={theme}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} style={{ disableRipple: true }}>
                        <Button
                          color="primary"
                          onClick={() => window.location.replace("/")}
                          style={{
                            backgroundColor: "#857A73",
                            width: "80%",
                            opacity: "80%",
                            disableRipple: true,
                          }}
                        >
                          Accueil
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          color="primary"
                          onClick={() =>
                            window.location.replace("/ProfileCoach")
                          }
                          style={{
                            backgroundColor: "#857A73",
                            width: "80%",
                            opacity: "80%",
                            disableRipple: true,
                          }}
                        >
                          Profil
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          color="primary"
                          onClick={() => window.location.replace("/Clients")}
                          style={{
                            backgroundColor: "#857A73",
                            width: "80%",
                            opacity: "80%",
                            disableRipple: true,
                          }}
                        >
                          Clients
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          color="primary"
                          onClick={() => window.location.replace("/Requests")}
                          style={{
                            backgroundColor: "#857A73",
                            width: "80%",
                            opacity: "80%",
                            disableRipple: true,
                          }}
                        >
                          Demandes
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          onClick={this.logoutHandler}
                          color="primary"
                          style={{
                            backgroundColor: "#857A73",
                            width: "80%",
                            opacity: "80%",
                            disableRipple: true,
                          }}
                        >
                          Déconnexion
                        </Button>
                      </Grid>
                    </Grid>
                  </ThemeProvider>
                </div>
              </Drawer>
            );
          }
        } else {
          //unvalidated coach menu
          return (
            <Drawer
              anchor="left"
              open={this.props.drawerOpened}
              onClose={this.props.toggleDrawer(false)}
              width="80%"
            >
              <div
                style={{
                  overflow: "hidden",
                  height: "100%",
                  paddingTop: "10%",
                  background: "#181001",
                  opacity: "100%",
                  width: "100%",
                  textAlign: "center",
                  overflowX: "hidden",
                }}
                onClick={this.props.toggleDrawer(false)}
                onKeyDown={this.props.toggleDrawer(false)}
              >
                <ThemeProvider theme={theme}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Button
                        color="primary"
                        onClick={() => window.location.replace("/")}
                        style={{
                          backgroundColor: "#857A73",
                          width: "80%",
                          opacity: "80%",
                          disableRipple: true,
                        }}
                      >
                        Accueil
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        color="primary"
                        onClick={() => window.location.replace("/Profile")}
                        style={{
                          backgroundColor: "#857A73",
                          width: "80%",
                          opacity: "80%",
                          disableRipple: true,
                        }}
                      >
                        Profil
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        color="primary"
                        onClick={() => window.location.replace("/Plannings")}
                        style={{
                          backgroundColor: "#857A73",
                          width: "80%",
                          opacity: "80%",
                          disableRipple: true,
                        }}
                      >
                        Plans
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Link to="/Contact" style={{ textDecoration: "none" }}>
                        <Button
                          color="primary"
                          style={{
                            backgroundColor: "#857A73",
                            width: "80%",
                            opacity: "80%",
                            disableRipple: true,
                          }}
                        >
                          Contact
                        </Button>
                      </Link>
                    </Grid>
                    <Grid item xs={12}>
                      <Link to="/FAQ" style={{ textDecoration: "none" }}>
                        <Button
                          color="primary"
                          style={{
                            backgroundColor: "#857A73",
                            width: "80%",
                            opacity: "80%",
                            disableRipple: true,
                          }}
                        >
                          FAQ
                        </Button>
                      </Link>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        onClick={this.logoutHandler}
                        color="primary"
                        style={{
                          backgroundColor: "#857A73",
                          width: "80%",
                          opacity: "80%",
                          disableRipple: true,
                        }}
                      >
                        Déconnexion
                      </Button>
                    </Grid>
                  </Grid>
                </ThemeProvider>
              </div>
            </Drawer>
          );
        }
      } else {
        //logged user menu
        return (
          <Drawer
            anchor="left"
            open={this.props.drawerOpened}
            onClose={this.props.toggleDrawer(false)}
          >
            <div
              style={{
                overflow: "hidden",
                height: "100%",
                paddingTop: "10%",
                background: "#181001",
                opacity: "100%",
                width: "100%",
                textAlign: "center",
                overflowX: "hidden",
              }}
              onClick={this.props.toggleDrawer(false)}
              onKeyDown={this.props.toggleDrawer(false)}
            >
              <ThemeProvider theme={theme}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Button
                      color="primary"
                      onClick={() => window.location.replace("/")}
                      style={{
                        backgroundColor: "#857A73",
                        width: "80%",
                        opacity: "80%",
                        disableRipple: true,
                      }}
                    >
                      Accueil
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      color="primary"
                      onClick={() => window.location.replace("/Profile")}
                      style={{
                        backgroundColor: "#857A73",
                        width: "80%",
                        opacity: "80%",
                        disableRipple: true,
                      }}
                    >
                      Profil
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      color="primary"
                      onClick={() => window.location.replace("/Plannings")}
                      style={{
                        backgroundColor: "#857A73",
                        width: "80%",
                        opacity: "80%",
                        disableRipple: true,
                      }}
                    >
                      Plans
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Link to="/Contact" style={{ textDecoration: "none" }}>
                      <Button
                        color="primary"
                        style={{
                          backgroundColor: "#857A73",
                          width: "80%",
                          opacity: "80%",
                          disableRipple: true,
                        }}
                      >
                        Contact
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Link to="/FAQ" style={{ textDecoration: "none" }}>
                      <Button
                        color="primary"
                        style={{
                          backgroundColor: "#857A73",
                          width: "80%",
                          opacity: "80%",
                          disableRipple: true,
                        }}
                      >
                        FAQ
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      onClick={this.logoutHandler}
                      color="primary"
                      style={{
                        backgroundColor: "#857A73",
                        width: "80%",
                        opacity: "80%",
                        disableRipple: true,
                      }}
                    >
                      Déconnexion
                    </Button>
                  </Grid>
                </Grid>
              </ThemeProvider>
            </div>
          </Drawer>
        );
      }
    } else {
      //not logged user menu
      return (
        <Drawer
          anchor="left"
          open={this.props.drawerOpened}
          onClose={this.props.toggleDrawer(false)}
        >
          <div
            style={{
              overflow: "hidden",
              height: "100%",
              paddingTop: "10%",
              background: "#181001",
              opacity: "100%",
              width: "100%",
              textAlign: "center",
              overflowX: "hidden",
            }}
            onClick={this.props.toggleDrawer(false)}
            onKeyDown={this.props.toggleDrawer(false)}
          >
            <ThemeProvider theme={theme}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    onClick={() => window.location.replace("/")}
                    style={{
                      backgroundColor: "#857A73",
                      width: "80%",
                      opacity: "80%",
                      disableRipple: true,
                    }}
                  >
                    Accueil
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/Login" style={{ textDecoration: "none" }}>
                    <Button
                      color="primary"
                      style={{
                        backgroundColor: "#857A73",
                        width: "80%",
                        opacity: "80%",
                        disableRipple: true,
                      }}
                    >
                      Connexion
                    </Button>
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/Register" style={{ textDecoration: "none" }}>
                    <Button
                      color="primary"
                      style={{
                        backgroundColor: "#857A73",
                        width: "80%",
                        opacity: "80%",
                        disableRipple: true,
                      }}
                    >
                      Inscription
                    </Button>
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/InfoPlans" style={{ textDecoration: "none" }}>
                    <Button
                      color="primary"
                      style={{
                        backgroundColor: "#857A73",
                        width: "80%",
                        opacity: "80%",
                        disableRipple: true,
                      }}
                    >
                      Abonnements
                    </Button>
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/Contact" style={{ textDecoration: "none" }}>
                    <Button
                      color="primary"
                      style={{
                        backgroundColor: "#857A73",
                        width: "80%",
                        opacity: "80%",
                        disableRipple: true,
                      }}
                    >
                      Contact
                    </Button>
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/FAQ" style={{ textDecoration: "none" }}>
                    <Button
                      color="primary"
                      style={{
                        backgroundColor: "#857A73",
                        width: "80%",
                        opacity: "80%",
                        disableRipple: true,
                      }}
                    >
                      FAQ
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </ThemeProvider>
          </div>
        </Drawer>
      );
    }
  }
}
