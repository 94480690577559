import React, {useEffect, useState} from "react";
import {Grid, MenuItem, TextField} from "@material-ui/core";
import '../../../css/Profile.css';
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import CheckIcon from '@material-ui/icons/Check';
import HearingIcon from '@material-ui/icons/Hearing';
import { Icon } from '@iconify/react';
import trophyIcon from '@iconify/icons-mdi/trophy';
import TuneIcon from '@material-ui/icons/Tune';
import {Card} from "material-ui";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Button from "@material-ui/core/Button";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ClearIcon from '@material-ui/icons/Clear';
import Slide from "@material-ui/core/Slide";
import b64toBlob from "b64-to-blob";
import CircularProgress from "@material-ui/core/CircularProgress";


function ChangeSubscription() {


    useEffect(()=>{
        getUser({})
    }, []);

    //-1 initial state waiting for actual membership type load
    //0 is not vip
    //1 is vip

    const [isVip, setIsVip] = useState(-1)
    const [submitButton, setSubmitButton] = useState('Soumettez votre demande')

    // Permet de récupérer les informations de l'utilisateur connecté
    const getUser = async () =>{
        let requestURL = window.$apiURL.toString()+'users'
        let requestOptions = {
            method : 'GET',
            headers : {
                'Accept' : 'application/json',
                'Authorization' : 'Bearer '+localStorage.getItem('cbs_perform_token'),
            },
        }
        const responsePromise = await fetch(requestURL, requestOptions);
        const data = await responsePromise.json();
        if (data.cli_abonnement === 0){
            setIsVip(0)
        }else if (data.cli_abonnement === 2){
            setIsVip(1)
        }else if(data.cli_abonnement === 3){
            setIsVip(-1);
        }
    }

    const [coa_ville, setCoa_ville] = useState('')
    const [coa_villeError, setCoa_villeError] = useState('')
    const [coach, setCoach] = useState({
        'id' : '',
        'coa_nom' : '',
        'coa_prenom' : '',
        'coa_description' : '',
        'coa_profile_picture' : '',
        'coa_ranking' : '',
        'coa_profile_picture_ext' : '',
    });
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [coachIsSet, setCoachIsSet] = useState(false);
    const [checked, setChecked] = useState(true);
    const [direction, setDirection] = useState('left');
    const [image, setImage] = useState(null);
    const [userComments, setUserComments] = useState('');
    const [userCommentsError, setUserCommentsError] = useState('');

    //Permet de stocker les changements du champs coa_ville
    const onChangeVille = async (e) =>{
        setCoa_villeError('')
        setCoa_ville(e.target.value)
        setCoachIsSet(false)
        getCoachs(e.target.value);
    }

    // Permet de stocker l'écriture d'un nouveau message
    const onChangeUserComments = (e) =>{
        setUserCommentsError('')
        setUserComments(e.target.value)
    }

    // Permet de charger la liste de tous les coachs de la ville sélectionnée
    const getCoachs = async (value) =>{
        let requestURL = window.$apiURL.toString()+"users/coachs?ville="+value;
        let requestOptions = {
            method : 'GET',
            headers : {
                'Accept' : 'application/json',
                'Authorization' : 'Bearer '+localStorage.getItem('cbs_perform_token'),
            },
        }
        const responsePromise = await fetch(requestURL, requestOptions)
        const data = await responsePromise.json();
        setTotalPages(data.total)
        if (data['data'].length !== 0){
            if (data['data'][0].coa_profile_picture !== null){
                let blob = b64toBlob(data['data'][0].coa_profile_picture, 'application/'+data['data'][0].coa_profile_picture_ext)
                setImage(URL.createObjectURL(blob))
            }
            setCoach(data['data'][0])
            setCoachIsSet(true)
        }
    }
    // Charge le coach précédent
    const prevCoach = async (value) => {
        setDirection('right')
        setChecked((prev) => !prev)
        setCurrentPage(currentPage-1);
        let requestURL = window.$apiURL.toString()+"users/coachs?ville="+coa_ville+"&page="+value;
        let requestOptions = {
            method : 'GET',
            headers : {
                'Accept' : 'application/json',
                'Authorization' : 'Bearer '+localStorage.getItem('cbs_perform_token'),
            },
        }
        const responsePromise = await fetch(requestURL, requestOptions);
        const data = await responsePromise.json();
        setCoach(data['data'][0])
        if (data['data'][0].coa_profile_picture !== null){
            let blob = b64toBlob(data['data'][0].coa_profile_picture, 'application/'+data['data'][0].coa_profile_picture_ext)
            setImage(URL.createObjectURL(blob))
        }
        setDirection('left')
        setChecked((prev) => !prev)
    }
    // Charge le coach suivant
    const nextCoach = async (value) => {
        setDirection('left')
        setChecked((prev) => !prev)
        setCurrentPage(currentPage+1);
        let requestURL = window.$apiURL.toString()+"users/coachs?ville="+coa_ville+"&page="+value;
        let requestOptions = {
            method : 'GET',
            headers : {
                'Accept' : 'application/json',
                'Authorization' : 'Bearer '+localStorage.getItem('cbs_perform_token'),
            },
        }
        const responsePromise = await fetch(requestURL, requestOptions);
        const data = await responsePromise.json();
        setCoach(data['data'][0])
        if (data['data'][0].coa_profile_picture !== null){
            let blob = b64toBlob(data['data'][0].coa_profile_picture, 'application/'+data['data'][0].coa_profile_picture_ext)
            setImage(URL.createObjectURL(blob))
        }
        setDirection('right')
        setChecked((prev) => !prev)
    }

    // Permet de vérifier que l'utilisateur a bien entré ses objectifs, et choisi un coach
    function validate(){
        let errors = false;
        if (userComments.length === 0){
            errors = true;
            setUserComments('Veuillez indiquer vos objectifs svp')
        }
        if (!coachIsSet){
            errors = true;
            setCoa_villeError('Veuillez choisir votre ville pour choisir un coach')
        }
        return errors;
    }

    //Enregistre la souscription dans la base de données
    const handleSubscriptionChangeVip = async () => {
        let errors = validate();
        if (!errors){
            setSubmitButton(<CircularProgress size={25} style={{color : 'white'}}/>)
            let subscriptionChangeFormData = new FormData();
            subscriptionChangeFormData.set('coa_id', coach.id);
            subscriptionChangeFormData.set('subject', 'vip_subscription');
            subscriptionChangeFormData.set('message', userComments);
            let requestURL = window.$apiURL.toString()+"users/exchanges/create_new_coach_exchange"
            let requestOptions = {
                method : 'POST',
                headers : {
                    'Accept' : 'application/json',
                    'Authorization' : 'Bearer '+localStorage.getItem('cbs_perform_token'),
                },
                body : subscriptionChangeFormData
            }
            const responsePromise = await fetch(requestURL, requestOptions);
            // eslint-disable-next-line no-unused-vars
            const data = await responsePromise.json();
            if(responsePromise.ok){
                window.location.replace("/SubscriptionRequestSubmitted");
            }
        }
    }

    // Permet de repasser à l'abonnement gratuit
    const handleSubscriptionChangeFree = async () => {
        let requestURL = window.$apiURL.toString()+"users/revert_to_free"
        let requestOptions = {
            method : 'PUT',
            headers : {
                'Accept' : 'application/json',
                'Authorization' : 'Bearer '+localStorage.getItem('cbs_perform_token'),
            },
        }
        const responsePromise = await fetch(requestURL, requestOptions);
        if (responsePromise.ok){
            window.location.replace("/Profile")
        }
    }

    // Permet de changer l'affichage selon le type d'abonnement du client
    if (isVip === -1){
        return (
            <div style={{paddingTop : '50%'}}><CircularProgress style={{color : '#857A73'}}/></div>
        )
    }else if (isVip === 0){
        return(
            <div>
                <Container component="main" maxWidth="xs" className="divProfile">
                    <Typography component="h3" variant="h5" style={{paddingBottom : '20%'}}>
                        Passez à l'abonnment VIP dès maintenant !
                    </Typography>
                    <Typography component="h4" variant="h6" style={{paddingBottom : '15%'}}>
                        Vos avantages :
                    </Typography>
                    <Grid container spacing={4} >
                        <Grid item xs={3} >
                            <TuneIcon style={{margin: "11px"}}/>
                        </Grid>
                        <Grid item xs={6}>
                            Un planning entièrement personnalisé
                        </Grid>
                        <Grid item xs={3}>
                            <CheckIcon style={{margin: "11px"}}/>
                        </Grid>
                        <Grid item xs={3}>
                            <HearingIcon style={{margin: "11px"}}/>
                        </Grid>
                        <Grid item xs={6}>
                            Un coach à votre écoute
                        </Grid>
                        <Grid item xs={3}>
                            <CheckIcon style={{margin: "11px"}}/>
                        </Grid>
                        <Grid item xs={3}>
                            <Icon icon={trophyIcon} style={{margin: "14px"}}/>
                        </Grid>
                        <Grid item xs={6}>
                            La garantie d'atteindre vos objectifs
                        </Grid>
                        <Grid item xs={3}>
                            <CheckIcon style={{margin: "11px"}}/>
                        </Grid>
                        <Grid item xs={12}>
                            Quelle est votre ville ?
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                select
                                variant={"outlined"}
                                onChange={e => onChangeVille(e)}
                                name="coa_ville"
                                value={coa_ville}
                                error={coa_villeError !== ''}
                                helperText={coa_villeError !== '' ? coa_villeError : ''}
                            >
                                <MenuItem value={'geneve'}>Genève</MenuItem>
                                <MenuItem value={'lausanne'}>Lausanne</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            {coachIsSet ?
                                <Button
                                    disabled={currentPage-1 === 0}
                                    onClick={() => prevCoach(currentPage-1)}
                                    style={{
                                        minHeight : '36px',
                                        minWidth : '36px',
                                        borderRadius : 25
                                    }}>
                                    <NavigateBeforeIcon/>
                                </Button>
                            :
                                <Button
                                    disabled
                                    style={{
                                        minHeight : '36px',
                                        minWidth : '36px',
                                        borderRadius : 25
                                    }}>
                                    <NavigateBeforeIcon/>
                                </Button>
                            }

                        </Grid>
                        <Grid item xs={6} style={{paddingTop : '22px', paddingBottom : '22px'}}>
                            Choisissez votre coach :
                        </Grid>
                        <Grid item xs={3}>
                            {coachIsSet ?
                                <Button
                                    disabled={currentPage+1 === totalPages+1}
                                    onClick={() => nextCoach(currentPage+1)}
                                    style={{
                                        minHeight : '36px',
                                        minWidth : '36px',
                                        borderRadius : 25
                                    }}>
                                    <NavigateNextIcon/>
                                </Button>
                                :
                                <Button
                                    disabled
                                    style={{
                                        minHeight : '36px',
                                        minWidth : '36px',
                                        borderRadius : 25
                                    }}>
                                    <NavigateNextIcon/>
                                </Button>
                            }
                        </Grid>
                        {coachIsSet ?
                            <Grid item xs={12} style={{paddingBottom : '10%', minHeight : '500px'}}>
                                <Slide direction={direction} in={checked}  mountOnEnter>
                                    <MuiThemeProvider>
                                        <Card>
                                            <CardHeader
                                                title={coach.coa_nom+" "+coach.coa_prenom}
                                                subheader={coach.coa_description}
                                                style={{ padding : 0, paddingTop : '10px', paddingBottom : '10px'}}
                                            />
                                            {coach.coa_profile_picture !== null ?
                                                <CardMedia
                                                    style={{height: 0, paddingTop: '56.25%'}}

                                                    image={image}
                                                />
                                                :
                                                <CardMedia
                                                    style={{height: 0, paddingTop: '56.25%'}}

                                                    image="https://images.squarespace-cdn.com/content/v1/519d1bb9e4b09e4694bff22c/1582149849802-ZC7E0T9PBX3Q9SF7AV23/ke17ZwdGBToddI8pDm48kP06O0_IHyRXSOOiqwgWaApZw-zPPgdn4jUwVcJE1ZvWEtT5uBSRWt4vQZAgTJucoTqqXjS3CfNDSuuf31e0tVEsL0EX72Q6S7TgfQYQBQpkz5xM6Qt8VXd_xJGg_ziCFib8BodarTVrzIWCp72ioWw/profile-placeholder.png"
                                                />

                                            }

                                            <CardContent style={{padding : 0, paddingTop : '10px', paddingBottom : '10px'}}>
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                    Ville : {coa_ville}
                                                </Typography>
                                                {/* <Box component="fieldset" mb={3} borderColor="transparent" style={{paddingTop : '10px'}}>
                                                    <Typography variant="body2" color="textSecondary" component="p">Évaluations</Typography>
                                                    <Rating name="read-only" value={coach.coa_ranking} precision={0.5} readOnly size="small" />
                                                </Box> */}
                                            </CardContent>
                                        </Card>
                                    </MuiThemeProvider>
                                </Slide>
                            </Grid>

                            :
                            <Grid item xs={12} style={{paddingBottom : '10%', minHeight : '500px'}}/>
                        }
                        <Grid item xs={12} style={{paddingBottom : '10%'}}>
                            <TextField
                                multiline
                                rows={15}
                                variant="outlined"
                                label="Parlez nous de vos objectifs"
                                name="user_comments"
                                onChange={(e) => onChangeUserComments(e)}
                                error={userCommentsError !== ''}
                                helperText={userCommentsError !== '' ? userCommentsError : ''}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            Laissez nous votre numéro de téléphone si vous souhaitez être rappelé !
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom : '10%'}}>
                            <Button
                                style={{backgroundColor : '#857A73'}}
                                variant={"contained"}
                                color={"primary"}
                                fullWidth
                                onClick={() => handleSubscriptionChangeVip()}
                            >
                                {submitButton}
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        )
    }else if (isVip === 1){
        return (
            <div>
                <Container component="main" maxWidth="xs" className="divProfile">
                    <Typography component="h3" variant="h5" style={{paddingBottom : '20%'}}>
                        Êtes-vous sur de vouloir annuler votre abonnement VIP ?
                    </Typography>
                    <Typography component="h4" variant="h6" style={{paddingBottom : '15%'}}>
                        Vous n'aurez plus accès à ces avantages :
                    </Typography>
                    <Grid container spacing={4} >
                        <Grid item xs={3} >
                            <TuneIcon style={{margin: "11px"}}/>
                        </Grid>
                        <Grid item xs={6}>
                            Un planning entièrement personnalisé
                        </Grid>
                        <Grid item xs={3}>
                            <ClearIcon style={{margin: "11px"}}/>
                        </Grid>
                        <Grid item xs={3}>
                            <HearingIcon style={{margin: "11px"}}/>
                        </Grid>
                        <Grid item xs={6}>
                            Un coach à votre écoute
                        </Grid>
                        <Grid item xs={3}>
                            <ClearIcon style={{margin: "11px"}}/>
                        </Grid>
                        <Grid item xs={3}>
                            <Icon icon={trophyIcon} style={{margin: "14px"}}/>
                        </Grid>
                        <Grid item xs={6}>
                            La garantie d'atteindre vos objectifs
                        </Grid>
                        <Grid item xs={3}>
                            <ClearIcon style={{margin: "11px"}}/>
                        </Grid>
                        <Grid item xs={12} style={{paddingTop : '20%'}}>
                            <Button
                                variant={"contained"}
                                fullWidth
                                style={{backgroundColor : '#43a047'}}
                                color={"primary"}
                                onClick={() => window.location.replace("/Profile")}
                            >
                                Annuler
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant={"contained"}
                                fullWidth
                                style={{backgroundColor : '#f44336'}}
                                color={"primary"}
                                onClick={() => handleSubscriptionChangeFree()}
                            >
                                Retour à l'abonnement gratuit
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        )
    }

}

export default ChangeSubscription;