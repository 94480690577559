import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";

const compareObjets = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
};

export default function ObjectifDetails(props) {
  const [objectif, setObjectif] = useState({
    OBJ_NOM: "",
    OBJ_DESCRIPTION: "",
    OBJ_POINTS: 1,
    OBJ_CONSEILS: "",
    OBJ_JOUR: props.jour,
    OBJ_SEMAINE: props.semaine,
  });
  const { objectifsDuJour, setObjectifsDuJour, objectifs } = props;

  // Au chargement de l'objectif, on charge ses infos à partir de la props passée
  useEffect(() => {
    if (props.infoObjectif) {
      setObjectif(props.infoObjectif);
      setObjectif((prevState) => ({ ...prevState, OBJ_JOUR: props.jour }));

      if (!props.infoObjectif.hasOwnProperty("OBJ_POINTS")) {
        setObjectif((prevState) => ({ ...prevState, OBJ_POINTS: 1 }));
      }

      setObjectif((prevState) => ({
        ...prevState,
        OBJ_SEMAINE: props.semaine,
      }));
    }
  }, []);

  const onChangeName = (e) => {
    // ...objectif permet de reprendre les autres champs de l'objet Défi.
    setObjectif({ ...objectif, OBJ_NOM: e.target.value });
  };

  const onChangeDescription = (e) => {
    setObjectif({ ...objectif, OBJ_DESCRIPTION: e.target.value });
  };

  const onChangePoints = (e) => {
    setObjectif({ ...objectif, OBJ_POINTS: e.target.value });
  };

  const onChangeConseils = (e) => {
    setObjectif({ ...objectif, OBJ_CONSEILS: e.target.value });
  };

  const saveObjectifLocal = () => {
    if (Array.isArray(objectifs)) {
      setObjectifsDuJour(
        objectifsDuJour.filter(
          (item) => item !== objectifsDuJour[props.clefObjet]
        )
      );
      setObjectifsDuJour((prevState) => [...prevState, objectif]);
    } else {
      setObjectifsDuJour((prevState) => ({
        // On reprend prevState pour ne pas supprimer les autres objectifs du jour
        ...prevState,
        [props.clefObjet]: objectif,
      }));
    }
    props.handleClose();
  };

  return (
    <Container component="main" maxWidth="xs" className="divClients">
      <Typography
        component="h3"
        variant="h4"
        style={{ paddingBottom: "10%", paddingTop: "10%" }}
      >
        Objectif {objectif.OBJ_NOM}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            variant={"outlined"}
            fullWidth
            label={"Nom"}
            onChange={(e) => onChangeName(e)}
            value={objectif.OBJ_NOM || ""}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            variant={"outlined"}
            type="number"
            fullWidth
            inputProps={{ min: 1 }}
            label={"Points"}
            onChange={(e) => onChangePoints(e)}
            value={objectif.OBJ_POINTS || 1}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            variant={"outlined"}
            fullWidth
            label={"Description"}
            multiline
            rows={3}
            onChange={(e) => onChangeDescription(e)}
            value={objectif.OBJ_DESCRIPTION || ""}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant={"outlined"}
            fullWidth
            label={"Conseils (optionnels)"}
            multiline
            rows={3}
            onChange={(e) => onChangeConseils(e)}
            value={objectif.OBJ_CONSEILS || ""}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            style={{ backgroundColor: "#857A73" }}
            variant={"contained"}
            color={"primary"}
            onClick={saveObjectifLocal}
          >
            Enregistrer
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
