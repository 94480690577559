import React, {useEffect, useState} from "react";
import '../../../css/Plannings.css';
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import {Button, Grid, MenuItem, TextField,} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {fr} from "date-fns/locale";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {addDays} from "material-ui/DatePicker/dateUtils";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import moment from 'moment'
import 'moment/locale/fr-ch'
import {createMuiTheme, makeStyles} from "@material-ui/core/styles";
moment.locale('fr-ch')


function PlanningsVIP(){

    const [user, setUser] = useState({})

    useEffect(() => {
        fetchUser().then(setUser);
        setFormType(
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        disabled
                    />
                </Grid>
            </Grid>
        )
    },[]);



    //Retourne la date d'aujourd'hui
    function getToday() {
        let date = new Date();
        let newDate = new Date(date.setDate(date.getDate()+1))
        addDays(date, 2);
        return newDate.toISOString();
    }


    // Permet de récupérer les informations sur l'utilisateur connecté
    async function fetchUser(){
        let requestURL = window.$apiURL.toString()+'users'
        let requestOptions = {
            method : 'GET',
            headers : {
                'Accept' : 'application/json',
                'Authorization' : 'Bearer '+localStorage.getItem('cbs_perform_token'),
            },
        }
        return fetch(requestURL, requestOptions)
            .then(res => res.json())
            .then(res =>{
                return res;
            })
    }


    const [pla_nom, setPla_nom] = useState('');
    const [pla_nomError, setPla_nomError] = useState(false);
    const [pla_nomErrorText, setPla_nomErrorText] = useState('');

    // Permet de stocker les changement du champs pla_nom
    const onChangePla_nom = (e) => {
        setPla_nom(e.target.value);
        setPla_nomError(false);
    }

    const [pla_type, setPla_type] = useState('');
    const [pla_typeError, setPla_typeError] = useState(false);
    const [pla_typeErrorText, setPla_typeErrorText] = useState('');
    const [pla_rem_niveau, setPla_rem_niveau] = useState('');
    const [pla_rem_niveauError, setPla_rem_niveauError] = useState(false);
    const [pla_rem_niveauErrorText, setPla_rem_niveauErrorText] = useState('');
    const [pla_duree, setPla_duree] = useState('')
    const [pla_dureeError, setPla_dureeError] = useState(false)
    const [pla_dureeErrorText, setPla_dureeErrorText] = useState('')
    const [pla_repetitions, setPla_repetitions] = useState('');
    const [pla_repetitionsError, setPla_repetitionsError] = useState(false);
    const [pla_repetitionsErrorText, setPla_repetitionsErrorText] = useState('');
    const [pla_objectif_temps, setPla_objectif_temps] = useState('')
    const [pla_objectif_tempsError, setPla_objectif_tempsError] = useState(false)
    const [pla_objectif_tempsErrorText, setPla_objectif_tempsErrorText] = useState('')
    const [pla_datedebut, setPla_datedebut] = useState('')
    const [pla_datedebutError, setPla_datedebutError] = useState(false)
    const [pla_datedebutErrorText, setPla_datedebutErrorText] = useState('')
    const [checkBoxError, setCheckBoxError] = useState(false);
    const [checkBoxErrorText, setCheckBoxErrorText] = useState('');


    const [userComments, setUserComments] = useState('');
    const [userCommentsError, setUserCommentsError] = useState('');


    // Permet de stocker l'écriture d'un nouveau message
    const onChangeUserComments = (e) =>{
        setUserCommentsError('')
        setUserComments(e.target.value)
    }


    const [formType, setFormType] = useState(null);

    //Permet de stocker les changements du champs pla_rem_niveau
    const onChangeRem_niveau = (e) =>{
        setPla_rem_niveau(e.target.value);
        setPla_rem_niveauError(false);
    }
    //Permet de stocker les changements du champs pla_duree
    const onChangePla_duree = (e) => {
        setPla_duree(e.target.value)
        setPla_dureeError(false);
    }
    //Permet de stocker les changements du champs pla_repetitions
    const onChangePla_repetitions = (e) =>{
        setPla_repetitions(e.target.value);
        setPla_repetitionsError(false);
    }
    //Permet de stocker les changements du champs pla_objectif_temps
    const onChangePla_objectif_temps = (e) =>{
        setPla_objectif_temps(e.target.value);
        setPla_objectif_tempsError(false)
    }
    //Permet de stocker les changements du champs pla_datedebut
    const onChangePla_datedebut = (e) => {
        setPla_datedebutError(false)
        setPla_datedebut(e)
    }
    const onCheck = (e) =>{
        setCheckBoxError(false)
        setCheckBoxErrorText('')
    }


    //Permet de stocker les changements du champs pla_type
    const onChangePla_type = (e) => {
        setPla_dureeError(false);
        setPla_typeError(false);
        setPla_type(e.target.value);
        setPla_repetitions('');
        setPla_repetitionsError(false);
        setPla_duree('')
        setPla_dureeError(false);
        setFormType(null);
        if (e.target.value === 'r'){
            setFormType(

                    <Grid container spacing={2}>
                        <Grid item xs={12}>

                            <TextField
                                id="rem_niveau_niv"
                                select
                                variant="outlined"
                                name="rem_niveau"
                                label="Votre niveau"
                                onChange={e => onChangeRem_niveau(e)}
                                required
                                error={pla_rem_niveauError}
                                helperText={pla_rem_niveauError ? pla_rem_niveauErrorText : ''}
                            >
                                <MenuItem value={'0'}>Débutant</MenuItem>
                                <MenuItem value={'1'}>Avancé</MenuItem>
                            </TextField>

                        </Grid>
                        <Grid item xs={12}>

                            <TextField
                                id="rem_niveau_duree"
                                select
                                required
                                label="Durée de l'entraînement"
                                variant="outlined"
                                name="pla_duree"
                                onChange={e => onChangePla_duree(e)}
                                error={pla_dureeError}
                                helperText={pla_dureeError ? pla_dureeErrorText : ''}
                            >
                                <MenuItem value={'6'}>6 semaines</MenuItem>
                                <MenuItem value={'7'}>7 semaines</MenuItem>
                                <MenuItem value={'8'}>8 semaines</MenuItem>
                                <MenuItem value={'9'}>9 semaines</MenuItem>
                                <MenuItem value={'10'}>10 semaines</MenuItem>
                                <MenuItem value={'11'}>11 semaines</MenuItem>
                                <MenuItem value={'12'}>12 semaines</MenuItem>
                            </TextField>

                        </Grid>
                        <Grid item xs={12}>

                            <TextField
                                id="rem_niveau_repetitions"
                                select
                                required
                                label="Entraînements par semaine"
                                variant="outlined"
                                name="pla_repetitions"
                                onChange={e => onChangePla_repetitions(e)}
                                error={pla_repetitionsError}
                                helperText={pla_repetitionsError ? pla_repetitionsErrorText : ''}
                            >
                                <MenuItem value={'2'}>2 fois par semaine</MenuItem>
                                <MenuItem value={'3'}>3 fois par semaine</MenuItem>
                            </TextField>

                        </Grid>
                    </Grid>
            )
        }
        else if(e.target.value === 'm'){
            setFormType(

                <Grid container spacing={2}>
                    <Grid item xs={12}>

                        <TextField
                            id="mara_duree"
                            select
                            required
                            label="Durée de l'entraînement"
                            variant="outlined"
                            name="pla_duree"
                            onChange={e => onChangePla_duree(e)}
                            error={pla_dureeError}
                            helperText={pla_dureeError ? pla_dureeErrorText : ''}
                        >
                            <MenuItem value={'12'}>12 semaines</MenuItem>
                            <MenuItem value={'13'}>13 semaines</MenuItem>
                            <MenuItem value={'14'}>14 semaines</MenuItem>
                            <MenuItem value={'15'}>15 semaines</MenuItem>
                            <MenuItem value={'16'}>16 semaines</MenuItem>
                            <MenuItem value={'17'}>17 semaines</MenuItem>
                            <MenuItem value={'18'}>18 semaines</MenuItem>
                            <MenuItem value={'19'}>19 semaines</MenuItem>
                            <MenuItem value={'20'}>20 semaines</MenuItem>
                        </TextField>

                    </Grid>
                    <Grid item xs={12}>

                        <TextField
                            select
                            id="mara_repetitions"
                            required
                            variant="outlined"
                            name="pla_repetitions"
                            label="Entraînements par semaine"
                            onChange={e => onChangePla_repetitions(e)}
                            error={pla_repetitionsError}
                            helperText={pla_repetitionsError ? pla_repetitionsErrorText : ''}
                        >
                            <MenuItem value={'3'}>3 fois par semaine</MenuItem>
                            <MenuItem value={'4'}>4 fois par semaine</MenuItem>
                            <MenuItem value={'5'}>5 fois par semaine</MenuItem>
                        </TextField>

                    </Grid>
                    <Grid item xs={12}>

                        <TextField
                            id="mara_objectif"
                            select
                            required
                            variant="outlined"
                            name="pla_objectif_temps"
                            label="Objectif Temps"
                            onChange={e => onChangePla_objectif_temps(e)}
                            error={pla_objectif_tempsError}
                            helperText={pla_objectif_tempsError ? pla_objectif_tempsErrorText : ''}
                        >
                            <MenuItem value={'3'}>Inférieur à 3h</MenuItem>
                            <MenuItem value={'35'}>Inférieur à 3h30</MenuItem>
                            <MenuItem value={'4'}>Inférieur à 4h</MenuItem>
                            <MenuItem value={'45'}>Inférieur à 4h30</MenuItem>
                            <MenuItem value={'5'}>Inférieur à 5h</MenuItem>
                        </TextField>

                    </Grid>
                </Grid>

            )
        }
        else if(e.target.value === 'sm'){
            setFormType(
            <Grid container spacing={2}>
                    <Grid item xs={12}>

                        <TextField
                            id="semi_mara_duree"
                            select
                            required
                            label="Durée de l'entraînement"
                            variant="outlined"
                            name="pla_duree"
                            onChange={e => onChangePla_duree(e)}
                            error={pla_dureeError}
                            helperText={pla_dureeError ? pla_dureeErrorText : ''}
                        >
                            <MenuItem value={'8'}>8 semaines</MenuItem>
                            <MenuItem value={'9'}>9 semaines</MenuItem>
                            <MenuItem value={'10'}>10 semaines</MenuItem>
                            <MenuItem value={'11'}>11 semaines</MenuItem>
                            <MenuItem value={'12'}>12 semaines</MenuItem>
                            <MenuItem value={'13'}>13 semaines</MenuItem>
                            <MenuItem value={'14'}>14 semaines</MenuItem>
                            <MenuItem value={'15'}>15 semaines</MenuItem>
                            <MenuItem value={'16'}>16 semaines</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="semi_mara_repetitions"
                            select
                            required
                            variant="outlined"
                            name="pla_repetitions"
                            label="Entraînements par semaine"
                            onChange={e => onChangePla_repetitions(e)}
                            error={pla_repetitionsError}
                            helperText={pla_repetitionsError ? pla_repetitionsErrorText : ''}
                        >
                            <MenuItem value={'3'}>3 fois par semaine</MenuItem>
                            <MenuItem value={'4'}>4 fois par semaine</MenuItem>
                            <MenuItem value={'5'}>5 fois par semaine</MenuItem>
                        </TextField>

                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="semi_mara_objectif"
                            select
                            required
                            variant="outlined"
                            name="pla_objectif_temps"
                            label="Objectif Temps"
                            onChange={e => onChangePla_objectif_temps(e)}
                            error={pla_objectif_tempsError}
                            helperText={pla_objectif_tempsError ? pla_objectif_tempsErrorText : ''}
                        >
                            <MenuItem value={'15'}>Inférieur à 1h30</MenuItem>
                            <MenuItem value={'175'}>Inférieur à 1h45</MenuItem>
                            <MenuItem value={'2'}>Inférieur à 2h</MenuItem>
                            <MenuItem value={'225'}>Inférieur à 2h15</MenuItem>
                            <MenuItem value={'25'}>Inférieur à 2h30</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
            )
        }
        else if(e.target.value === 'c'){
            setFormType(
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            id="course_duree"
                            select
                            required
                            label="Durée de l'entraînement"
                            variant="outlined"
                            name="pla_duree"
                            onChange={e => onChangePla_duree(e)}
                            error={pla_dureeError}
                            helperText={pla_dureeError ? pla_dureeErrorText : ''}
                        >
                            <MenuItem value={'8'}>8 semaines</MenuItem>
                            <MenuItem value={'9'}>9 semaines</MenuItem>
                            <MenuItem value={'10'}>10 semaines</MenuItem>
                            <MenuItem value={'11'}>11 semaines</MenuItem>
                            <MenuItem value={'12'}>12 semaines</MenuItem>
                            <MenuItem value={'13'}>13 semaines</MenuItem>
                            <MenuItem value={'14'}>14 semaines</MenuItem>
                            <MenuItem value={'15'}>15 semaines</MenuItem>
                            <MenuItem value={'16'}>16 semaines</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="course_repetitions"
                            select
                            required
                            variant="outlined"
                            label="Entraînements par semaine"
                            name="pla_repetitions"
                            onChange={e => onChangePla_repetitions(e)}
                            error={pla_repetitionsError}
                            helperText={pla_repetitionsError ? pla_repetitionsErrorText : ''}
                        >
                            <MenuItem value={'2'}>2 fois par semaine</MenuItem>
                            <MenuItem value={'3'}>3 fois par semaine</MenuItem>
                            <MenuItem value={'4'}>4 fois par semaine</MenuItem>
                            <MenuItem value={'5'}>5 fois par semaine</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="course_objectif"
                            select
                            required
                            variant="outlined"
                            name="pla_objectif_temps"
                            label="Objectif Temps"
                            onChange={e => onChangePla_objectif_temps(e)}
                            error={pla_objectif_tempsError}
                            helperText={pla_objectif_tempsError ? pla_objectif_tempsErrorText : ''}
                        >
                            <MenuItem value={'40'}>Inférieur à 40 minutes</MenuItem>
                            <MenuItem value={'45'}>Inférieur à 45 minutes</MenuItem>
                            <MenuItem value={'50'}>Inférieur à 50 minutes</MenuItem>
                            <MenuItem value={'55'}>Inférieur à 55 minutes</MenuItem>
                            <MenuItem value={'60'}>Inférieur à 60 minutes</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
            )
        }
    }

    function validate() {
        let errors = false;
        let formData = document.getElementById('newPlanningFreeFrom');
        let form = new FormData(formData);
        let checkboxCount = 0;
        form.forEach(function(value, key){
            if (key === 'daysOfExercice[]'){
                checkboxCount++;
            }
        });
        if (pla_nom.length === 0){
            errors = true;
            setPla_nomError(true)
            setPla_nomErrorText('Vous devez saisir un nom pour votre plan')
        }
        if (pla_type === 'r'){
            if(pla_rem_niveau.length === 0){
                errors = true;
                setPla_rem_niveauError(true);
                setPla_rem_niveauErrorText('Veuillez indiquer votre niveau actuel')
            }
        }else if(pla_type === 'm' || pla_type === 'sm' || pla_type === 'c'){
            if(pla_objectif_temps.length === 0){
                errors = true;
                setPla_objectif_tempsError(true);
                setPla_objectif_tempsErrorText('Veuillez indiquer votre objectif de temps')
            }
        }
        if (pla_type.length === 0){
            errors = true;
            setPla_typeError(true);
            setPla_typeErrorText('Veuillez choisir un type de plan')
        }
        if(pla_duree.length === 0){
            errors = true;
            setPla_dureeError(true);
            setPla_dureeErrorText('Veuillez indiquer la durée du plan souhaitée')
        }
        if (pla_repetitions.length === 0){
            errors = true;
            setPla_repetitionsError(true);
            setPla_repetitionsErrorText('Veuillez indiquer le nombre de répéritions par semaine souhaité')
        }
        if (new Date(pla_datedebut) < new Date()){
            errors = true;
            setPla_datedebutError(true);
            setPla_datedebutErrorText('La date doit se situer dans le futur');
        }
        if (checkboxCount.toString() !== pla_repetitions.toString()){
            errors = true;
            setCheckBoxError(true);
            setCheckBoxErrorText('Veuillez cocher le bon nombre de jours('+pla_repetitions+")");
        }
        if (pla_type.length === 0){
            errors = true;
            setPla_typeError(true);
            setPla_typeErrorText('Veuillez choisir un type de plan')
        }
        if (userComments.length === 0){
            errors = true;
            setUserComments('Veuillez indiquer vos objectifs svp')
        }
        return errors;
    }

    const submitPlanningRequest = async (e) => {
        e.preventDefault();

        const errors = validate();
        if (!errors) {
            let pla_idgratuit;
            if (pla_type === 'r') {
                pla_idgratuit = pla_rem_niveau + pla_type + pla_repetitions + pla_duree;
            } else {
                pla_idgratuit = pla_type + pla_repetitions + pla_duree;
            }
            let newPlanningFreeFromData = document.getElementById('newPlanningFreeFrom');
            let type_course = '';
            switch (pla_type) {
                case 'r' :
                    type_course = '(Remise en forme)';
                    break;
                case 'm' :
                    type_course = '(Marathon)';
                    break;
                case 'sm' :
                    type_course = '(Semi-marathon)';
                    break;
                case 'c' :
                    type_course = '(Course 10km)';
                    break;
            }
            let formData_planning = new FormData(newPlanningFreeFromData);
            formData_planning.set('pla_nom', formData_planning.get('pla_nom') + " " + type_course);
            formData_planning.set('pla_idgratuit', pla_idgratuit);
            if (pla_datedebut.length === 0){
                formData_planning.set('pla_datedebut', new Date(getToday()).toISOString().substring(0,10))
            }else{
                formData_planning.set('pla_datedebut', new Date(pla_datedebut).toISOString().substring(0,10))
            }
            formData_planning.set('isPlaVip', 1);
            let daysAfter = [], daysBefore = [];
            let cpt1 = 0, cpt2 = 0;
            formData_planning.forEach(function (value, key) {
                if (key === 'daysOfExercice[]') {
                    if (value < new Date(pla_datedebut).getDay()) {
                        daysBefore[cpt1] = value;
                        cpt1++;
                    } else {
                        daysAfter[cpt2] = value;
                        cpt2++;
                    }
                } 
            });
            daysBefore.sort();
            let concat = daysAfter.concat(daysBefore)
            formData_planning.delete('daysOfExercice[]');
            for (let i = 0; i < concat.length; i++) {
                formData_planning.append('daysOfExercice[]', concat[i]);
            }

            let formData_uce = new FormData();
            formData_uce.set('message', userComments);
            formData_uce.set('subject', 'plannings');

            let requestURL_planning = window.$apiURL.toString()+"users/plannings";
            let requestOptions_planning = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('cbs_perform_token'),
                },
                body: formData_planning
            }
            const responsePromise_planning = await fetch(requestURL_planning, requestOptions_planning);
            const data_planning = await responsePromise_planning.json();
            if (responsePromise_planning.ok){
                formData_uce.set('pla_id', data_planning['PLANNING'][0].id)
                let requestURL_uce = window.$apiURL.toString() + "users/exchanges/create_new_coach_exchange";
                let requestOptions_uce = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('cbs_perform_token'),
                    },
                    body: formData_uce
                }
                const responsePromise_uce = await fetch(requestURL_uce, requestOptions_uce);
                const data_uce = await responsePromise_uce.json();
                if (responsePromise_uce.ok){
                    //redirect to planning request submitted
                    window.location.replace("/SubscriptionRequestSubmitted");
                }else{
                    //redirect to error page
                }
            }else{
                //redirect to error page

            }
        }
    }



    return(
        <div>
            <Container component="main" maxWidth="xs" className="divPlannings">
                <CssBaseline/>
                <Typography component="h4" variant="h4" style={{paddingBottom : '20%'}}>
                    Demander un plan d'entraînement VIP à votre coach
                </Typography>
                <form id="newPlanningFreeFrom">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="off"
                                variant="outlined"
                                name="pla_nom"
                                label='Nom du planning'
                                helperText={pla_nomError ? pla_nomErrorText : ''}
                                error={pla_nomError}
                                onChange={e => onChangePla_nom(e)}
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                name="pla_type"
                                label="Type de Planning"
                                value={pla_type}
                                error={pla_typeError}
                                helperText={pla_typeError ? pla_typeErrorText : ''}
                                onChange={e => onChangePla_type(e)}
                                select
                                required
                                MenuProps={{disableScrollLock: true}}
                            >
                                <MenuItem value={'c'}>Course 10 Km</MenuItem>
                                <MenuItem value={'m'}>Marathon</MenuItem>
                                <MenuItem value={'sm'}>Semi-Marathon</MenuItem>
                                <MenuItem value={'r'}>Remise en forme</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                    <br/>
                    <br/>
                    <br/>
                    {formType}
                    <br/>
                    <br/>
                    <br/>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
                                <KeyboardDatePicker
                                    PopoverProps={{
                                        disableScrollLock: true
                                    }}
                                    autoOk
                                    variant="inline"
                                    inputVariant="outlined"
                                    name="pla_datedebut"
                                    format="dd-MM-yyyy"
                                    minDate={getToday()}
                                    value={pla_datedebut === '' ? getToday() : pla_datedebut}
                                    error={pla_datedebutError}
                                    helperText={pla_datedebutError ? pla_datedebutErrorText : 'Date de début du planning'}
                                    InputAdornmentProps={{ position: 'start'}}
                                    onChange={e => onChangePla_datedebut(e)}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                value="1"
                                name="daysOfExercice[]"
                                onChange={e => onCheck(e)}
                                control={<Checkbox style={{color : '#857A73'}}/>}
                                label="Lundi"
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                onChange={e => onCheck(e)}
                                value="2"
                                name="daysOfExercice[]"
                                control={<Checkbox style={{color : '#857A73'}}/>}
                                label="Mardi"
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                onChange={e => onCheck(e)}
                                value="3"
                                name="daysOfExercice[]"
                                control={<Checkbox style={{color : '#857A73'}}/>}
                                label="Mercredi"
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                onChange={e => onCheck(e)}
                                value="4"
                                name="daysOfExercice[]"
                                control={<Checkbox style={{color : '#857A73'}}/>}
                                label="Jeudi"
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                onChange={e => onCheck(e)}
                                value="5"
                                name="daysOfExercice[]"
                                control={<Checkbox style={{color : '#857A73'}}/>}
                                label="Vendredi"
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                onChange={e => onCheck(e)}
                                value="6"
                                name="daysOfExercice[]"
                                control={<Checkbox style={{color : '#857A73'}}/>}
                                label="Samedi"
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                onChange={e => onCheck(e)}
                                value="0"
                                name="daysOfExercice[]"
                                control={<Checkbox style={{color : '#857A73'}}/>}
                                label="Dimanche"
                                labelPlacement="top"
                            />
                            <FormHelperText
                                error={checkBoxError}
                            >
                                {checkBoxErrorText}
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                multiline
                                rows={15}
                                variant="outlined"
                                label="Parlez nous de vos attentes"
                                name="user_comments"
                                onChange={e => onChangeUserComments(e)}
                                error={userCommentsError !== ''}
                                helperText={userCommentsError !== '' ? userCommentsError : ''}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                style={{backgroundColor : '#D2BF67'}}
                                onClick={e => submitPlanningRequest(e)}
                                variant="contained"
                                color="primary"
                                fullWidth
                            >
                                Demander votre plan VIP
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </div>
    )

}
export default PlanningsVIP;
