import React, {useEffect, useState} from 'react';
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import '../../../css/Profile.css'
import Typography from "@material-ui/core/Typography";
import {Grid, MenuItem, TextField, Button} from "@material-ui/core";
import {Link, useParams} from "react-router-dom";
import jsPDF from "jspdf";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";



function DetailFactureCoach() {

    // Pour vérifier qu'il y a eu un retour du back
    const [factureIsSet, setFactureIsSet]= useState(0);

    // Données de la facture
    let {id_facture} = useParams();
    const [montant, setMontant] = useState('');
    const [code, setCode] = useState('');
    const [type, setType] = useState('');
    const [detail, setDetail] = useState('');
    const [dateEmission, setDateEmission] = useState('');
    const [datePaiement, setDatePaiement] = useState('');
    const [statut, setStatut] = useState('');
    const [isPaid, setIsPaid] = useState(false);

    // Données du coach
    const [coa_nom, setCoa_nom] = useState('');
    const [coa_prenom, setCoa_prenom] = useState('');
    const [coa_email, setCoaEmail] = useState('');
    const [coa_isSuperAdmin, setCoaIsSuperAdmin] =useState(false)

    // Données du client
    const [cli_nom, setCli_nom] = useState('');
    const [cli_prenom, setCli_prenom] = useState('');
    const [cli_email, setCliEmail] = useState('');
    const [cli_nom_prenom, setCli_nom_prenom] = useState('');

    // Texte d'erreur dans le dialogue de modification
    const [detailError, setDetailError] = useState('');
    const [montantError, setMontantError] = useState('');
    const [typeError, setTypeError] = useState('');


    // Permet de stocker les changements du champs detail (de la facture)
    const onChangeDetailFacture = (e) => {
        setDetail(e.target.value);
        setDetailError('');
    }
    //Permet de stocker les changements du champs montant
    const onChangeMontant = (e) => {
        setMontant(e.target.value);
        setMontantError('');
    }
    //Permet de stocker les changements du champs type
    const onChangeType = (e) => {
        setType(e.target.value);
        setTypeError('');
    }

    // Permet de vérifier que les données obligatoires sont correctement entrées
    function validateFacture() {
        let errors = false;
        if (detail.length === 0) {
            errors = true;
            setDetailError('Veuillez saisir le détail');
        }
        if (montant < 1) {
            errors = true;
            setMontantError('Veuillez saisir le montant');
        }
        if (type.length === 0) {
            errors = true;
            setTypeError('Veuillez choisir la méthode de paiement');
        }
        return errors;
    }


    // Gestion des pages de dialogues différents
    const [openPDF, setOpenPDF] = useState(false);
    const handleDialogOpenPDF = () => {
        setOpenPDF(true);
    }
    const handleDialogClosePDF = () => {
        setOpenPDF(false);
    }
    const [openModif, setOpenModif] = useState(false);
    const handleDialogOpenModif = () => {
        setOpenModif(true);
    }
    const handleDialogCloseModif = () => {
        setOpenModif(false);
    }
    const [openConfirm, setOpenConfirm] =useState(false);
    const handleDialogOpenConfirmPay = () => {
        setOpenConfirm(true);
    }
    const handleDialogCloseConfirmPay = () => {
        setOpenConfirm(false);
    }
    const [openDelete, setOpenDelete] =useState(false);
    const handleDialogOpenDelete = () => {
        setOpenDelete(true);
    }
    const handleDialogCloseDelete = () => {
        setOpenDelete(false);
    }

    // Permet de récupérer les détails d'une facture en particulier
    const getFactureDetail = async () =>{
        let requestURL = window.$apiURL.toString()+'payments/coach/'+id_facture;
        let requestOptions = {
            method : 'GET',
            headers : {
                'Accept' : 'application/json',
                'Authorization' : 'Bearer '+localStorage.getItem('cbs_perform_token'),
            },
        }
        const responsePromise = await fetch(requestURL, requestOptions);
        const data = await responsePromise.json();
        if (!responsePromise.ok){
            window.location.replace("/404");
        }else{
            setIsPaid( false );
            setDetail(data.pai_detail);
            setMontant(data.pai_montant);
            setCode(data.pai_code);
            if (data.isSuperAdmin === 1) {
                setCoaIsSuperAdmin(true);
            }
            if (data.pai_type === 'paypal'){
                setType('Paypal');
            } else if (data.pai_type === 'facture') {
                setType('Facture');
            } else {
                setType('Autre');
            }
            setDateEmission(data.pai_dateemission);
            setDatePaiement(data.pai_datepaiement);

            if (data.pai_statut === 'paid'){
                setStatut('Montant payé');
            } else {
                setStatut('Montant non payé');
            }
            setCli_nom(data.cli_nom);
            setCli_prenom(data.cli_prenom);
            setCliEmail(data.cli_email);
            setCli_nom_prenom(data.cli_prenom+' '+data.cli_nom);

            if (Date.parse(data.pai_datepaiement)){
                setIsPaid(true);
            }  else {
                setIsPaid(false);
            }
        }
        setFactureIsSet(1);
    }

    // Permet de récupérer les détails du coach connecté
    const getCoach = async () => {
        let requestURL = window.$apiURL.toString()+'coachs'
        let requestOptions = {
            method : 'GET',
            headers : {
                'Accept' : 'application/json',
                'Authorization' : 'Bearer '+localStorage.getItem('cbs_perform_token'),
            },
        }
        const responsePromise = await fetch(requestURL, requestOptions);
        const data = await responsePromise.json();
        setCoa_nom(data.coa_nom);
        setCoa_prenom(data.coa_prenom);
        setCoaEmail(data.email);
    }

    // Mise en forme de la facture avec les informations de celle-ci
    const createPDF = async (e) =>{
        e.preventDefault();
        var doc = new jsPDF();
        //let valtest =montant.toString();
        // Titre
        doc.setFont('helvetica')
        doc.setFontType("bold");
        doc.setFontSize(24)
        doc.text('Facture', 20, 30);
        doc.setFontType("normal");
        doc.setFontSize(12);
        doc.text('PerForm-CBS', 130,30);
        doc.text('Rue du Nant 10', 130,35);
        doc.text('CH-1207 Genève', 130,40);
        doc.text('+41 79 531 40 90', 130,45);

        doc.text('Date d\'impression:',120,60)
        doc.setFontType("bold");
        doc.text(''+moment().format('D MMM, YYYY'),160,60);

        //Clients
        doc.text(coa_prenom+' '+coa_nom, 20, 80);
        doc.setFontType("normal");
        doc.text(coa_email,20, 85);

        doc.setLineWidth(0.5);
        doc.line(20, 90, 190, 90);

        // Détail de la facture
        doc.text('Détail de votre Facture: ',20, 100);

        doc.setDrawColor(105,105,105);
        doc.line(50, 120, 160, 120);
        doc.line(50, 135, 160, 135);
        doc.line(50, 150, 160, 150);
        doc.line(50, 165, 160, 165);
        doc.line(50, 180, 160, 180);

        doc.setFontType("bold");
        doc.text('Description: ',50, 115);
        doc.text('Prix: ',50, 130)
        doc.text('Date d\'émission: ',50, 145);
        doc.text('Type de paiement: ',50, 160);
        doc.text('Status de paiement: ',50, 175);

        doc.setFontType("normal");
        doc.text(detail,100, 115);
        doc.text(montant+' CHF',100, 130)
        doc.text(dateEmission,100, 145);
        doc.text(type,100, 160);
        doc.text(statut,100, 175);


        //Coach
        doc.setFontSize(11);
        doc.text('A payer par l\'utilisateur:', 20,220)
        doc.text(cli_prenom+' '+cli_nom, 20, 230);
        doc.setFontType("normal");
        doc.text(cli_email,20, 235);

        doc.save('invoiceCoach'+id_facture+'.pdf');

        handleDialogClosePDF();
    }


    useEffect(()=>{
        getCoach()
        getFactureDetail({})
    }, [])

    // Permet d'enregistrer les changements effectué sur la facture dans la base de données
    const updateFactureModification = async () => {
        let error= validateFacture();
        if (!error){
            let formData = new FormData();
            formData.set('detail', detail);
            formData.set('montant', montant);
            formData.set('type', type);
            formData.set('pai_id',id_facture)
            let object = {};
            formData.forEach(function (value, key) {
                object[key] = value;
            });
            let jsonBody = JSON.stringify(object);
            let requestURL = window.$apiURL.toString()+"payments/coach"
            let requestOptions ={
                method : 'PUT',
                headers : {
                    'Accept' : 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer '+localStorage.getItem('cbs_perform_token'),
                },
                body : jsonBody,
            }
            const responsePromise = await fetch(requestURL, requestOptions);
            if (responsePromise.ok){
                window.location.replace("/FactureCoach/"+id_facture)
            }
        }
        handleDialogCloseModif();
    }

    //Permet de confirmer les changements de la facture
    const updateFactureConfirmation = async (e) => {
        e.preventDefault();
        let formData = new FormData();
        let requestURL = window.$apiURL.toString() + 'payments/coach/statut/'+id_facture;
        let requestOptions = {
            crossDomain: true,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('cbs_perform_token'),
            },
            body: formData,
        }
        const responsePromise = await fetch(requestURL, requestOptions)
        if (responsePromise.ok){
            window.location.replace("/FactureCoach/"+id_facture)
        }
        handleDialogCloseConfirmPay();
    }

    //Permet de supprimer la facture de la base de données
    const handleDeleteFacture = async () => {
        let requestURL = window.$apiURL.toString()+"payments/coach/"+id_facture;
        let requestOptions = {
            method : 'DELETE',
            headers : {
                'Accept' : 'application/json',
                'Authorization' : 'Bearer '+localStorage.getItem('cbs_perform_token'),
            },
        }
        const responsePromise = await fetch(requestURL, requestOptions);
        const data = await responsePromise.json();
        if (responsePromise.ok){
            window.location.replace("/FacturesCoach");
        }
        handleDialogCloseDelete();
    }



    //Permet d'afficher les informations quand les données sont chargées
    if (factureIsSet ===0){
        return (
            <div style={{paddingTop : '50%'}}><CircularProgress style={{color : '#857A73'}}/></div>
        )
    } else if (factureIsSet ===1){
        return(
            <div>
                <Container component="main" maxWidth="xs" className="divProfile">
                    <CssBaseline/>
                    <Typography component="h1" variant="h3" style={{paddingBottom : '20%'}}>
                        Détail de la facture
                    </Typography>
                    <form id="updateForm" autoComplete="on">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    disabled={true}
                                    value={cli_nom_prenom}
                                    helperText={"Nom du client"}
                                    fullWidth
                                    id="cli_nom_prenom"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    disabled={true}
                                    value={detail}
                                    helperText={"Détail de la facture"}
                                    fullWidth
                                    id="detail"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    disabled={true}
                                    value={montant}
                                    helperText={"montant"}

                                    fullWidth
                                    id="montant"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    disabled={true}
                                    value={dateEmission}
                                    helperText={"Date d'émission"}
                                    format="dd-MM-yyyy"
                                    fullWidth
                                    id="dateEmission"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    disabled={true}
                                    value={type}
                                    helperText={"Type de paiement"}
                                    fullWidth
                                    id="type"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    disabled={true}
                                    name="statut"
                                    value={statut}
                                    helperText={"Statut"}

                                    fullWidth
                                    id="Statut"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {!type ==='Paypal' ?
                                    <br/>
                                    :
                                    <TextField
                                        variant="outlined"
                                        disabled={true}
                                        name="code"
                                        value={code}
                                        helperText={"Code de paiement"}
                                        fullWidth
                                        id="code"
                                    />
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    style={{backgroundColor : '#857A73'}}
                                    color="primary"
                                    onClick={handleDialogOpenPDF}
                                >  Générer en PDF
                                </Button>
                                <Dialog
                                    open={openPDF}
                                    onClose={handleDialogClosePDF}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{ maxWidth : '100%'}}
                                >
                                    <DialogContent style={{maxWidth : '90%', paddingRight : '5%', paddingLeft: '5%'}}>
                                        <DialogContentText id="alert-dialog-description" style={{color : 'black', fontSize : 20}}>
                                            Voulez-vous générer en PDF la facture suivante?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions style={{ maxWidth : '92%', paddingRight : '4%', paddingLeft: '4%'}}>
                                        <Button onClick={handleDialogClosePDF} color="primary" autofocus>
                                            Annuler
                                        </Button>
                                        <Button onClick={e => createPDF(e)} style={{color : '#009900'}}>
                                            Oui
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={handleDialogOpenModif}
                                    style={{backgroundColor : '#857A73'}}
                                >
                                    Modifier la facture
                                </Button>
                                <Dialog open={openModif}
                                        onClose={handleDialogCloseModif}
                                        aria-labelledby="form-dialog-title">
                                    <DialogTitle id="form-dialog-title">Modifier les informations </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            Vous pouvez ici modifier le détail de la facture, son montant et le type de paiement
                                        </DialogContentText>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            value={detail}
                                            label={"Détail de la facture"}
                                            onChange={e => onChangeDetailFacture(e)}
                                            helperText={detailError}
                                            fullWidth
                                            id="detail"

                                        />
                                        <TextField
                                            value={montant}
                                            helperText={"montant"}
                                            onChange={e => onChangeMontant(e)}
                                            fullWidth
                                            label={"Montant"}
                                            helperText={montantError}
                                            id="montant"
                                        />
                                        {coa_isSuperAdmin ?
                                            <TextField
                                                select
                                                onChange={e => onChangeType(e)}
                                                label={"Type de paiement"}
                                                helperText={typeError}
                                            >
                                                <MenuItem value={'paypal'}>Paypal</MenuItem>
                                                <MenuItem value={'facture'}>Facture</MenuItem>
                                                <MenuItem value={'other'}>Autre</MenuItem>
                                            </TextField>
                                        :
                                            <TextField
                                                select
                                                onChange={e => onChangeType(e)}
                                                label={"Type de paiement"}
                                                helperText={typeError}
                                            >
                                                <MenuItem value={'facture'}>Facture</MenuItem>
                                                <MenuItem value={'other'}>Autre</MenuItem>
                                            </TextField>
                                        }
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleDialogCloseModif} color="primary">
                                            Annuler
                                        </Button>
                                        <Button onClick={updateFactureModification} color="primary" style={{color : '#009900'}}>
                                            Enregistrer
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    style={{backgroundColor : '#f44336'}}
                                    fullWidth
                                    variant={"contained"}
                                    color={"primary"}
                                    onClick={() => handleDialogOpenDelete()}
                                >
                                    Supprimer la facture
                                </Button>
                                <Dialog
                                    open={openDelete}
                                    onClose={handleDialogCloseDelete}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{ maxWidth : '100%'}}
                                >
                                    <DialogContent style={{maxWidth : '90%', paddingRight : '5%', paddingLeft: '5%'}}>
                                        <DialogContentText id="alert-dialog-description" style={{color : 'black', fontSize : 20}}>
                                            Êtes-vous sûr de vouloir supprimer la facture ?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions style={{ maxWidth : '92%', paddingRight : '4%', paddingLeft: '4%'}}>
                                        <Button onClick={handleDialogCloseDelete} color="primary" autofocus>
                                            Annuler
                                        </Button>
                                        <Button onClick={handleDeleteFacture} style={{color : '#f44336'}}>
                                            Supprimer
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Grid>
                            <Grid item xs={12}>
                                {isPaid ?
                                    <br/>
                                    :<Button
                                        fullWidth
                                        variant="contained"
                                        style={{backgroundColor : '#857A73'}}
                                        color="primary"
                                        onClick={handleDialogOpenConfirmPay}
                                    >  Confirmer le paiement de facture
                                    </Button> }
                                <Dialog
                                    open={openConfirm}
                                    onClose={handleDialogCloseConfirmPay}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{ maxWidth : '100%'}}
                                >
                                    <DialogContent style={{maxWidth : '90%', paddingRight : '5%', paddingLeft: '5%'}}>
                                        <DialogContentText id="alert-dialog-description" style={{color : 'black', fontSize : 20}}>
                                            Voulez-vous confirmer le paiement?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions style={{ maxWidth : '92%', paddingRight : '4%', paddingLeft: '4%'}}>
                                        <Button onClick={handleDialogCloseConfirmPay} color="primary" autofocus>
                                            Annuler
                                        </Button>
                                        <Button onClick={e => updateFactureConfirmation(e)} style={{color : '#009900'}}>
                                            Oui
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Grid>
                        </Grid>
                    </form>
                </Container>
            </div>
        )
    }


}

export default DetailFactureCoach
