import React, {useEffect, useState} from "react";
import {Grid, TextField} from "@material-ui/core";
import {useParams} from "react-router-dom";
import '../../../css/Clients.css';
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

function PlanningVipDiscUser(){

    const [isReady, setIsReady] = useState(-1);
    const [exchanges, setExchanges] = useState({})
    const [uceId, setUceId] = useState('');


    let {id_planning} = useParams();

    useEffect(() =>{
        getExchanges()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    //Récupère les échanges entre le coach et le client par rapport à un planning spécifique
    const getExchanges = async () => {
        let requestURL = window.$apiURL.toString()+"users/exchanges/get_user_messages?subject=plannings&pla_id="+id_planning
        let requestOptions = {
            method : 'GET',
            headers : {
                'Accept' : 'application/json',
                'Authorization' : 'Bearer '+localStorage.getItem('cbs_perform_token'),
            },
        }
        const responsePromise = await fetch(requestURL, requestOptions);
        const data = await responsePromise.json();
        if (responsePromise.ok){
            setUceId(data[0].uce_id)
            setExchanges(data);
            setIsReady(1);
        }else if(responsePromise.status === 404){
            window.location.replace("/404")
        }

    }

    // Permet de mettre en forme l'affichage des messages entre le client et son coach
    // eslint-disable-next-line array-callback-return
    const commentsList = Object.keys(exchanges).map(key => {
            if (exchanges[key].from === 'user'){
                return (
                    <Grid container spacing={2} key={key}>
                        <Grid item xs={12} style={{textAlign : 'right', fontSize : '12px'}}>
                            Vous{' '}{new Date(exchanges[key].created_at).toLocaleString('fr')}
                        </Grid>
                        <Grid item xs={3}/>
                        <Grid item xs={9} style={{ textAlign : 'right',  paddingTop : 0}}>
                            <Box
                                style={{maxWidth : 'fit_content', backgroundColor : '#cfd8dc', borderRadius : 5,
                                    textAlign : 'left', margin : 0}}
                            >
                                <p style={{padding : '10px', margin : 0}}>{exchanges[key].message}</p>
                            </Box>
                        </Grid>
                    </Grid>
                )
            }else if (exchanges[key].from === 'coach'){
                return (
                    <Grid container spacing={2} key={key}>
                        <Grid item xs={12} style={{textAlign : 'left', fontSize : '12px'}}>
                            Coach : {exchanges[key].coa_nom}{' '}{exchanges[key].coa_prenom}{' '}{new Date(exchanges[key].created_at).toLocaleString('fr')}
                        </Grid>
                        <Grid item xs={9} style={{ textAlign : 'right',  paddingTop : 0}}>
                            <Box
                                style={{maxWidth : 'fit_content', backgroundColor : '#eeeeee', borderRadius : 5,
                                    textAlign : 'left', margin : 0}}
                            >
                                <p style={{padding : '10px', margin : 0}}>{exchanges[key].message}</p>
                            </Box>
                        </Grid>
                        <Grid item xs={3}/>
                    </Grid>
                )
            }
        }
    )


    const [comment, setComment] = useState('');
    const [commentError, setCommentError] = useState('');

    // Permet de stocker l'écriture d'un nouveau message
    const onChangeComment = (e) =>{
        setComment(e.target.value)
        setCommentError('')
    }

    // Permet de vérifier qu'un message a bien été écrit avant l'envoi
    function validate(){
        let errors = false;
        if (comment.length === 0){
            errors = true;
            setCommentError('Veuillez saisir votre message !')
        }
        return errors;
    }

    // Permet de sauvegarder le nouveau message écrit dans la base de données
    const handleNewMessage = async () => {
        let errors = validate();
        if(!errors){
            let subscriptionChangeFormData = new FormData();
            subscriptionChangeFormData.set('uce_id', uceId);
            subscriptionChangeFormData.set('message', comment);

            let requestURL = window.$apiURL.toString()+"users/exchanges/send_message_to_coach"
            let requestOptions = {
                method : 'POST',
                headers : {
                    'Accept' : 'application/json',
                    'Authorization' : 'Bearer '+localStorage.getItem('cbs_perform_token'),
                },
                body : subscriptionChangeFormData
            }
            const responsePromise = await fetch(requestURL, requestOptions);
            if (responsePromise.ok){
               window.location.replace("/PlanningVipDiscUser/"+id_planning)
            }
        }
    }


    // Affichage de la page selon le chargement du contenu
    if (isReady === -1){
        return (
            <div style={{paddingTop : '50%'}}><CircularProgress style={{color : '#857A73'}}/></div>
        )
    }else if (isReady === 1){
        return (
            <div>
                <Container component="main" maxWidth="xs" className="divRequest">
                    <Typography component="h3" variant="h5" style={{paddingBottom : '10%'}}>
                        Message concernant le planning
                    </Typography>
                    <br/>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            Discussion :
                        </Grid>
                    </Grid>
                    <br/><br/>
                    <div
                        style={{height : '40vh', width : '100%',
                            overflow : 'hidden',
                            position : 'relative'}}
                        >
                        <div style={{position : 'absolute',
                            top : 0, bottom : 0, overflow: 'scroll',
                            overflowX : 'hidden', height : '100%'}}>
                            {commentsList}
                        </div>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{paddingTop : '15%', textAlign : 'left'}}>
                            Nouveau message
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom : '10%'}}>
                            <TextField
                                variant={"outlined"}
                                multiline
                                rows={4}
                                name={'userComments'}
                                onChange={e => onChangeComment(e)}
                                error={commentError !== ''}
                                helperText={commentError !== '' ? commentError : ''}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <Button
                                style={{backgroundColor : '#857A73'}}
                                variant={"contained"}
                                color={"primary"}
                                onClick={e => handleNewMessage(e)}
                                fullWidth
                            >
                                Envoyer votre message
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        )
    }else{
        return (
            <div/>
        )
    }
}

export default PlanningVipDiscUser;