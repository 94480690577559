import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import "../../../css/Clients.css";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

function DetailClient() {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      name: {
        fontSize: 16,
        textAlign: "left",
        color: "white",
        padding: "10px",
        backgroundColor: "#857A73",
        opacity: "70%",
        margin: 0,
      },
      pos: {
        marginBottom: 12,
      },
    })
  );
  const classes = useStyles();

  const [isReady, setIsReady] = useState(-1);
  const [client, setClient] = useState({});
  const [clientRaces, setClientRaces] = useState({});
  const [planRequests, setPlanRequests] = useState("");

  let { id_client } = useParams();

  useEffect(() => {
    getUserDetails();
  }, []);

  // Permet de récupérer les messages entre un client et son coach
  const getUserDetails = async () => {
    let requestURLUserData =
      window.$apiURL.toString() + "coachs/users/details/" + id_client;
    let requestURLRaces =
      window.$apiURL.toString() + "coachs/races/users/" + id_client;
    let requestURLPlanRequest =
      window.$apiURL.toString() +
      "coachs/exchanges/" +
      id_client +
      "?subject=plannings";
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromiseUserData = await fetch(
      requestURLUserData,
      requestOptions
    );
    const responsePromiseRaces = await fetch(requestURLRaces, requestOptions);
    const responsePromisePlanRequest = await fetch(
      requestURLPlanRequest,
      requestOptions
    );
    const dataUserData = await responsePromiseUserData.json();
    const dataRaces = await responsePromiseRaces.json();
    const dataPlanRequest = await responsePromisePlanRequest.json();
    if (responsePromiseUserData.status === 404) {
      window.location.replace("/404");
    }
    setClient(dataUserData);
    if (dataPlanRequest.data.length === 0) {
      setClientRaces(dataRaces);
      setIsReady(0);
    } else {
      setPlanRequests(dataPlanRequest.data);
      setClientRaces(dataRaces);
      setIsReady(1);
    }
  };

  // Permet la mise en forme de l'affichage des demandes de plan VIP du client concerné
  const requestCards = Object.keys(planRequests).map((key) => (
    <Grid item xs={12} key={key}>
      <Link
        to={"/PlanningVipDisc/request/" + planRequests[key].uce_id}
        style={{ textDecoration: "none" }}
      >
        <Card variant="outlined" style={{ minWidth: "100%" }}>
          <Typography
            className={classes.name}
            color="textSecondary"
            gutterBottom
          >
            {planRequests[key].cli_nom} {planRequests[key].cli_prenom} :{" "}
            {planRequests[key].pla_id}
          </Typography>
        </Card>
      </Link>
    </Grid>
  ));

  const raceCards = Object.keys(clientRaces).map((key) => (
    <Grid item xs={12} key={key}>
      <Grid container>
        <Grid item xs={6}>
          {clientRaces[key].ccl_nom}
        </Grid>
        <Grid item xs={3}>
          {clientRaces[key].ccl_type}
        </Grid>
        <Grid item xs={3}>
          {clientRaces[key].ccl_time}
        </Grid>
      </Grid>
    </Grid>
  ));

  // Affichage de la page (différent selon si le client a déjà effectué une demande de plan VIP ou non)
  if (isReady === -1) {
    return (
      <Container
        component="main"
        maxWidth="xs"
        className="divProfile"
        style={{ paddingTop: "10%", paddingBottom: "10%" }}
      >
        <CircularProgress style={{ color: "#857A73" }} />
      </Container>
    );
  } else if (isReady === 0) {
    return (
      <div>
        <Container component="main" maxWidth="xs" className="divRequest">
          <Typography
            component="h3"
            variant="h5"
            style={{ paddingBottom: "10%" }}
          >
            Détail client n° {id_client}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: "left" }}>
              <b>ID :</b> {client.id}
            </Grid>
            <Grid item xs={12} style={{ textAlign: "left" }}>
              <b>Nom :</b> {client.cli_nom}
            </Grid>
            <Grid item xs={12} style={{ textAlign: "left" }}>
              <b>Prénom :</b> {client.cli_prenom}
            </Grid>
            <Grid item xs={12} style={{ textAlign: "left" }}>
              <b>Date de naissance :</b> {client.cli_dob}
            </Grid>
            <Grid item xs={12} style={{ textAlign: "left" }}>
              <b>Email :</b> {client.email}
            </Grid>
            <Grid item xs={12} style={{ textAlign: "left" }}>
              <b>Records : </b>
            </Grid>
            {raceCards}
          </Grid>

          <br />
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Link
                to={"/HistDiscSub/" + id_client}
                style={{ textDecoration: "none" }}
              >
                <Button
                  style={{ backgroundColor: "#857A73" }}
                  variant={"contained"}
                  color={"primary"}
                  fullWidth
                >
                  Historique des messages d'inscription
                </Button>
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Link
                to={"/FacturesClient/" + id_client}
                style={{ textDecoration: "none" }}
              >
                <Button
                  style={{ backgroundColor: "#857A73" }}
                  variant={"contained"}
                  color={"primary"}
                  fullWidth
                >
                  Vos factures avec le client
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              Historique des plans VIP :
            </Grid>
            <Grid item xs={12}>
              Pas de demande actuellement
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  } else if (isReady === 1) {
    return (
      <div>
        <Container component="main" maxWidth="xs" className="divRequest">
          <Typography
            component="h3"
            variant="h5"
            style={{ paddingBottom: "10%" }}
          >
            Détail client n° {id_client}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: "left" }}>
              <b>ID :</b> {client.id}
            </Grid>
            <Grid item xs={12} style={{ textAlign: "left" }}>
              <b>Nom :</b> {client.cli_nom}
            </Grid>
            <Grid item xs={12} style={{ textAlign: "left" }}>
              <b>Prénom :</b> {client.cli_prenom}
            </Grid>
            <Grid item xs={12} style={{ textAlign: "left" }}>
              <b>Date de naissance :</b> {client.cli_dob}
            </Grid>
            <Grid item xs={12} style={{ textAlign: "left" }}>
              <b>Email :</b> {client.email}
            </Grid>
            <Grid item xs={12} style={{ textAlign: "left" }}>
              <b>Records : </b>
            </Grid>
            {raceCards}
          </Grid>
          <br />
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Link
                to={"/HistDiscSub/" + id_client}
                style={{ textDecoration: "none" }}
              >
                <Button
                  style={{ backgroundColor: "#857A73" }}
                  variant={"contained"}
                  color={"primary"}
                  fullWidth
                >
                  Historique des messages d'inscription
                </Button>
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Link
                to={"/FacturesClient/" + id_client}
                style={{ textDecoration: "none" }}
              >
                <Button
                  style={{ backgroundColor: "#857A73" }}
                  variant={"contained"}
                  color={"primary"}
                  fullWidth
                >
                  Vos factures avec le client
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              Historique des plans VIP :
            </Grid>
            <Grid item xs={12}>
              {requestCards}
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  } else {
    return (
      <Container
        component="main"
        maxWidth="xs"
        className="divProfile"
        style={{ paddingTop: "10%", paddingBottom: "10%" }}
      >
        <CircularProgress style={{ color: "#857A73" }} />
      </Container>
    );
  }
  //     if (requestType === 'vip_subscription'){
  //         if (hasRequest === -1){
  //
  //         } else if (hasRequest === 1){
  //
  //         } else if (hasRequest === 0) {
  //
  //         }
  //     }else{
  //         return (
  //             <Container component="main" maxWidth="xs" className="divProfile" style={{paddingTop : '10%', paddingBottom : '10%'}}>
  //                 <CircularProgress style={{color : '#857A73'}}/>
  //             </Container>
  //         )
  //     }

  // }
}

export default DetailClient;
