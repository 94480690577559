import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";

function PreviousRaces() {
  const [isReady, setIsReady] = useState(-1);
  const [races, setRaces] = useState({});
  const [raceName, setRaceName] = useState("");
  const [raceTime, setRaceTime] = useState("");
  const [raceType, setRaceType] = useState("");
  const [submitButton, setSubmitButton] = useState("Ajouter record");
  const [raceNameError, setRaceNameError] = useState("");
  const [raceTypeError, setRaceTypeError] = useState("");
  const [raceTimeError, setRaceTimeError] = useState("");

  useEffect(() => {
    getPreviousRaces();
  }, []);

  const getPreviousRaces = async () => {
    let requestURL = window.$apiURL.toString() + "users/mescourses";
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    if (responsePromise.status !== 404) {
      setRaces(data);
      setIsReady(1);
    } else {
      setIsReady(0);
    }
  };

  const createNewRace = async () => {
    if (!validate()) {
      setSubmitButton(
        <CircularProgress size={25} style={{ color: "white" }} />
      );
      let formData = new FormData();
      formData.set("ccl_nom", raceName);
      formData.set("ccl_type", raceType);
      formData.set("ccl_time", raceTime);
      let requestURL = window.$apiURL.toString() + "users/mescourses";
      let requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
        },
        body: formData,
      };
      const responsePromise = await fetch(requestURL, requestOptions);
      const data = await responsePromise.json();
      if (responsePromise.status !== 500) {
        setRaces(data);
      } else {
        console.log(data);
      }
      setRaceName("");
      setRaceType("");
      setRaceTime("");
      setSubmitButton("Ajouter record");
      setIsReady(1);
    }
  };

  const deleteRace = async (id_race) => {
    let requestURL = window.$apiURL.toString() + "users/mescourses/" + id_race;
    let requestOptions = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    setRaces(data);
    if (responsePromise.status === 404) {
      setIsReady(0);
    }
  };

  const onChangeRaceName = (e) => {
    setRaceNameError("");
    setRaceName(e.target.value);
  };
  const onChangeRaceTime = (e) => {
    setRaceTimeError("");
    setRaceTime(e.target.value);
  };
  const onChangeRaceType = (e) => {
    setRaceTypeError("");
    setRaceType(e.target.value);
  };

  function validate() {
    let errors = false;
    if (raceName.length === 0) {
      setRaceNameError("Nom de la course manquant");
      errors = true;
    }
    if (raceType.length === 0) {
      setRaceTypeError("Type de course manquant");
      errors = true;
    }
    if (raceTime.length === 0) {
      setRaceTimeError("Record de la course manquant");
      errors = true;
    }
    return errors;
  }

  const raceCards = Object.keys(races).map((key) => (
    <Grid item xs={12} key={key}>
      <Grid container>
        <Grid item xs={9}>
          <p
            style={{
              textAlign: "left",
              fontSize: "20px",
              fontWeight: "bold",
              margin: "2%",
            }}
          >
            {races[key].ccl_nom}
          </p>
        </Grid>
        <Grid item xs={3}>
          <Button onClick={() => deleteRace(races[key].id)}>
            <CloseIcon />
          </Button>
        </Grid>
        <Grid item xs={8}>
          <p style={{ textAlign: "left", margin: "2%" }}>
            {races[key].ccl_type}
          </p>
        </Grid>
        <Grid item xs={4}>
          <p style={{ textAlign: "center", margin: "2%" }}>
            {races[key].ccl_time}
          </p>
        </Grid>
      </Grid>
    </Grid>
  ));

  if (isReady === -1) {
    return (
      <div>
        <Container
          component="main"
          maxWidth="xs"
          className="divProfile"
          style={{ paddingTop: "10%", paddingBottom: "10%" }}
        >
          <CircularProgress style={{ color: "#857A73" }} />
        </Container>
      </div>
    );
  } else if (isReady === 0) {
    return (
      <div>
        <Container component="main" maxWidth="xl" className="divClients">
          <Typography
            component="h4"
            variant="h4"
            style={{ paddingBottom: "10%" }}
          >
            Mes Courses
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ paddingBottom: "5%" }}>
                  <Typography component="h5" variant="h5">
                    Aucun record actuellement
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography component="h6" variant="h6">
                    Ajouter un nouveau record
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    helperText={raceNameError === "" ? "" : raceNameError}
                    error={raceNameError !== ""}
                    label="Nom de la course"
                    required
                    variant="outlined"
                    value={raceName}
                    onChange={(e) => onChangeRaceName(e)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="outlined" error={raceTypeError !== ""}>
                    <InputLabel htmlFor="raceType">Type de course</InputLabel>
                    <Select
                      native
                      label="Type de course"
                      required
                      value={raceType}
                      onChange={(e) => onChangeRaceType(e)}
                      inputProps={{
                        id: "raceType",
                      }}
                    >
                      <option aria-label="None" value="" />
                      <option value={"Marathon"}>Marathon</option>
                      <option value={"Semi-marathon"}>Semi-marathon</option>
                      <option value={"Ultra marathon"}>Ultra marathon</option>
                      <option value={"5 kilomètres"}>5 kilomètres</option>
                      <option value={"10 kilomètres"}>10 kilomètres</option>
                      <option value={"Autre"}>Autre</option>
                    </Select>
                    <FormHelperText>
                      {raceTypeError === "" ? "" : raceTypeError}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    helperText={raceTimeError === "" ? "" : raceTimeError}
                    error={raceTimeError !== ""}
                    inputProps={{
                      step: 1,
                      id: "raceTime",
                    }}
                    label="Record"
                    type="time"
                    required
                    variant="outlined"
                    value={raceTime}
                    onChange={(e) => onChangeRaceTime(e)}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    style={{ backgroundColor: "#857A73" }}
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={(e) => createNewRace(e)}
                  >
                    {submitButton}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  } else if (isReady === 1) {
    return (
      <div>
        <Container component="main" maxWidth="md" className="divClients">
          <Typography
            component="h4"
            variant="h4"
            style={{ paddingBottom: "3%" }}
          >
            Mes Courses
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2} style={{ paddingBottom: "3%" }}>
                {raceCards}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography component="h6" variant="h6">
                    Ajouter un nouveau record
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    helperText={raceNameError === "" ? "" : raceNameError}
                    error={raceNameError !== ""}
                    label="Nom de la course"
                    required
                    variant="outlined"
                    value={raceName}
                    onChange={(e) => onChangeRaceName(e)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="outlined" error={raceTypeError !== ""}>
                    <InputLabel htmlFor="raceType">Type de course</InputLabel>
                    <Select
                      native
                      label="Type de course"
                      required
                      value={raceType}
                      onChange={(e) => onChangeRaceType(e)}
                      inputProps={{
                        id: "raceType",
                      }}
                    >
                      <option aria-label="None" value="" />
                      <option value={"Marathon"}>Marathon</option>
                      <option value={"Semi-marathon"}>Semi-marathon</option>
                      <option value={"Ultra marathon"}>Ultra marathon</option>
                      <option value={"5 kilomètres"}>5 kilomètres</option>
                      <option value={"10 kilomètres"}>10 kilomètres</option>
                      <option value={"Autre"}>Autre</option>
                    </Select>
                    <FormHelperText>
                      {raceTypeError === "" ? "" : raceTypeError}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    helperText={raceTimeError === "" ? "" : raceTimeError}
                    error={raceTimeError !== ""}
                    inputProps={{
                      step: 1,
                      id: "raceTime",
                    }}
                    label="Record"
                    type="time"
                    required
                    variant="outlined"
                    value={raceTime}
                    onChange={(e) => onChangeRaceTime(e)}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    style={{ backgroundColor: "#857A73" }}
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={(e) => createNewRace(e)}
                  >
                    {submitButton}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  } else {
    return (
      <div>
        <Container
          component="main"
          maxWidth="xs"
          className="divProfile"
          style={{ paddingTop: "10%", paddingBottom: "10%" }}
        >
          <CircularProgress style={{ color: "#857A73" }} />
        </Container>
      </div>
    );
  }
}

export default PreviousRaces;
