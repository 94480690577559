import React, {useEffect, useState} from 'react';
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import '../../../css/Profile.css'
import Typography from "@material-ui/core/Typography";
import {Grid, MenuItem, TextField, Button} from "@material-ui/core";
import {Link, useParams} from "react-router-dom";
import jsPDF from "jspdf";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import DialogTitle from "@material-ui/core/DialogTitle";

function DetailFacture(){

    // Pour vérifier qu'il y a eu un retour du back
    const [factureIsSet, setFactureIsSet]= useState(0);

    // Données de la facture
    let {id_facture} = useParams();
    const [montant, setMontant] = useState('');
    const [code, setCode] = useState('');
    const [type, setType] = useState('');
    const [detail, setDetail] = useState('');
    const [dateEmission, setDateEmission] = useState('');
    const [datePaiement, setDatePaiement] = useState('');
    const [statut, setStatut] = useState('');
    const [isPaid, setIsPaid] = useState(false);
    const [paypalButton, setPaypalButton]= useState(false);
    const [paypalPaid, setPaypalPaid] = useState(false);

    // Données du coach
    const [coa_nom, setCoa_nom] = useState('');
    const [coa_prenom, setCoa_prenom] = useState('');
    const [coa_email, setCoaEmail] = useState('');
    const [coa_isSuperAdmin, setCoaIsSuperAdmin] =useState(false);

    // Données du client
    const [cli_nom, setCli_nom] = useState('');
    const [cli_prenom, setCli_prenom] = useState('');
    const [cli_email, setCliEmail] = useState('');


    // Gestion du dialogue du PDF
    const [open, setOpen] = React.useState(false);
    const handleDialogOpen = () => {
        setOpen(true);
    }
    const handleDialogClose = () => {
        setOpen(false);
    }


    // Gestion du dialogue pour la modification du type de paiement
    const [openModif, setOpenModif] = useState(false);
    const handleDialogOpenModif = () => {
        setOpenModif(true);
    }
    const handleDialogCloseModif = () => {
        setOpenModif(false);
        window.location.replace("/Facture/"+id_facture)
    }

    // Permet de stocker les changements du champs type (de paiement)
    const onChangeType = (e) => {
        setType(e.target.value);
    }

    // Permet de récupérer les détails d'une facture en particulier
    const getFactureDetail = async () =>{
        let requestURL = window.$apiURL.toString()+'payments/'+id_facture;
        let requestOptions = {
            method : 'GET',
            headers : {
                'Accept' : 'application/json',
                'Authorization' : 'Bearer '+localStorage.getItem('cbs_perform_token'),
            },
        }
        const responsePromise = await fetch(requestURL, requestOptions);
        const data = await responsePromise.json();
        if (!responsePromise.ok){
            window.location.replace("/404");
        }else{
            setIsPaid( false );
            setDetail(data.pai_detail);
            setMontant(data.pai_montant);

            setCode(data.pai_code);
            if (data.pai_type === 'paypal'){
                setType('Paypal');
            } else if (data.pai_type === 'facture') {
                setType('Facture');
            } else {
                setType('Autre');
            }
            if (data.isSuperAdmin === 1) {
                setCoaIsSuperAdmin(true);
            }
            setDateEmission(data.pai_dateemission);
            setDatePaiement(data.pai_datepaiement);

            if (data.pai_statut === 'paid'){
                setStatut('Montant payé');
            } else {
                setStatut('Montant non payé');
            }
            setCoa_nom(data.coa_nom);
            setCoa_prenom(data.coa_prenom);
            setCoaEmail(data.coa_email);
            setPaypalButton(false);

            if (Date.parse(data.pai_datepaiement)){
                setIsPaid(true);
                setPaypalButton(false);
                if(data.pai_type ==='paypal'){
                    setPaypalPaid(true);
                }
            } else if(data.pai_type ==='paypal'){
                setIsPaid(false);
                setPaypalButton(true);
            }
        }
        setFactureIsSet(1);
    }

    // Permet d'enregistrer les changements effectué sur une facture dans la base de données
    const updateFactureModification = async () => {
        let formData = new FormData();
        formData.set('type', type);
        formData.set('pai_id',id_facture)
        let object = {};
        formData.forEach(function (value, key) {
            object[key] = value;
        });
        let jsonBody = JSON.stringify(object);
        let requestURL = window.$apiURL.toString()+"payments"
        let requestOptions ={
            method : 'PUT',
            headers : {
                'Accept' : 'application/json',
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer '+localStorage.getItem('cbs_perform_token'),
            },
            body : jsonBody,
        }
        const responsePromise = await fetch(requestURL, requestOptions);
        if (responsePromise.ok){
            window.location.replace("/Facture/"+id_facture)
        }
        handleDialogCloseModif();
    }

    // Permet de récupérer les informations d'un client connecté
    const getUser = async () => {
        let requestURL = window.$apiURL.toString()+'users'
        let requestOptions = {
            method : 'GET',
            headers : {
                'Accept' : 'application/json',
                'Authorization' : 'Bearer '+localStorage.getItem('cbs_perform_token'),
            },
        }
        const responsePromise = await fetch(requestURL, requestOptions);
        const data = await responsePromise.json();
        setCli_nom(data.cli_nom);
        setCli_prenom(data.cli_prenom);
        setCliEmail(data.email);
    }

    // Mise en forme de la facture avec les informations de celle-ci
    const createPDF = async (e) =>{
        e.preventDefault();
        var doc = new jsPDF();
        //let valtest =montant.toString();
        // Titre
        doc.setFont('helvetica')
        doc.setFontType("bold");
        doc.setFontSize(24)
        doc.text('Facture', 20, 30);
        doc.setFontType("normal");
        doc.setFontSize(12);
        doc.text('PerForm-CBS', 130,30);
        doc.text('Rue du Nant 10', 130,35);
        doc.text('CH-1207 Genève', 130,40);
        doc.text('+41 79 531 40 90', 130,45);

        doc.text('Date d\'impression:',120,60)
        doc.setFontType("bold");
        doc.text(''+moment().format('D MMM, YYYY'),160,60);

        //Clients
        doc.text(cli_prenom+' '+cli_nom, 20, 80);
        doc.setFontType("normal");
        doc.text(cli_email,20, 85);

        doc.setLineWidth(0.5);
        doc.line(20, 90, 190, 90);

        // Détail de la facture
        doc.text('Détail de votre Facture: ',20, 100);

        doc.setDrawColor(105,105,105);
        doc.line(50, 120, 160, 120);
        doc.line(50, 135, 160, 135);
        doc.line(50, 150, 160, 150);
        doc.line(50, 165, 160, 165);
        doc.line(50, 180, 160, 180);

        doc.setFontType("bold");
        doc.text('Description: ',50, 115);
        doc.text('Prix: ',50, 130)
        doc.text('Date d\'émission: ',50, 145);
        doc.text('Type de paiement: ',50, 160);
        doc.text('Status de paiement: ',50, 175);

        doc.setFontType("normal");
        doc.text(detail,100, 115);
        doc.text(montant+' CHF',100, 130)
        doc.text(dateEmission,100, 145);
        doc.text(type,100, 160);
        doc.text(statut,100, 175);


        //Coach
        doc.setFontSize(11);
        doc.text('En faveur du Coach:', 20,220)
        doc.text(coa_nom+' '+coa_prenom, 20, 230);
        doc.setFontType("normal");
        doc.text(coa_email,20, 235);

        //Création du fichier PDF
        doc.save('invoice'+id_facture+'.pdf');

        handleDialogClose();
    }


    useEffect(()=>{
        getUser()
        getFactureDetail({})
    }, [])


    // Affichage de la page
    if (factureIsSet ===0){
        return (
            <div style={{paddingTop : '50%'}}><CircularProgress style={{color : '#857A73'}}/></div>
        )
    } else if (factureIsSet ===1){
        return(
            <div>
                <Container component="main" maxWidth="xs" className="divProfile">
                    <CssBaseline/>
                    <Typography component="h1" variant="h3" style={{paddingBottom : '20%'}}>
                        Détail de la facture
                    </Typography>
                    <br />
                    <br />
                    <form id="updateForm" autoComplete="on">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    disabled={true}
                                    value={detail}
                                    helperText={"Détail de la facture"}
                                    fullWidth
                                    id="detail"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    disabled={true}
                                    value={montant}
                                    helperText={"montant"}

                                    fullWidth
                                    id="montant"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    disabled={true}
                                    value={dateEmission}
                                    helperText={"Date d'émission"}
                                    format="dd-MM-yyyy"
                                    fullWidth
                                    id="dateEmission"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    disabled={true}
                                    value={type}
                                    helperText={"Type de paiement"}
                                    fullWidth
                                    id="type"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    disabled={true}
                                    name="statut"
                                    value={statut}
                                    helperText={"Statut"}

                                    fullWidth
                                    id="Statut"
                                />
                            </Grid>
                            {!paypalPaid ?
                                <br/>
                                :
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    disabled={true}
                                    name="code"
                                    value={code}
                                    helperText={"Code de paiement"}
                                    fullWidth
                                    id="code"
                                />
                            </Grid>
                            }
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{backgroundColor : '#857A73'}}
                                    onClick={handleDialogOpen}
                                >  Générer en PDF
                                </Button>
                                <Dialog
                                    open={open}
                                    onClose={handleDialogClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{ maxWidth : '100%'}}
                                >
                                    <DialogContent style={{maxWidth : '90%', paddingRight : '5%', paddingLeft: '5%'}}>
                                        <DialogContentText id="alert-dialog-description" style={{color : 'black', fontSize : 20}}>
                                            Voulez-vous générer en PDF la facture suivante?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions style={{ maxWidth : '92%', paddingRight : '4%', paddingLeft: '4%'}}>
                                        <Button onClick={handleDialogClose} color="primary" autofocus>
                                            Annuler
                                        </Button>
                                        <Button onClick={e => createPDF(e)} style={{color : '#009900'}}>
                                            Oui
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Grid>
                            {isPaid ?
                                <br />
                                :
                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={handleDialogOpenModif}
                                        style={{backgroundColor : '#857A73'}}
                                    >
                                        Changer moyen de paiement
                                    </Button>
                                    <Dialog open={openModif}
                                            onClose={handleDialogCloseModif}
                                            aria-labelledby="form-dialog-title">
                                        <DialogTitle id="form-dialog-title">Modifier le paiement </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                Vous pouvez ici modifier le type de paiement
                                            </DialogContentText>
                                            {coa_isSuperAdmin ?
                                                <TextField
                                                    select
                                                    onChange={e => onChangeType(e)}
                                                    label={"Type de paiement"}
                                                >
                                                    <MenuItem value={'paypal'}>Paypal</MenuItem>
                                                    <MenuItem value={'facture'}>Facture</MenuItem>
                                                    <MenuItem value={'other'}>Autre</MenuItem>
                                                </TextField>
                                                :
                                                <TextField
                                                    select
                                                    onChange={e => onChangeType(e)}
                                                    label={"Type de paiement"}
                                                >
                                                    <MenuItem value={'facture'}>Facture</MenuItem>
                                                    <MenuItem value={'other'}>Autre</MenuItem>
                                                </TextField>
                                            }
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleDialogCloseModif} color="primary">
                                                Annuler
                                            </Button>
                                            <Button onClick={updateFactureModification} color="primary" style={{color : '#009900'}}>
                                                Enregistrer
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Grid>
                            }
                            {!paypalButton ?
                                <br/>
                                :
                            <Grid item xs={12}>
                                    <Link to={"/PaypalPayment/"+id_facture} style={{textDecoration : 'none'}}>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            style={{backgroundColor : '#857A73'}}
                                            color="primary"
                                        >  Paiement par paypal
                                        </Button>
                                    </Link>
                            </Grid>
                            }
                        </Grid>
                    </form>
                </Container>
            </div>
        )
    }


}

export default DetailFacture
