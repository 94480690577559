import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import Pagination from "@material-ui/lab/Pagination";
import "../../../../css/Request.css";

function VipRequests() {
  const [isReady, setIsReady] = useState(-1);
  const [exchanges, setExchanges] = useState("");
  const [pageTotal, setPageTotal] = useState("");

  useEffect(() => {
    getVipRequestsExchanges();
  }, []);

  // Permet de récupérer les demandes de passage à un abonnement VIP
  const getVipRequestsExchanges = async () => {
    let requestURL =
      window.$apiURL.toString() + "coachs/exchanges?subject=vip_subscription";
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    setExchanges(data["data"]);
    setPageTotal(data.last_page);
    setIsReady(1);
  };

  // Permet la mise en forme des demandes de passage à un abonnement VIP
  const exchangesCards = Object.keys(exchanges).map((key) => (
    <Grid item xs={12} key={key}>
      <Link
        to={"/RequestDetail/" + exchanges[key].uce_id}
        style={{ textDecoration: "none" }}
      >
        <Card variant="outlined" style={{ minWidth: "100%" }}>
          <Typography
            color="textSecondary"
            gutterBottom
            style={{
              fontSize: 16,
              textAlign: "left",
              color: "white",
              padding: "10px",
              backgroundColor: "#857A73",
              opacity: "70%",
              margin: 0,
            }}
          >
            {exchanges[key].cli_nom} {exchanges[key].cli_prenom}
          </Typography>
        </Card>
      </Link>
    </Grid>
  ));

  // Permet de gérer l'affichage pour afficher un nombre limité de demandes par page
  const handlePageChange = async (event, value) => {
    let requestURL =
      window.$apiURL.toString() +
      "coachs/exchanges?subject=vip_subscription&page=" +
      value;
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    setPageTotal(data.last_page);
    setExchanges(data.data);
    window.scrollTo(0, 0);
  };

  // Affichage de la page
  if (isReady === -1) {
    return (
      <div style={{ paddingTop: "50%" }}>
        <CircularProgress style={{ color: "#857A73" }} />
      </div>
    );
  } else if (isReady === 1) {
    return (
      <div>
        <Container component={"main"} maxWidth={"xs"} className="divRequest">
          <Typography
            component="h1"
            variant="h3"
            style={{ paddingBottom: "15%" }}
          >
            Demandes d'abonnement VIP
          </Typography>
          <Grid container spacing={2}>
            {exchangesCards}
            <Grid
              item
              xs={12}
              style={{ paddingTop: "10%", alignItems: "center" }}
            >
              <Pagination
                disabled={exchanges.length === 0}
                count={pageTotal}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
                style={{ display: "inline-flex" }}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default VipRequests;
