import React, { useEffect, useState } from "react";
import "../../../css/Clients.css";
import { Button, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Link, useParams } from "react-router-dom";
import Card from "@material-ui/core/Card";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Pagination from "@material-ui/lab/Pagination";

function FacturesClientCoach() {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      name: {
        fontSize: 18,
        textAlign: "left",
        color: "white",
        padding: "10px",
        backgroundColor: "#857A73",
        opacity: "70%",
        margin: 0,
      },
      subject: {
        fontSize: 14,
        padding: "10px",
        color: "white",
        opacity: "85%",
        backgroundColor: "#857A73",
      },
      score: {
        fontSize: 14,
        padding: "10px",
        color: "white",
        opacity: "100%",
        backgroundColor: "#857A73",
      },
      score2: {
        fontSize: 14,
        padding: "10px",
        color: "white",
        opacity: "85%",
        backgroundColor: "#857A73",
      },
      pos: {
        marginBottom: 12,
      },
    })
  );
  const classes = useStyles();

  // Récupération de l'id de l'url
  let { id_client } = useParams();

  // Données de la facture
  const [lst, setLst] = useState("");
  const [pageTotal, setPageTotal] = useState("");

  // Pour vérifier qu'il y a eu un retour du back
  const [facturesAreSet, setFacturesAreSet] = useState(0);
  const [hasFacture, setHasFacture] = useState(-1);

  const [factOrder, setFactOrder] = useState("desc");
  const [factOrderType, setFactOrderType] = useState("dateemission");

  // Permet de stocker les changements du champs de tri
  const onChangeTrierPar = async (e) => {
    setFactOrderType(e.target.value);
    afficherGetFactures();
  };

  // Permet de récupérer les factures d'un client en particulier envers un coach
  const afficherGetFactures = async () => {
    let requestURL =
      window.$apiURL.toString() + "payments/coach/client/" + id_client;
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    if (data.data[0] != null) {
      setPageTotal(data.last_page);
      setLst(data.data);
      setHasFacture(1);
      window.scrollTo(0, 0);
    } else {
      setHasFacture(0);
    }
    setFacturesAreSet(1);
  };

  // Gestion de la pagination et récupération des données des factures selon la page choisie
  const handlePageChange = async (event, value) => {
    let requestURL =
      window.$apiURL.toString() +
      "payments/coach/client/" +
      id_client +
      "?page=" +
      value;
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    setPageTotal(data.last_page);
    setLst(data.data);
    setFacturesAreSet(1);
    setHasFacture(1);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    afficherGetFactures();
  }, []);

  // Permet de mettre en forme l'affichage des factures
  const factureCards = Object.keys(lst).map((key) => (
    <Grid item xs={12} key={key}>
      <Card variant="outlined" style={{ minWidth: "100%" }}>
        <Typography className={classes.name} color="textSecondary" gutterBottom>
          {lst[key].pai_detail} de {lst[key].cli_nom} {lst[key].cli_prenom}{" "}
          Montant: {lst[key].pai_montant} CHF
        </Typography>
        {lst[key].pai_statut === "paid" ? (
          <Typography variant="h5" component="h2" className={classes.score}>
            Emis le: {lst[key].pai_dateemission}&emsp;&emsp;Status: payée le{" "}
            {lst[key].pai_datepaiement}
          </Typography>
        ) : (
          <Typography variant="h5" component="h2" className={classes.score}>
            Emis le: {lst[key].pai_dateemission}&emsp;&emsp;Status: non payée
          </Typography>
        )}
        <Typography variant="h5" component="h2" className={classes.score2}>
          <Link
            to={"/FactureCoach/" + lst[key].id}
            style={{ textDecoration: "none" }}
          >
            <Button
              style={{ backgroundColor: "grey" }}
              variant="contained"
              color="primary"
            >
              Ouvrir
            </Button>
          </Link>
        </Typography>
      </Card>
    </Grid>
  ));

  // Affichage sur la page
  if (facturesAreSet === 0) {
    return (
      <div style={{ paddingTop: "50%" }}>
        <CircularProgress style={{ color: "#857A73" }} />
      </div>
    );
  } else if (facturesAreSet === 1) {
    if (hasFacture === -1) {
      return (
        <div>
          <Container component="main" maxWidth="xs" className="divFacture">
            <Typography
              component="h5"
              variant="h5"
              style={{ paddingBottom: "20%" }}
            >
              Pas de factures actuellement
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Link
                  to={"/NewFacture/" + id_client}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    style={{ backgroundColor: "#857A73" }}
                    variant={"contained"}
                    color={"primary"}
                    fullWidth
                  >
                    Créer une facture
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Container>
        </div>
      );
    } else if (hasFacture === 1) {
      return (
        <div>
          <Container component="main" maxWidth="xs" className="divFacture">
            <Typography
              component="h4"
              variant="h4"
              style={{ paddingBottom: "20%" }}
            >
              Factures de {lst[0].cli_nom} {lst[0].cli_prenom}
            </Typography>
            <Grid container spacing={2}>
              {factureCards}
              <Grid item xs={12}>
                <Link
                  to={"/NewFacture/" + id_client}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    style={{ backgroundColor: "#857A73" }}
                    variant={"contained"}
                    color={"primary"}
                    fullWidth
                  >
                    Créer une facture
                  </Button>
                </Link>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ paddingTop: "10%", alignItems: "center" }}
              >
                <Pagination
                  disabled={lst.length === 0}
                  count={pageTotal}
                  variant="outlined"
                  shape="rounded"
                  onChange={handlePageChange}
                  style={{ display: "inline-flex" }}
                />
              </Grid>
            </Grid>
          </Container>
        </div>
      );
    } else if (hasFacture === 0) {
      return (
        <div>
          <Container component="main" maxWidth="xs" className="divFacture">
            <Typography
              component="h5"
              variant="h5"
              style={{ paddingBottom: "20%" }}
            >
              Pas de factures actuellement
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Link
                  to={"/NewFacture/" + id_client}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    style={{ backgroundColor: "#857A73" }}
                    variant={"contained"}
                    color={"primary"}
                    fullWidth
                  >
                    Créer une facture
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Container>
        </div>
      );
    }
  }
}

export default FacturesClientCoach;
