import React, { useEffect, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import "../../../../css/Clients.css";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

function PlanningVipDisc() {
  const [isReady, setIsReady] = useState(-1);
  const [exchanges, setExchanges] = useState({});
  const [requestType, setRequestType] = useState("");
  const [userId, setUserId] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [plaId, setPlaId] = useState("");

  let { id_request } = useParams();

  useEffect(() => {
    getExchanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Permet d'obtenir les messages échangés entre le client et son coach
  const getExchanges = async () => {
    //let requestURL = window.$apiURL.toString()+"coachs/exchanges/"+id_request;
    let requestURL =
      window.$apiURL.toString() + "coachs/exchanges/request/" + id_request;
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    if (responsePromise.ok) {
      setRequestType(data[0].subject);
      setUserId(data[0].cli_id);
      setUserLastName(data[0].cli_nom);
      setUserName(data[0].cli_prenom);
      setPlaId(data.pla_id);
      setExchanges(data);
      setIsReady(1);
    } else if (responsePromise.status === 404) {
      window.location.replace("/404");
    }
  };

  // Permet de mettre en forme l'affichage des messages entre le client et son coach
  const commentsList = Object.keys(exchanges).map((key) => {
    if (exchanges[key].from === "coach") {
      return (
        <Grid container spacing={2} key={key}>
          <Grid item xs={12} style={{ textAlign: "right", fontSize: "12px" }}>
            Vous {new Date(exchanges[key].created_at).toLocaleString("fr")}
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={9} style={{ textAlign: "right", paddingTop: 0 }}>
            <Box
              style={{
                maxWidth: "fit_content",
                backgroundColor: "#cfd8dc",
                borderRadius: 5,
                textAlign: "left",
                margin: 0,
              }}
            >
              <p style={{ padding: "10px", margin: 0 }}>
                {exchanges[key].message}
              </p>
            </Box>
          </Grid>
        </Grid>
      );
    } else if (exchanges[key].from === "user") {
      return (
        <Grid container spacing={2} key={key}>
          <Grid item xs={12} style={{ textAlign: "left", fontSize: "12px" }}>
            Utilisateur : {exchanges[key].cli_nom} {exchanges[key].cli_prenom}{" "}
            {new Date(exchanges[key].created_at).toLocaleString("fr")}
          </Grid>
          <Grid item xs={9} style={{ textAlign: "right", paddingTop: 0 }}>
            <Box
              style={{
                maxWidth: "fit_content",
                backgroundColor: "#eeeeee",
                borderRadius: 5,
                textAlign: "left",
                margin: 0,
              }}
            >
              <p style={{ padding: "10px", margin: 0 }}>
                {exchanges[key].message}
              </p>
            </Box>
          </Grid>
          <Grid item xs={3} />
        </Grid>
      );
    }
  });

  const [comment, setComment] = useState("");
  const [commentError, setCommentError] = useState("");

  // Permet de stocker l'écriture d'un nouveau message
  const onChangeComment = (e) => {
    setComment(e.target.value);
    setCommentError("");
  };

  // Permet de vérifier qu'un message a bien été écrit avant l'envoi
  function validate() {
    let errors = false;
    if (comment.length === 0) {
      errors = true;
      setCommentError("Veuillez saisir votre message !");
    }
    return errors;
  }

  // Permet de sauvegarder le nouveau message écrit dans la base de donnée
  const handleNewMessage = async () => {
    let errors = validate();
    if (!errors) {
      let subscriptionChangeFormData = new FormData();
      subscriptionChangeFormData.set("uce_id", id_request);
      subscriptionChangeFormData.set("message", comment);
      let requestURL =
        window.$apiURL.toString() + "coachs/exchanges/send_message_to_user";
      let requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
        },
        body: subscriptionChangeFormData,
      };
      const responsePromise = await fetch(requestURL, requestOptions);
      const data = await responsePromise.json();
      if (responsePromise.ok) {
        window.location.replace("/PlanningVipDisc/request/" + id_request);
      }
    }
  };

  // Affichage de la page
  if (isReady === -1) {
    return (
      <div style={{ paddingTop: "50%" }}>
        <CircularProgress style={{ color: "#857A73" }} />
      </div>
    );
  } else if (isReady === 1) {
    if (requestType === "plannings") {
      return (
        <div>
          <Container component="main" maxWidth="xs" className="divRequest">
            <Typography
              component="h3"
              variant="h5"
              style={{ paddingBottom: "10%" }}
            >
              Plan du client {userLastName} {userName}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Link
                  to={"/PlanningCreation/" + plaId}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    style={{ backgroundColor: "#D2BF67" }}
                    variant={"contained"}
                    color={"primary"}
                    fullWidth
                  >
                    Gestion du plan
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <br />
            <br />
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                Discussion :
              </Grid>
            </Grid>
            <br />
            <br />
            <div
              style={{
                height: "40vh",
                width: "100%",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  overflow: "scroll",
                  overflowX: "hidden",
                  height: "100%",
                }}
              >
                {commentsList}
              </div>
            </div>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                style={{ paddingTop: "15%", textAlign: "left" }}
              >
                Nouveau message
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: "10%" }}>
                <TextField
                  variant={"outlined"}
                  multiline
                  rows={4}
                  name={"userComments"}
                  onChange={(e) => onChangeComment(e)}
                  error={commentError !== ""}
                  helperText={commentError !== "" ? commentError : ""}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  style={{ backgroundColor: "#857A73" }}
                  variant={"contained"}
                  color={"primary"}
                  onClick={(e) => handleNewMessage(e)}
                  fullWidth
                >
                  Envoyer votre message
                </Button>
              </Grid>
            </Grid>
          </Container>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default PlanningVipDisc;
