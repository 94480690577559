import React, { useState } from "react";
import { sha256 } from "js-sha256";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { Grid, TextField } from "@material-ui/core";
import Container from "@material-ui/core/Container/Container";
import Button from "@material-ui/core/Button";
import "../../../css/Profile.css";

function ChangePassword() {
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [currPassword, setCurrPassword] = useState("");
  const [newPasswordErrorText, setNewPasswordErrorText] = useState("");
  const [newPasswordHasError, setNewPasswordHasError] = useState(false);
  const [currPasswordErrorText, setCurrPasswordErrorText] = useState("");
  const [currPasswordHasError, setCurrPasswordHasError] = useState(false);
  const [passwordChangeResultText, setPasswordChangeResultText] = useState("");

  // Permet de récupérer le mot de passe actuel entré par l'utilisateur
  const onChangeCurrPassword = (e) => {
    setCurrPassword(e.target.value);
    setCurrPasswordHasError(false);
  };

  // Permet de récupérer le nouveau mot de passe de l'utilisateur
  const onChangeNewPassword = (e) => {
    setNewPassword(e.target.value);
    setNewPasswordHasError(false);
  };

  // Permet de récupérer la confirmation du nouveau mot de passe de l'utilisateur
  const onChangeNewPasswordConfirmation = (e) => {
    setNewPasswordConfirmation(e.target.value);
    setNewPasswordHasError(false);
  };

  // Vérifie que les données entrées par l'utilisateur sont correctes
  function validate() {
    let errors = false;
    if (currPassword.length === 0) {
      errors = true;
      setCurrPasswordHasError(true);
      setCurrPasswordErrorText(
        "Vous devez rentrer votre mot de passe actuel !"
      );
    }
    if (newPassword.length === 0 || newPasswordConfirmation.length === 0) {
      errors = true;
      setNewPasswordHasError(true);
      setNewPasswordErrorText("Vous devez entrer un nouveau mot de passe !");
    }
    if (newPassword !== newPasswordConfirmation) {
      errors = true;
      setNewPasswordHasError(true);
      setNewPasswordErrorText("Les mots de passe ne correspondent pas !");
    }
    return errors;
  }

  // Enregistre le nouveau mot de passe dans la base de données
  const changePasswordHandler = async (e) => {
    e.preventDefault();
    if (!validate()) {
      e.preventDefault();
      let changePasswordFormData = document.getElementById(
        "changePasswordForm"
      );
      let formData = new FormData(changePasswordFormData);
      formData.set(
        "current_password",
        sha256(formData.get("current_password"))
      );
      formData.set("password", sha256(formData.get("password")));
      formData.set(
        "password_confirmation",
        sha256(formData.get("password_confirmation"))
      );
      let requestURL = window.$apiURL.toString() + "users/changepassword";
      const requestOptions = {
        crossDomain: true,
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
        },
        body: formData,
      };
      const responsePromise = await fetch(requestURL, requestOptions);
      const data = await responsePromise.json();
      if (!responsePromise.ok) {
        setCurrPasswordHasError(true);
        setCurrPasswordErrorText(data.message);
      } else {
        setPasswordChangeResultText(data.message + " !");
        setTimeout(function () {
          window.location.replace("/Profile");
        }, 1500);
      }
    }
  };

  return (
    <div>
      <Container component="main" maxWidth="xs" className="divProfile">
        <CssBaseline />
        <Typography component="h1" variant="h3">
          Changement de mot de passe
        </Typography>
        <br />
        <Typography component="h3" variant="h6" style={{ minHeight: "32px" }}>
          {passwordChangeResultText}
        </Typography>
        <br />
        <form id="changePasswordForm" autoComplete>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                helperText={currPasswordHasError ? currPasswordErrorText : ""}
                error={currPasswordHasError}
                onChange={(e) => onChangeCurrPassword(e)}
                name="current_password"
                label="Mot de passe actuel"
                type="password"
                autoComplete="current-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                helperText={newPasswordHasError ? newPasswordErrorText : ""}
                error={newPasswordHasError}
                onChange={(e) => onChangeNewPassword(e)}
                name="password"
                label="Nouveau mot de passe"
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                helperText={newPasswordHasError ? newPasswordErrorText : ""}
                error={newPasswordHasError}
                onChange={(e) => onChangeNewPasswordConfirmation(e)}
                name="password_confirmation"
                label="Confirmation du nouveau mot de passe"
                type="password"
              />
            </Grid>
            <br />
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                onClick={(e) => changePasswordHandler(e)}
                variant="contained"
                color="primary"
                style={{ marginTop: "10px", backgroundColor: "#857A73" }}
              >
                {" "}
                Changer le mot de passe
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                style={{ backgroundColor: "#857A73" }}
                fullWidth
                onClick={() => window.location.replace("/Profile")}
                variant="contained"
                color="primary"
              >
                {" "}
                Annuler
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
}

export default ChangePassword;
