import React, { useState } from "react";
import { sha256 } from "js-sha256";
import { Button, TextField, Grid } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import "../../../css/Register.css";

function Register() {
  const [lNameErrorText, setlNameErrorText] = useState("");
  const [lNameHasError, setlNameHasError] = useState(false);
  const [fNameErrorText, setfNameErrorText] = useState("");
  const [fNameHasError, setfNameHasError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");
  const [emailHasError, setEmailHasError] = useState(false);
  const [passwordErrorText, setPasswordErrorText] = useState("");
  const [passwordHasError, setPasswordHasError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [lName, setLName] = useState("");
  const [fName, setFName] = useState("");

  //Permet de stocker les changements du champs cli_nom
  const onChangeLName = (e) => {
    setLName(e.target.value);
    setlNameHasError(false);
  };
  //Permet de stocker les changements du champs cli_prenom
  const onChangeFName = (e) => {
    setFName(e.target.value);
    setfNameHasError(false);
  };
  //Permet de stocker les changements du champs email
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    setEmailHasError(false);
  };
  //Permet de stocker les changements du champs password
  const onChangePassword = (e) => {
    setPassword(e.target.value);
    setPasswordHasError(false);
  };
  //Permet de stocker les changements du champs password_confirmation
  const onChangePasswordConfirmation = (e) => {
    setPasswordConfirmation(e.target.value);
    setPasswordHasError(false);
  };

  // Vérifie que tous les champs obligatoires soient remplis correctement
  function validate() {
    let errors = false;
    if (lName.length === 0) {
      errors = true;
      setlNameHasError(true);
      setlNameErrorText("Le nom de famille doit être rempli !");
    }
    if (fName.length === 0) {
      errors = true;
      setfNameHasError(true);
      setfNameErrorText("Le prénom doit être rempli !");
    }
    if (
      !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
        email.toString()
      )
    ) {
      errors = true;
      setEmailHasError(true);
      setEmailErrorText("Le format de l'email n'est pas correct !");
    }
    if (email.length === 0) {
      errors = true;
      setEmailHasError(true);
      setEmailErrorText("Email doit être rempli !");
    }
    if (password.length === 0 || passwordConfirmation.length === 0) {
      errors = true;
      setPasswordHasError(true);
      setPasswordErrorText("Vous devez entrer un mot de passe !");
    }
    if (password.length < 8 || passwordConfirmation.length < 8) {
      errors = true;
      setPasswordHasError(true);
      setPasswordErrorText(
        "Le mot de passe doit faire au moins 8 caractères !"
      );
    }
    if (password !== passwordConfirmation) {
      errors = true;
      setPasswordHasError(true);
      setPasswordErrorText("Les deux mots de passe doivent correspondre !");
    }

    return errors;
  }

  // Enregistre l'utilisateur dans la base de données
  const registerHandler = async (e) => {
    e.preventDefault();
    const errors = validate();
    if (!errors) {
      e.preventDefault();
      let requestURL = window.$apiURL.toString() + "register/user";
      let RegisterFormData = document.getElementById("registerForm");
      let formData = new FormData(RegisterFormData);
      formData.set("password", sha256(formData.get("password")));
      formData.set(
        "password_confirmation",
        sha256(formData.get("password_confirmation"))
      );
      formData.append("isAdmin", "0");
      formData.append("isValidated", "0");
      const requestOptions = {
        crossDomain: true,
        method: "POST",
        headers: { Accept: "application/json" },
        body: formData,
      };
      const responsePromise = await fetch(requestURL, requestOptions);
      const data = await responsePromise.json();
      if (!responsePromise.ok) {
        if (data.error.email !== null) {
          setEmailHasError(true);
          setEmailErrorText("Cette adresse email est déjà prise");
        }
      } else {
        localStorage.setItem("user_mail", data.success.email);
        window.location.replace("/MailNotificationPage");
      }
    }
  };

  return (
    <div>
      <Container component="main" maxWidth="xs" className="divRegister">
        <CssBaseline />
        <Typography
          component="h1"
          variant="h3"
          style={{ paddingBottom: "10%" }}
        >
          Inscription
        </Typography>
        <br />
        <br />
        <form id="registerForm" autoComplete="on">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                autoComplete="family-name"
                name="cli_nom"
                helperText={lNameHasError ? lNameErrorText : ""}
                error={lNameHasError}
                onChange={(e) => onChangeLName(e)}
                required
                fullWidth
                id="lastName"
                label="Nom de Famille"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                onChange={(e) => onChangeFName(e)}
                autoComplete="first-name"
                name="cli_prenom"
                required
                helperText={fNameHasError ? fNameErrorText : ""}
                error={fNameHasError}
                fullWidth
                id="firstName"
                label="Prénom"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                onChange={(e) => onChangeEmail(e)}
                helperText={emailHasError ? emailErrorText : ""}
                error={emailHasError}
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                helperText={passwordHasError ? passwordErrorText : ""}
                error={passwordHasError}
                onChange={(e) => onChangePassword(e)}
                name="password"
                label="Mot de passe"
                type="password"
                id="password"
                autoComplete="current-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                helperText={passwordHasError ? passwordErrorText : ""}
                error={passwordHasError}
                onChange={(e) => onChangePasswordConfirmation(e)}
                name="password_confirmation"
                label="Confirmation de mot de passe"
                type="password"
                id="password"
                autoComplete="current-password"
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ paddingBottom: "10%", paddingTop: "10%" }}
            >
              <Button
                style={{ backgroundColor: "#857A73" }}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={(e) => registerHandler(e)}
              >
                {" "}
                S'enregistrer
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
}

export default Register;
