import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Menu from "./Screens/Components/Menu/Menu";
import Home from "./Screens/Components/Home/Home";
import Footer from "./Screens/Components/Footer/Footer";
import Login from "./Screens/Components/Login/Login";
import Register from "./Screens/Components/Register/Register";
import ForgotPassword from "./Screens/Components/Login/ForgotPassword";
import MailVerifiedPage from "./Screens/Components/Register/MailVerifiedPage";
import LoginCoach from "./Screens/Components/Login/LoginCoach";
import NewPassword from "./Screens/Components/Login/NewPassword";
import MailNotificationPage from "./Screens/Components/MailNotification/MailNotificationPage";
import PrivateRoute from "./Screens/Components/PrivateRoutes/PrivateRoute";
import Profile from "./Screens/Components/Profile/Profile";
import PrivateRouteAdmin from "./Screens/Components/PrivateRoutes/PrivateRouteAdmin";
import ProfileCoach from "./Screens/Components/Coach/ProfileCoach";
import Plannings from "./Screens/Components/User/Plannings";
import PlanningsGratuit from "./Screens/Components/User/PlanningsGratuit";
import PlanningsVIP from "./Screens/Components/User/PlanningsVIP";
import Planning from "./Screens/Components/User/Planning";
import PlanningVipDiscUser from "./Screens/Components/User/PlanningVipDiscUser";
import ChangeSubscription from "./Screens/Components/User/ChangeSubscription";
import InfoPlans from "./Screens/Components/InformationPages/InfoPlans";
import FAQ from "./Screens/Components/InformationPages/FAQ";
import Contact from "./Screens/Components/InformationPages/Contact";
import SubscriptionRequestSubmitted from "./Screens/Components/User/SubscriptionRequestSubmitted";
import CheckSubscriptionStatus from "./Screens/Components/User/CheckSubscriptionStatus";
import RequestsMenu from "./Screens/Components/Coach/Requests/RequestsMenu";
import VipRequests from "./Screens/Components/Coach/Requests/VipRequests";
import VipPlanRequests from "./Screens/Components/Coach/Requests/VipPlanRequests";
import RequestDetail from "./Screens/Components/Coach/Requests/RequestDetail";
import Clients from "./Screens/Components/Coach/Clients";
import DetailClient from "./Screens/Components/Coach/DetailClient";
import HistDiscSub from "./Screens/Components/Coach/HistDiscSub";
import NotFound from "./Screens/Components/ErrorPages/NotFound";
import ChangePassword from "./Screens/Components/User/ChangePassword";
import ChangePasswordCoach from "./Screens/Components/Coach/ChangePasswordCoach";
import PrivateRouteSuperAdmin from "./Screens/Components/PrivateRoutes/PrivateRouteSuperAdmin";
import UserManagementMenu from "./Screens/Components/Coach/UserManagement/UserManagementMenu";
import FreeUserManagement from "./Screens/Components/Coach/UserManagement/FreeUserManagement";
import VipUserManagement from "./Screens/Components/Coach/UserManagement/VipUserManagement";
import PlanningVipDisc from "./Screens/Components/Coach/PlanningManagement/PlanningVipDisc";
import HistReqVipPlan from "./Screens/Components/Coach/HistReqVipPlan";
import PlanningCreation from "./Screens/Components/Coach/PlanningManagement/PlanningCreation";
import CreateCoach from "./Screens/Components/Coach/UserManagement/CreateCoach";
import Factures from "./Screens/Components/Payment/Factures";
import DetailFacture from "./Screens/Components/Payment/DetailFacture";
import DetailFactureCoach from "./Screens/Components/Payment/DetailFactureCoach";
import PaypalPayment from "./Screens/Components/Payment/PaypalPayment";
import CreerFacture from "./Screens/Components/Payment/CreerFacture";
import FacturesCoach from "./Screens/Components/Payment/FacturesCoach";
import PlanningRequestSubmitted from "./Screens/Components/User/PlanningRequestSubmitted";
import FacturesClientCoach from "./Screens/Components/Payment/FacturesClientCoach";
import NewFaq from "./Screens/Components/Coach/FaqManagement/NewFaq";
import FAQDetail from "./Screens/Components/InformationPages/FaqDetail";
import Videos from "./Screens/Components/Coach/PlanningManagement/Videos";
import NewVideo from "./Screens/Components/Coach/PlanningManagement/NewVideo";
import PlanningVideos from "./Screens/Components/User/PlanningVideos";
import PreviousRaces from "./Screens/Components/Profile/PreviousRaces";
import NewDefi from "./Screens/Components/Coach/DefiManagement/NewDefi";
import Defis from "./Screens/Components/InformationPages/Defis";
import DefiDetail from "./Screens/Components/InformationPages/DefiDetail";
import DetailCliDefi from "./Screens/Components/User/DetailCliDefi";
import GestionObjectifs from "./Screens/Components/InformationPages/GestionObjectifs";
import ObjectifDetail from "./Screens/Components/InformationPages/ObjectifDetail";
import NewObjectif from "./Screens/Components/Coach/DefiManagement/NewObjectif";

function App() {
  return (
    <Router>
      <div className="App">
        <header>
          <Menu />
        </header>
        <div className="MainScrollArea">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/infoPlans" component={InfoPlans} />
            <Route exact path="/404" component={NotFound} />
            <Route exact path="/FAQ" component={FAQ} />
            <Route exact path="/defis" component={Defis} />
            <Route exact path="/user/defi/:defiID" component={DetailCliDefi} />
            <Route path="/FAQ/:id_faq" component={FAQDetail} />
            <Route path="/defis/:defiID" component={DefiDetail} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <PrivateRoute exact path="/newDefi" component={NewDefi} />
            <PrivateRoute exact path="/newObjectif" component={NewObjectif} />
            <PrivateRoute
              exact
              path="/gestionObjectifs"
              component={GestionObjectifs}
            />
            <Route path="/objectifs/:objID" component={ObjectifDetail} />
            <Route
              exact
              path="/MailNotificationPage"
              component={MailNotificationPage}
            />
            <Route exact path="/ForgotPassword" component={ForgotPassword} />
            <Route
              exact
              path="/MailVerifiedPage"
              component={MailVerifiedPage}
            />
            <Route exact path="/Admin" component={LoginCoach} />
            <Route path="/NewPassword" component={NewPassword} />
            <PrivateRoute exact path="/Profile" component={Profile} />
            <PrivateRoute exact path="/mescourses" component={PreviousRaces} />
            <PrivateRoute
              exact
              path="/ChangePassword"
              component={ChangePassword}
            />
            <PrivateRoute exact path="/Plannings" component={Plannings} />
            <PrivateRoute
              exact
              path="/PlanningsGratuit"
              component={PlanningsGratuit}
            />
            <PrivateRoute exact path="/PlanningsVIP" component={PlanningsVIP} />
            <PrivateRoute
              exact
              path="/planningVideos"
              component={PlanningVideos}
            />
            <PrivateRouteAdmin exact path="/Clients" component={Clients} />
            <PrivateRouteAdmin
              path="/DetailClient/:id_client"
              component={DetailClient}
            />
            <PrivateRouteAdmin
              path="/HistDiscSub/:id_request"
              component={HistDiscSub}
            />
            <PrivateRoute path="/Planning/:id_planning" component={Planning} />
            <PrivateRoute
              path="/PlanningVipDiscUser/:id_planning"
              component={PlanningVipDiscUser}
            />
            <PrivateRoute
              exact
              path="/ChangeSubscription"
              component={ChangeSubscription}
            />
            <PrivateRoute
              exact
              path="/SubscriptionRequestSubmitted"
              component={SubscriptionRequestSubmitted}
            />
            <PrivateRoute
              exact
              path="/PlanningRequestSubmitted"
              component={PlanningRequestSubmitted}
            />
            <PrivateRoute
              exact
              path="/CheckSubscriptionStatus"
              component={CheckSubscriptionStatus}
            />
            <PrivateRouteAdmin
              exact
              path="/ProfileCoach"
              component={ProfileCoach}
            />
            <PrivateRouteAdmin
              exact
              path="/ChangePasswordCoach"
              component={ChangePasswordCoach}
            />
            <PrivateRouteAdmin
              exact
              path="/Requests"
              component={RequestsMenu}
            />
            <PrivateRouteAdmin
              exact
              path="/VipRequests"
              component={VipRequests}
            />
            <PrivateRouteAdmin
              exact
              path="/VipPlanRequests"
              component={VipPlanRequests}
            />
            <PrivateRouteAdmin
              path="/RequestDetail/:id_request"
              component={RequestDetail}
            />
            <PrivateRouteAdmin
              path="/HistReqVipPlan/:id_user"
              component={HistReqVipPlan}
            />
            <PrivateRouteAdmin
              path="/PlanningVipDisc/request/:id_request"
              component={PlanningVipDisc}
            />
            <PrivateRouteAdmin
              path="/PlanningCreation/:id_planning"
              component={PlanningCreation}
            />
            <PrivateRouteSuperAdmin
              exact
              path="/management"
              component={UserManagementMenu}
            />
            <PrivateRouteSuperAdmin
              exact
              path="/CreateCoach"
              component={CreateCoach}
            />
            <PrivateRouteSuperAdmin
              exact
              path="/GestionUtilisateursGratuits"
              component={FreeUserManagement}
            />
            <PrivateRouteSuperAdmin
              exact
              path="/GestionUtilisateursVip"
              component={VipUserManagement}
            />
            <PrivateRoute exact path="/Factures" component={Factures} />
            <PrivateRoute
              path="/Facture/:id_facture"
              component={DetailFacture}
            />
            <PrivateRoute
              path="/PaypalPayment/:id_facture"
              component={PaypalPayment}
            />
            <PrivateRouteAdmin
              path="/NewFacture/:id_client"
              component={CreerFacture}
            />
            <PrivateRouteAdmin
              exact
              path="/FacturesCoach/"
              component={FacturesCoach}
            />
            <PrivateRouteAdmin
              path="/FactureCoach/:id_facture"
              component={DetailFactureCoach}
            />
            <PrivateRouteAdmin
              path="/FacturesClient/:id_client"
              component={FacturesClientCoach}
            />
            <PrivateRouteSuperAdmin exact path="/NewFaq" component={NewFaq} />
            <PrivateRouteSuperAdmin exact path="/videos" component={Videos} />
            <PrivateRouteSuperAdmin
              exact
              path="/newVideo"
              component={NewVideo}
            />
          </Switch>
        </div>
        <footer>
          <Footer />
        </footer>
      </div>
    </Router>
  );
}

export default App;
