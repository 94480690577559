// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { sha256 } from "js-sha256";

const PrivateRouteSuperAdmin = ({ component: Component, ...rest }) => {
  // Add your own authentication on the below line.

  let isSuperAdmin = false;

  // Permet de vérifier si l'utilisateur connecté en tant que super admin
  if (localStorage.getItem("cbs_perform_token") !== null) {
    if (
      localStorage.getItem("cbs_perform_token_is_super_admin") === null ||
      localStorage.getItem("cbs_perform_token_is_super_admin") !==
        sha256(localStorage.getItem("cbs_perform_token").substring(606, 750))
    ) {
      isSuperAdmin = false;
    } else {
      isSuperAdmin = true;
    }
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isSuperAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

export default PrivateRouteSuperAdmin;
