import React, {useEffect, useState} from "react";
import {Grid, TextField} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import '../../../css/Profile.css';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";

function CheckSubscriptionStatus() {
    const [userIsSet, setUserIsSet] = useState(0)
    const [user, setUser] = useState({
       'id' : '',
       'cli_nom' : '',
       'cli_prenom' : '',
       'cli_abonnement' : '',
    });
    const [exchangesIsSet, setExchangesIsSet] = useState(0)
    const [exchanges, setExchanges] = useState('')
    const [exchangeId, setExchangeId] = useState('');

    useEffect(() => {
        getUser()
        getUserExchanges()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Permet de récupérer les informations du clients connectés
    const getUser = async () => {
        let requestURL = window.$apiURL.toString()+'users'
        let requestOptions = {
            method : 'GET',
            headers : {
                'Accept' : 'application/json',
                'Authorization' : 'Bearer '+localStorage.getItem('cbs_perform_token'),
            },
        }
        const responsePromise = await fetch(requestURL, requestOptions);
        // eslint-disable-next-line no-unused-vars
        const data = await responsePromise.json();
        setUser(user);
        setUserIsSet(1)
    }

    //Récupère les échanges entre le coach et le client par rapport à une souscription à l'abonnement VIP
    const getUserExchanges = async () => {
        let requestURL = window.$apiURL.toString()+'users/exchanges/get_user_messages?subject=vip_subscription'
        let requestOptions = {
            method : 'GET',
            headers : {
                'Accept' : 'application/json',
                'Authorization' : 'Bearer '+localStorage.getItem('cbs_perform_token'),
            },
        }
        const responsePromise = await fetch(requestURL, requestOptions);
        const data = await responsePromise.json();
        setExchangeId(data[0].uce_id)
        setExchanges(data)
        setExchangesIsSet(1)
    }

    const [comment, setComment] = useState('');
    const [commentError, setCommentError] = useState('');

    // Permet de stocker l'écriture d'un nouveau message
    const onChangeComment = (e) =>{
        setComment(e.target.value)
        setCommentError('')
    }

    // Permet de vérifier qu'un message a bien été écrit avant l'envoi
    function validate(){
        let errors = false;
        if (comment.length === 0){
            errors = true;
            setCommentError('Veuillez saisir un commentaire')
        }
        return errors;
    }

    // Permet de sauvegarder le nouveau message écrit dans la base de données
    const handleNewMessage = async () => {
        let errors = validate();
        if(!errors){
            let subscriptionChangeFormData = new FormData();
            subscriptionChangeFormData.set('uce_id', exchangeId)
            subscriptionChangeFormData.set('message', comment);
            let requestURL = window.$apiURL.toString()+"users/exchanges/send_message_to_coach"
            let requestOptions = {
                method : 'POST',
                headers : {
                    'Accept' : 'application/json',
                    'Authorization' : 'Bearer '+localStorage.getItem('cbs_perform_token'),
                },
                body : subscriptionChangeFormData
            }
            const responsePromise = await fetch(requestURL, requestOptions);
            // eslint-disable-next-line no-unused-vars
            const data = await responsePromise.json();
            if (responsePromise.ok){
                window.location.replace("/CheckSubscriptionStatus")
            }
        }
    }

    // Permet de mettre en forme l'affichage des messages entre le client et son coach
    // eslint-disable-next-line array-callback-return
    const commentsList = Object.keys(exchanges).map(key => {
        if (exchanges[key].from === 'user'){
            return (
                <Grid container spacing={2} key={key}>
                    <Grid item xs={12} style={{textAlign : 'right', fontSize : '12px'}}>
                        Vous{' '}{new Date(exchanges[key].created_at).toLocaleString('fr')}
                    </Grid>
                    <Grid item xs={3}/>
                    <Grid item xs={9} style={{ textAlign : 'right',  paddingTop : 0}}>
                        <Box
                            style={{maxWidth : 'fit_content', backgroundColor : '#cfd8dc', borderRadius : 5,
                                    textAlign : 'left', margin : 0}}
                        >
                            <p style={{padding : '10px', margin : 0}}>{exchanges[key].message}</p>
                        </Box>
                    </Grid>
                </Grid>
            )
        }else if (exchanges[key].from === 'coach'){
            return (
                <Grid container spacing={2} key={key}>
                    <Grid item xs={12} style={{textAlign : 'left', fontSize : '12px'}}>
                        Coach : {exchanges[key].coa_nom}{' '}{exchanges[key].coa_prenom+' '+new Date(exchanges[key].created_at).toLocaleString('fr')}
                    </Grid>

                    <Grid item xs={9} style={{ textAlign : 'right',  paddingTop : 0}}>
                        <Box
                            style={{maxWidth : 'fit_content', backgroundColor : '#eeeeee', borderRadius : 5,
                                textAlign : 'left', margin : 0}}
                        >
                            <p style={{padding : '10px', margin : 0}}>{exchanges[key].message}</p>
                        </Box>
                    </Grid>
                    <Grid item xs={3}/>
                </Grid>
            )
        }
        }
    )

    const [open, setOpen] = React.useState(false);

    const handleDialogOpen = () => {
        setOpen(true);
    }
    const handleDialogClose = () => {
        setOpen(false);
    }

    // Permet de supprimer les échanges entre le coach et l'utilisateur concernant une demande de souscription à l'abonnement VIP
    const handleDeleteVipRequest = async () => {
        let requestURL = window.$apiURL.toString()+"users/cancel_subscription_request/"+exchangeId
        let requestOptions = {
            method : 'DELETE',
            headers : {
                'Accept' : 'application/json',
                'Authorization' : 'Bearer '+localStorage.getItem('cbs_perform_token'),
            },
        }
        const responsePromise = await fetch(requestURL, requestOptions);
        // eslint-disable-next-line no-unused-vars
        const data = await responsePromise.json();
        if (responsePromise.ok){
            window.location.replace("/Profile");
        }
    }

    // Permet d'afficher le contenu dès que le contenu a été chargé
    if (exchangesIsSet === 1 && userIsSet === 1){
        return(
            <div>
                <Container component="main" maxWidth="xs" className="divProfile">
                    <Typography component="h3" variant="h5" style={{paddingBottom : '20%'}}>
                        Suivi de votre demande
                    </Typography>
                    <div
                        style={{height : '40vh', width : '100%',
                                overflow : 'hidden',
                                position : 'relative'}}
                    >
                        <div style={{position : 'absolute',
                                     top : 0, bottom : 0, overflow: 'scroll',
                                     overflowX : 'hidden', height : '100%'}}>
                            {commentsList}
                        </div>

                    </div>

                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{paddingTop : '15%', textAlign : 'left'}}>
                            Nouveau message
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom : '10%'}}>
                            <TextField
                                variant={"outlined"}
                                multiline
                                rows={4}
                                name={'userComments'}
                                onChange={e => onChangeComment(e)}
                                error={commentError !== ''}
                                helperText={commentError !== '' ? commentError : ''}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <Button
                                style={{backgroundColor : '#857A73'}}
                                variant={"contained"}
                                color={"primary"}
                                onClick={e => handleNewMessage(e)}
                                fullWidth
                            >
                                Envoyer votre message
                            </Button>
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom : '10%'}}>
                            <Button
                                style={{backgroundColor : '#f44336', color : 'white'}}
                                variant={"contained"}
                                onClick={() => handleDialogOpen()}
                                fullWidth
                            >
                                Supprimer votre demande
                            </Button>
                            <Dialog
                                open={open}
                                onClose={handleDialogClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                style={{ maxWidth : '100%'}}
                            >
                                <DialogContent style={{maxWidth : '90%', paddingRight : '5%', paddingLeft: '5%'}}>
                                    <DialogContentText id="alert-dialog-description" style={{color : 'black', fontSize : 20}}>
                                        Êtes-vous sûr de vouloir supprimer la demande d'abonnement VIP ?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions style={{ maxWidth : '92%', paddingRight : '4%', paddingLeft: '4%'}}>
                                    <Button onClick={handleDialogClose} color="primary" autofocus>
                                        Annuler
                                    </Button>
                                    <Button onClick={handleDeleteVipRequest} style={{color : '#f44336'}}>
                                        Supprimer
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        )
    }else{
        return(
            <div style={{paddingTop : '50%'}}><CircularProgress style={{color : '#857A73'}}/></div>
        )
    }


}
export default CheckSubscriptionStatus;