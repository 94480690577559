import React, { useEffect, useState } from "react";
import "../../../css/Infos.css";
import {
  Button,
  Grid,
  TextField,
  Card,
  CircularProgress,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";

import { Link } from "react-router-dom";

function GestionObjectifs() {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      name: {
        fontSize: 16,
        textAlign: "left",
        color: "white",
        padding: "10px",
        backgroundColor: "#857A73",
        opacity: "70%",
        margin: 0,
      },
      pos: {
        marginBottom: 12,
      },
    })
  );
  const classes = useStyles();

  useEffect(() => {
    getObjectifs();
  }, []);

  const [isReady, setIsReady] = useState(-1);
  const [objectifs, setObjectifs] = useState([]);
  const [query, setQuery] = useState("");

  const onChangeQuery = (e) => {
    setQuery(e.target.value);
  };
  const searchOnEnter = (e) => {
    if (e.key === "Enter") {
      searchObjectifs();
    }
  };

  const getObjectifs = async () => {
    let requestURL = window.$apiURL.toString() + "objectifs/coachs/objectifs";
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    setObjectifs(data);
    setIsReady(1);
  };

  const searchObjectifs = async () => {
    let requestURL;
    if (query.length === 0) {
      requestURL = window.$apiURL.toString() + "objectifs/coachs/objectifs";
    } else {
      requestURL =
        window.$apiURL.toString() + "objectifs/coachs/search/" + query;
    }
    setIsReady(3);
    setObjectifs([]);
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    setObjectifs(data);
    if (data.length === 0) {
      setIsReady(2);
    } else {
      setIsReady(1);
    }
  };

  const objectifCards = Object.keys(objectifs).map((key, index) => (
    <Grid item xs={12} key={key}>
      <Link
        to={"/objectifs/" + objectifs[key].OBJ_ID}
        style={{ textDecoration: "none" }}
      >
        <Card variant="outlined" style={{ minWidth: "100%" }} key={index}>
          <Typography
            className={classes.name}
            color="textSecondary"
            gutterBottom
            align="right"
          >
            {objectifs[key].OBJ_NOM}
          </Typography>
        </Card>
      </Link>
    </Grid>
  ));
  // Affichage de la page
  if (isReady === -1) {
    return (
      <div style={{ paddingTop: "50%" }}>
        <CircularProgress style={{ color: "#857A73" }} />
      </div>
    );
  } else if (isReady === 1) {
    return (
      <div>
        <Container component="main" maxWidth="xs" className="divClients">
          <Typography
            component="h4"
            variant="h4"
            style={{ paddingBottom: "10%" }}
          >
            Objectifs
          </Typography>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{ justifyContent: "flex-end", display: "flex" }}
            >
              <Button
                onClick={() => window.location.replace("/newObjectif")}
                style={{ backgroundColor: "#857A73" }}
                variant={"contained"}
                color={"primary"}
              >
                Nouvel objectif
              </Button>
            </Grid>
            <Grid item xs={9} style={{ paddingBottom: "10%" }}>
              <TextField
                variant="outlined"
                label="Recherche"
                value={query}
                onChange={(e) => onChangeQuery(e)}
                onKeyPress={(e) => searchOnEnter(e)}
              />
            </Grid>
            <Grid item xs={3}>
              <Button style={{ height: "56px" }} onClick={searchObjectifs}>
                <SearchIcon />
              </Button>
            </Grid>
            {objectifCards}
          </Grid>
        </Container>
      </div>
    );
  } else if (isReady === 2) {
    return (
      <div>
        <Container component="main" maxWidth="xs" className="divClients">
          <Typography
            component="h4"
            variant="h4"
            style={{ paddingBottom: "20%" }}
          >
            Objectifs
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={9} style={{ paddingBottom: "10%" }}>
              <TextField
                variant="outlined"
                label="Recherche"
                value={query}
                onChange={(e) => onChangeQuery(e)}
                onKeyPress={(e) => searchOnEnter(e)}
              />
            </Grid>
            <Grid item xs={3}>
              <Button style={{ height: "56px" }} onClick={searchObjectifs}>
                <SearchIcon />
              </Button>
            </Grid>
            <Grid>
              <Typography
                component="h6"
                variant="h6"
                style={{ paddingBottom: "10%" }}
              >
                Aucun objectif trouvé
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  } else if (isReady === 3) {
    return (
      <div>
        <Container component="main" maxWidth="xs" className="divClients">
          <Typography
            component="h4"
            variant="h4"
            style={{ paddingBottom: "20%" }}
          >
            Objectifs
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={9} style={{ paddingBottom: "10%" }}>
              <TextField
                variant="outlined"
                label="Recherche"
                value={query}
                onChange={(e) => onChangeQuery(e)}
              />
            </Grid>
            <Grid item xs={3}>
              <Button style={{ height: "56px" }} onClick={searchObjectifs}>
                <SearchIcon />
              </Button>
            </Grid>
            <Grid>
              <CircularProgress style={{ color: "#857A73" }} />
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  } else {
    return (
      <div style={{ paddingTop: "50%" }}>
        <CircularProgress style={{ color: "#857A73" }} />
      </div>
    );
  }
}

export default GestionObjectifs;
