import React from "react";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";

function UserManagementMenu() {
  // Affichage de la page
  return (
    <div>
      <Container component={"main"} maxWidth={"xs"} className="divRequest">
        <Typography
          component="h1"
          variant="h3"
          style={{ paddingBottom: "15%" }}
        >
          Gestion de la plateforme
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              style={{ backgroundColor: "#857A73" }}
              onClick={() =>
                window.location.replace("/GestionUtilisateursGratuits")
              }
              variant={"contained"}
              fullWidth
              color={"primary"}
            >
              Gestion des utilisateurs gratuits
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              style={{ backgroundColor: "#857A73" }}
              onClick={() => window.location.replace("/GestionUtilisateursVip")}
              variant={"contained"}
              fullWidth
              color={"primary"}
            >
              Gestion des utilisateurs VIP
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              style={{ backgroundColor: "#857A73" }}
              onClick={() => window.location.replace("/CreateCoach")}
              variant={"contained"}
              fullWidth
              color={"primary"}
            >
              Créer un coach
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              style={{ backgroundColor: "#857A73" }}
              onClick={() => window.location.replace("/NewFaq")}
              variant={"contained"}
              fullWidth
              color={"primary"}
            >
              Nouvelle FAQ
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              style={{ backgroundColor: "#857A73" }}
              onClick={() => window.location.replace("/FAQ")}
              variant={"contained"}
              fullWidth
              color={"primary"}
            >
              Toutes les FAQ
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              style={{ backgroundColor: "#857A73" }}
              onClick={() => window.location.replace("/newVideo")}
              variant={"contained"}
              fullWidth
              color={"primary"}
            >
              Créer nouvelle video annexe
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              style={{ backgroundColor: "#857A73" }}
              onClick={() => window.location.replace("/videos")}
              variant={"contained"}
              fullWidth
              color={"primary"}
            >
              Gestion des vidéos annexe
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              style={{ backgroundColor: "#857A73" }}
              onClick={() => window.location.replace("/newDefi")}
              variant={"contained"}
              fullWidth
              color={"primary"}
            >
              Nouveau défi
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              style={{ backgroundColor: "#857A73" }}
              onClick={() => window.location.replace("/defis")}
              variant={"contained"}
              fullWidth
              color={"primary"}
            >
              Tous les défis
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              style={{ backgroundColor: "#857A73" }}
              onClick={() => window.location.replace("/gestionObjectifs")}
              variant={"contained"}
              fullWidth
              color={"primary"}
            >
              Gestion des objectifs
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
export default UserManagementMenu;
