import React, { useEffect, useRef, useState } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import "../../../css/Profile.css";
import Typography from "@material-ui/core/Typography";
import { Grid, MenuItem, TextField, Button } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { fr } from "date-fns/locale";
import Card from "@material-ui/core/Card";

function PaypalPayment() {
  // Vérification si paiement effectué et paypal lancé
  const [paidFor, setPaidFor] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const paypalButtonContainer = useRef(null);

  // Données de la facture
  let { id_facture } = useParams();
  const [montant, setMontant] = useState("");
  const [pai_code, setCode] = useState("");
  const [type, setType] = useState("");
  const [dateEmission, setDateEmission] = useState("");
  const [datePaiement, setDatePaiement] = useState("");
  const [statut, setStatut] = useState("");
  const [detail, setDetail] = useState("");
  const [verifStatut, setVerifStatut] = useState();
  const [isPaid, setIsPaid] = useState();

  // Permet de récupérer les détails d'une facture en particulier
  const getFactureDetail = async () => {
    let requestURL = window.$apiURL.toString() + "payments/" + id_facture;
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    if (!responsePromise.ok) {
      window.location.replace("/404");
    } else {
      setIsPaid(false);
      setMontant(data.pai_montant);
      setCode(data.pai_code);
      if (data.pai_type === "paypal") {
        setType(data.pai_type);
      } else {
        window.location.replace("/404");
      }
      setDetail(data.pai_detail);
      setDateEmission(data.pai_dateemission);
      setDatePaiement(data.pai_datepaiement);
      setStatut(data.pai_statut);
    }
  };

  // Données du client
  const [user, setUser] = useState({
    id: "",
    cli_nom: "",
    cli_prenom: "",
    cli_abonnement: "",
  });

  // Permet de récupérer les informations de l'utilisateur connecté
  const getUser = async () => {
    let requestURL = window.$apiURL.toString() + "users";
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    setUser(user);
  };

  // Définition du produit à payer avec montant et description
  const product = {
    price: montant,
    description: detail,
  };

  // Permet d'exécuter le script de paiement PayPal
  const executePaypalPayment = async () => {
    if (pai_code === null) {
      if (type === "paypal") {
        const script = document.createElement("script");
        script.src =
          "https://www.paypal.com/sdk/js?client-id=ATKhJB64NANED5PtTgAmmHi0HqFsMKfyde5KaPoSfm-DEXbd84sGWAlFCpe_0kqP6KfOWgM8rTWbkUQr&currency=CHF";
        script.addEventListener("load", () => setLoaded(true));
        document.body.appendChild(script);
        if (paypalButtonContainer.current === null) {
          return;
        }
        if (loaded) {
          setTimeout(() => {
            window.paypal
              .Buttons({
                createOrder: (data, actions) => {
                  return actions.order.create({
                    purchase_units: [
                      {
                        description: product.description,
                        amount: {
                          currency_code: "CHF",
                          value: product.price,
                        },
                      },
                    ],
                  });
                },
                onApprove: async (data, actions) => {
                  const order = await actions.order.capture();
                  setPaidFor(true);
                  let codeTest = order.id;
                  setCode(codeTest);
                  let updateFactureFormData = document.getElementById(
                    "updateFacturePaypalForm"
                  );
                  let formData = new FormData(updateFactureFormData);
                  formData.set("pai_code", order.id);
                  let requestURL =
                    window.$apiURL.toString() +
                    "payments/confirmerpaypal/" +
                    id_facture;
                  let requestOptions = {
                    crossDomain: true,
                    method: "POST",
                    headers: {
                      Accept: "application/json",
                      Authorization:
                        "Bearer " + localStorage.getItem("cbs_perform_token"),
                    },
                    body: formData,
                  };
                  const responsePromise = await fetch(
                    requestURL,
                    requestOptions
                  );
                  const dataUpdate = await responsePromise.json();
                },
                onError: (err) => {},
              })
              .render(paypalButtonContainer.current);
          });
        }
      }
    }
  };

  useEffect(() => {
    getFactureDetail({});
    getUser();
    // Load PayPal Script
    executePaypalPayment();
  });

  // Affichage de la page
  return (
    <div className="App">
      <Container component="main" maxWidth="xs" className="divProfile">
        {paidFor ? (
          <div>
            <h1>Paiement effectué avec succès!</h1>
            <div>
              <form id="updateFacturePaypalForm" autoComplete="on">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      disabled={true}
                      value={detail}
                      helperText={"Détail de la facture"}
                      fullWidth
                      id="detail"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      disabled={true}
                      value={montant}
                      helperText={"montant"}
                      fullWidth
                      id="montant"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      disabled={true}
                      value={dateEmission}
                      helperText={"Date d'émission"}
                      format="dd-MM-yyyy"
                      fullWidth
                      id="dateEmission"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      disabled={true}
                      value={pai_code}
                      helperText={"Code de paiement"}
                      fullWidth
                      id="pai_code"
                    />
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        ) : (
          <div>
            <h1>
              {product.description} pour {product.price} CHF
            </h1>
            <div>
              <form id="updateFacturePaypalForm" autoComplete="on">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      disabled={true}
                      value={detail}
                      helperText={"Détail de la facture"}
                      fullWidth
                      id="detail"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      disabled={true}
                      value={montant}
                      helperText={"montant"}
                      fullWidth
                      id="montant"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      disabled={true}
                      value={dateEmission}
                      helperText={"Date d'émission"}
                      format="dd-MM-yyyy"
                      fullWidth
                      id="dateEmission"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      disabled={true}
                      value={pai_code}
                      helperText={"Code de paiement"}
                      fullWidth
                      id="pai_code"
                    />
                  </Grid>
                </Grid>
              </form>
            </div>

            <div ref={paypalButtonContainer} />
          </div>
        )}
      </Container>
    </div>
  );
}

export default PaypalPayment;
