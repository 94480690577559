import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Button,
  TextField,
  Typography,
  Card,
  makeStyles,
  Modal,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import ObjectifDetails from "./ObjectifDetails";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    boxShadow: theme.shadows[5],
    outline: 0,
  },
}));

export default function ObjectifsJour(props) {
  const { objectifs, setObjectifs } = props;
  const [objectifsDuJour, setObjectifsDuJour] = useState([]);
  const [objectifsBDD, setObjectifsBDD] = useState([]);
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState({
    newObjectif: false,
  });

  const classes = useStyles();
  const semaineJourVoulu = "semaine" + props.semaine + "jour" + props.jour;

  // On récupère les objectifs du jour à chaque modification de l'état de objectifs
  // exemple: ajout ou suppression d'un objectif dans la liste des objectifs globaux objectifs
  useEffect(() => {
    getObjectifsDuJour();
    getObjectifsBDD();
  }, []);

  // handleOpen et handleClose permettent de gérer l'ouverture
  // et la fermeture des fenetres modales de chaque objectif
  const handleOpen = (objectif) => {
    setOpen((prevState) => ({ ...prevState, [objectif]: true }));
  };

  const handleClose = (objectif) => {
    setOpen((prevState) => ({ ...prevState, [objectif]: false }));
  };

  // Permet de update le state de query et de précharger l'objectif s'il existe déjà dans la BDD
  const handleChange = (event, values) => {
    setQuery(values);
  };

  const handleDelete = (objectif) => {
    // On vérifie les objectifs qui appartiennent au bon jour et à la bonne semaine dans le cas de modification d'un défi
    if (Array.isArray(objectifs)) {
      setObjectifsDuJour(
        objectifsDuJour.filter((item) => item !== objectifsDuJour[objectif])
      );
    } else {
      const newObjectifs = { ...objectifsDuJour };
      delete newObjectifs[objectif];
      setObjectifsDuJour(newObjectifs);
    }
  };

  const getObjectifsDuJour = () => {
    // On vérifie les objectifs qui appartiennent au bon jour et à la bonne semaine dans le cas de modification d'un défi
    if (Array.isArray(objectifs)) {
      setObjectifsDuJour(
        objectifs.filter((objectif) => {
          return (
            objectif.OBJ_JOUR === props.jour &&
            objectif.OBJ_SEMAINE === props.semaine
          );
        })
      );
    } // le cas de la création d'un défi
    else {
      // on les extrait dans un autre state propre au jour
      setObjectifsDuJour(objectifs[semaineJourVoulu] || {});
    }
    // On crée le state pour gérer l'ouverture/fermeture des modales pour chaque objectif du jour
    for (const objectif in objectifsDuJour) {
      setOpen((prevState) => ({ ...prevState, [objectif]: false }));
    }
  };

  // On récupère tous les objectifs de la BDD pour pouvoir les proposer dans la barre de recherche
  const getObjectifsBDD = async () => {
    let requestURL = window.$apiURL.toString() + "objectifs/coachs/objectifs";
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    setObjectifsBDD(data);
  };

  // Enregistre les objectifs du jour dans le state du parent pour pouvoir les réouvrir en recliquant sur le bouton du jour
  const saveObjectifs = () => {
    if (Array.isArray(objectifs)) {
      const newObjectifs = objectifs.filter((objectif) => {
        return (
          objectif.OBJ_JOUR !== props.jour ||
          objectif.OBJ_SEMAINE !== props.semaine
        );
      });
      setObjectifs(newObjectifs.concat(objectifsDuJour));
    } else {
      setObjectifs((prevState) => ({
        // On reprend prevState pour ne pas supprimer les objectifs des autres semaines
        ...prevState,
        [semaineJourVoulu]: objectifsDuJour,
      }));
    }
    // On ferme la modale en appelant la fonction handleClose du parent
    props.handleCloseMain();
  };

  // Contient une représentation d'une carte pour chaque objectifs du jour
  const objectifsCards = Object.keys(objectifsDuJour).map((key, index) => (
    <Grid item xs={12} key={key}>
      <Card variant="outlined" style={{ minWidth: "100%" }} key={index}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Button
            style={{ textDecoration: "none", padding: 0 }}
            fullWidth
            onClick={() => handleOpen(key)}
          >
            <Typography
              color="textSecondary"
              gutterBottom
              style={{
                flex: 5,
                fontSize: 16,
                textAlign: "left",
                color: "white",
                padding: "10px",
                backgroundColor: "#857A73",
                opacity: "70%",
                margin: 0,
              }}
            >
              {objectifsDuJour[key].OBJ_NOM}
            </Typography>
          </Button>
          <Button
            style={{ textDecoration: "none", padding: 0 }}
            onClick={() => handleDelete(key)}
          >
            <DeleteIcon />
          </Button>
        </div>
      </Card>
      <Modal
        open={open[key] || false}
        className={classes.modal}
        onClose={() => handleClose(key)}
      >
        <div className={classes.paper}>
          <ObjectifDetails
            semaine={props.semaine}
            jour={props.jour}
            semaineJourVoulu={semaineJourVoulu}
            objectifsDuJour={objectifsDuJour}
            setObjectifsDuJour={setObjectifsDuJour}
            infoObjectif={objectifsDuJour[key]}
            clefObjet={key}
            handleClose={() => handleClose(key)}
            objectifs={objectifs}
          />
        </div>
      </Modal>
    </Grid>
  ));

  return (
    <Container component="main" maxWidth="md" className="divClients">
      <Typography
        component="h3"
        variant="h4"
        style={{ paddingBottom: "10%", paddingTop: "10%" }}
      >
        Objectifs semaine {props.semaine} jour {props.jour}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={9} style={{ paddingBottom: "10%" }}>
          <Autocomplete
            id="searchObjectifs"
            options={objectifsBDD}
            getOptionLabel={(option) => option.OBJ_NOM}
            onChange={handleChange}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label="Recherche" variant="outlined" />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            style={{ height: "56px" }}
            onClick={() => handleOpen("newObjectif")}
          >
            <AddCircleIcon />
          </Button>
          <Modal
            open={open.newObjectif}
            className={classes.modal}
            onClose={() => handleClose("newObjectif")}
          >
            <div className={classes.paper}>
              {query !== "" ? (
                <ObjectifDetails
                  semaine={props.semaine}
                  jour={props.jour}
                  semaineJourVoulu={semaineJourVoulu}
                  objectifsDuJour={objectifsDuJour}
                  setObjectifsDuJour={setObjectifsDuJour}
                  // ici on passe une clef manuellement pour pouvoir créer un nouvel objectif à chaque fois
                  clefObjet={
                    "objectif" + (Object.keys(objectifsDuJour).length + 1)
                  }
                  handleClose={() => handleClose("newObjectif")}
                  infoObjectif={query}
                  objectifs={objectifs}
                />
              ) : (
                <ObjectifDetails
                  semaine={props.semaine}
                  jour={props.jour}
                  semaineJourVoulu={semaineJourVoulu}
                  objectifsDuJour={objectifsDuJour}
                  setObjectifsDuJour={setObjectifsDuJour}
                  clefObjet={
                    "objectif" + (Object.keys(objectifsDuJour).length + 1)
                  }
                  handleClose={() => handleClose("newObjectif")}
                  objectifs={objectifs}
                />
              )}
            </div>
          </Modal>
        </Grid>
        {objectifsCards}
        <Grid item xs={12}>
          <Button
            style={{ backgroundColor: "#857A73" }}
            variant={"contained"}
            color={"primary"}
            onClick={saveObjectifs}
          >
            Enregistrer
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
