import React, { useState } from "react";
import "../../../css/Infos.css";
import { Button, Grid, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";

function Contact() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [email_text, setEmail_text] = useState("");
  const [email_textError, setEmail_textError] = useState("");

  // Permet de stocker les changements du champ email
  const onChangeEmail = (e) => {
    setEmailError("");
    setEmail(e.target.value);
  };

  // Permet de stocker les changement du champ emailText
  const onChangeEmailText = (e) => {
    setEmail_textError("");
    setEmail_text(e.target.value);
  };

  // Permet d'envoyer le mail au coach (depuis le back)
  const sendMail = async (e) => {
    let errors = validate();
    if (!errors) {
      let formData = new FormData();
      formData.set("email", email);
      formData.set("email_text", email_text);
      let requestURL = window.$apiURL.toString() + "contact";
      let requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: formData,
      };
      // eslint-disable-next-line no-unused-vars
      const responsePromise = await fetch(requestURL, requestOptions);
      window.location.replace("/contact");
    }
  };

  // Permet de vérifier que les champs sont correctement remplis
  function validate() {
    let errors = false;
    if (email.length === 0) {
      errors = true;
      setEmailError("Veuillez saisir un email !");
    }
    if (!new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)) {
      errors = true;
      setEmailError("Veuillez saisir un email valide!");
    }
    if (email_text.length === 0) {
      errors = true;
      setEmail_textError("Veuillez saisir un text");
    }
    return errors;
  }

  // Affichage de la page
  return (
    <div>
      <Container component="main" maxWidth="xs" className="divInfos">
        <CssBaseline />
        <Typography component="h4" variant="h4">
          Contact
        </Typography>
        <br />
        <br />
        <form id="contactForm" autoComplete="on">
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <PersonIcon />
            </Grid>
            <Grid item xs={9} style={{ textAlign: "left" }}>
              Olivier BALDACCHINO
            </Grid>
            <Grid item xs={3}>
              <PhoneIcon />
            </Grid>
            <Grid item xs={9} style={{ textAlign: "left" }}>
              <p style={{ userSelect: "none", margin: 0 }}>+41 79 531 40 90</p>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name={"email"}
                value={email}
                onChange={(e) => onChangeEmail(e)}
                variant={"outlined"}
                label={"Email"}
                required
                helperText={emailError !== "" ? emailError : ""}
                error={emailError !== ""}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                required
                rows={10}
                variant="outlined"
                label="Votre message"
                value={email_text}
                helperText={email_textError !== "" ? email_textError : ""}
                error={email_textError !== ""}
                onChange={(e) => onChangeEmailText(e)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                style={{ backgroundColor: "#857A73" }}
                fullWidth
                onClick={sendMail}
                variant="contained"
                color="primary"
              >
                {" "}
                Envoyer
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
}

export default Contact;
