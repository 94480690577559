import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Grid, TextField } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import DeleteIcon from "@material-ui/icons/Delete";
import "../../../../css/Request.css";

import DateFnsUtils from "@date-io/date-fns";
import { fr } from "date-fns/locale";

function PlanningCreation() {
  const [isReady, setIsReady] = useState(-1);
  const [exercices, setExercices] = useState([]);
  const [displayRows, setDisplayRows] = useState([]);
  const [display, setDisplay] = useState([]);
  const [hasLoadedPlanning, setHasLoadedPlanning] = useState(false);
  const [minDate, setMinDate] = useState("");

  let { id_planning } = useParams();

  useEffect(() => {
    getPlanningRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  const theme = createMuiTheme({
    overrides: {
      MuiMenu: {
        paper: {
          maxHeight: "300px",
        },
      },
    },
    props: {
      MuiSelect: {
        MenuProps: {
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
          },
          disableScrollLock: true,
        },
      },
    },
  });

  const classes = useStyles();

  // Récupère la demande de planning VIP d'un client ainsi que tout les exercices qui y sont associé
  const getPlanningRequest = async () => {
    let requestURL1 =
      window.$apiURL.toString() +
      "coachs/plannings/getDefaultPlanning/" +
      id_planning;
    let requestURL2 =
      window.$apiURL.toString() + "coachs/exercices/getExercicesList";
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const resposePromise1 = await fetch(requestURL1, requestOptions);
    const resposePromise2 = await fetch(requestURL2, requestOptions);
    const data1 = await resposePromise1.json();
    const data2 = await resposePromise2.json();
    setExercices(data2);
    let rows = [];
    for (let i = 0; i < data1.exercices.length; i++) {
      if (i === 0) {
        let date = new Date(data1.exercices[i].con_date).toISOString();
        setMinDate(date);
      }
      let rowData = {
        rowId: i,
        rowDate: data1.exercices[i].con_date,
        rowExeId: data1.exercices[i].exe_id,
        rowExeVitesse: data1.exercices[i].con_vitesse,
        rowExeProg: data1.exercices[i].con_progression,
      };
      rows.push(rowData);
    }
    setDisplayRows(rows);
    setIsReady(1);
  };

  // Permet de stocker le changement d'un exercice par un autre
  const onchangeExercice = (e, key) => {
    let rows = displayRows;
    rows[key].rowExeId = e.target.value;
    setDisplayRows(rows);
    updateRows();
  };

  // Permet de stocker le changement de la vitesse d'un exercice
  const onChangeVitesseExercice = (e, key) => {
    let rows = displayRows;
    rows[key].rowExeVitesse = e.target.value;
    setDisplayRows(rows);
    updateRows();
  };

  // Permet de stocker le changement de la date d'un exercice
  const removeSpeed = (e, key) => {
    let rows = displayRows;
    rows[key].rowExeVitesse = 0.0;
    setDisplayRows(rows);
    updateRows();
  };

  // Permet de stocker le changement de la date d'un exercice
  const onChangeDateExercice = (e, key) => {
    let rows = displayRows;
    rows[key].rowDate = new Date(e).toISOString().substring(0, 10);
    setDisplayRows(rows);
    updateRows();
  };

  // Permet de supprimer un exercice
  const deleteExercice = (e, key) => {
    let rows = displayRows;
    rows.splice(key, 1);
    setDisplayRows(rows);
    updateRows();
  };

  // Permet de rajouter un exercice
  const addExercice = () => {
    let object = {
      rowId: displayRows.length,
      rowDate: null,
      rowExeId: "",
      rowExeVitesse: 0.0,
      rowExeProg: 0.0,
    };
    let rows = displayRows;
    rows.push(object);
    setDisplayRows(rows);
    updateRows();
  };

  // Permet de réafficher les exercices après modification
  function updateRows() {
    setDisplay(
      Object.keys(displayRows).map((key) => (
        <ThemeProvider theme={theme} key={key}>
          <Grid
            container
            spacing={2}
            style={{
              backgroundColor: "rgb(133, 122, 115, 0.2)",
              marginBottom: "15px",
              borderRadius: "10px",
            }}
            key={key}
          >
            <Grid item xs={3}>
              <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
                  <KeyboardDatePicker
                    PopoverProps={{
                      disableScrollLock: true,
                    }}
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    format={"dd/MM/yyyy"}
                    minDate={minDate}
                    value={displayRows[key].rowDate}
                    helperText={"Date exercice"}
                    InputAdornmentProps={{ position: "start" }}
                    onChange={(e) => onChangeDateExercice(e, key)}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl className={classes.formControl}>
                {displayRows[key].rowExeVitesse < 0.01 ? (
                  <TextField
                    variant={"outlined"}
                    helperText={"Vitesse (km/h)"}
                    type={"number"}
                    inputProps={{
                      min: 0.0,
                      max: 50.0,
                      step: 0.01,
                      style: { textAlign: "center" },
                    }}
                    onBlur={(e) => onChangeVitesseExercice(e, key)}
                  />
                ) : (
                  <TextField
                    value={displayRows[key].rowExeVitesse}
                    variant={"outlined"}
                    helperText={"Vitesse (km/h)"}
                    type={"number"}
                    inputProps={{
                      min: 0.0,
                      max: 50.0,
                      step: 0.01,
                      style: { textAlign: "center" },
                    }}
                    onFocus={(e) => removeSpeed(e, key)}
                    onBlur={(e) => onChangeVitesseExercice(e, key)}
                  />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  variant={"outlined"}
                  helperText={"Nom de l'exercice"}
                  value={displayRows[key].rowExeId}
                  select
                  onChange={(e) => onchangeExercice(e, key)}
                >
                  {Object.keys(exercices).map((key) => (
                    <MenuItem key={key} value={exercices[key].id}>
                      {exercices[key].exe_nomexercice}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <Button
                style={{ height: "60px" }}
                onClick={(e) => deleteExercice(e, key)}
              >
                <DeleteIcon />
              </Button>
            </Grid>
          </Grid>
        </ThemeProvider>
      ))
    );
    setHasLoadedPlanning(true);
  }

  // Permet de sauvegarder les changements dans la base de donnée
  const savePlanning = async () => {
    let rows = displayRows;
    let dataRaw = [];
    let formData = new FormData();
    for (let i = 0; i < rows.length; i++) {
      let item = {
        exe_id: rows[i].rowExeId,
        exe_date: rows[i].rowDate,
        exe_vitesse: rows[i].rowExeVitesse,
        con_progression: rows[i].rowExeProg,
      };
      dataRaw.push(item);
    }
    formData.set("data", JSON.stringify(dataRaw));
    let requestURL =
      window.$apiURL.toString() +
      "coachs/plannings/changeVipPlanning/" +
      id_planning;
    let requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
      body: formData,
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    if (responsePromise.ok) {
      window.location.replace("/DetailClient/" + data["planning"].cli_id);
    }
  };

  // Affichage de la page
  if (isReady === -1) {
    return (
      <div style={{ paddingTop: "50%" }}>
        <CircularProgress style={{ color: "#857A73" }} />
      </div>
    );
  } else if (isReady === 1) {
    return (
      <div>
        <Container component={"main"} className="divRequest" maxWidth={"xl"}>
          {display}
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Button
                onClick={updateRows}
                disabled={hasLoadedPlanning}
                style={{ backgroundColor: "#857A73" }}
                variant={"contained"}
                color={"primary"}
                fullWidth
              >
                Charger planning
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                disabled={!hasLoadedPlanning}
                variant={"contained"}
                color={"primary"}
                onClick={addExercice}
                style={{ backgroundColor: "#857A73" }}
                fullWidth
              >
                Ajouter exercice
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant={"contained"}
                color={"primary"}
                onClick={() =>
                  (window.location.href = "/PlanningCreation/" + id_planning)
                }
                style={{ backgroundColor: "#f44336" }}
                fullWidth
              >
                Annuler
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                disabled={!hasLoadedPlanning}
                variant={"contained"}
                color={"primary"}
                onClick={savePlanning}
                style={{ backgroundColor: "#43a047" }}
                fullWidth
              >
                Sauvegarder
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default PlanningCreation;
