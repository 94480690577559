import React, { useState } from "react";
import { Grid, Button, Modal, makeStyles } from "@material-ui/core";
import ObjectifsJour from "./ObjectifsJour";

export default function AffichageObjectifs(props) {
  const nbJours = props.nbSemaines * 7;
  // On récupère le state depuis `NewDefi.js`, `objectifs` et sa fonction `setObjectifs` pour le modifier afin de les passer aux composants de chaque jour
  const { objectifs, setObjectifs } = props;

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      boxShadow: theme.shadows[5],
      outline: 0,
    },
  }));

  function Semaine(props) {
    // State pour gérer l'état d'ouverture des différentes fenêtres modales.
    const [open, setOpen] = useState({
      lundi: false,
      mardi: false,
      mercredi: false,
      jeudi: false,
      vendredi: false,
      samedi: false,
      dimanche: false,
    });

    const classes = useStyles();

    const handleOpen = (j) => {
      switch (j) {
        case 1:
          setOpen({ ...open, lundi: true });
          break;

        case 2:
          setOpen({ ...open, mardi: true });
          break;

        case 3:
          setOpen({ ...open, mercredi: true });
          break;

        case 4:
          setOpen({ ...open, jeudi: true });
          break;

        case 5:
          setOpen({ ...open, vendredi: true });
          break;

        case 6:
          setOpen({ ...open, samedi: true });
          break;

        case 7:
          setOpen({ ...open, dimanche: true });
          break;

        default:
          break;
      }
    };

    const handleClose = (j) => {
      switch (j) {
        case 1:
          setOpen({ ...open, lundi: false });
          break;

        case 2:
          setOpen({ ...open, mardi: false });
          break;

        case 3:
          setOpen({ ...open, mercredi: false });
          break;

        case 4:
          setOpen({ ...open, jeudi: false });
          break;

        case 5:
          setOpen({ ...open, vendredi: false });
          break;

        case 6:
          setOpen({ ...open, samedi: false });
          break;

        case 7:
          setOpen({ ...open, dimanche: false });
          break;

        default:
          break;
      }
    };

    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs>
            <Button
              style={{ backgroundColor: "#857A73" }}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => handleOpen(1)}
            >
              Semaine {props.id} Jour 1
            </Button>
            <Modal
              className={classes.modal}
              open={open.lundi}
              onClose={() => handleClose(1)}
            >
              <div className={classes.paper}>
                <ObjectifsJour
                  semaine={props.id}
                  jour={1}
                  objectifs={objectifs}
                  setObjectifs={setObjectifs}
                  handleCloseMain={() => handleClose(1)}
                />
              </div>
            </Modal>
          </Grid>
          <Grid item xs>
            <Button
              style={{ backgroundColor: "#857A73" }}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => handleOpen(2)}
            >
              Semaine {props.id} Jour 2
            </Button>
            <Modal
              className={classes.modal}
              open={open.mardi}
              onClose={() => handleClose(2)}
            >
              <div className={classes.paper}>
                <ObjectifsJour
                  semaine={props.id}
                  jour={2}
                  objectifs={objectifs}
                  setObjectifs={setObjectifs}
                  handleCloseMain={() => handleClose(2)}
                />
              </div>
            </Modal>
          </Grid>
          <Grid item xs>
            <Button
              style={{ backgroundColor: "#857A73" }}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => handleOpen(3)}
            >
              Semaine {props.id} Jour 3
            </Button>

            <Modal
              className={classes.modal}
              open={open.mercredi}
              onClose={() => handleClose(3)}
            >
              <div className={classes.paper}>
                <ObjectifsJour
                  semaine={props.id}
                  jour={3}
                  objectifs={objectifs}
                  setObjectifs={setObjectifs}
                  handleCloseMain={() => handleClose(3)}
                />
              </div>
            </Modal>
          </Grid>
          <Grid item xs>
            <Button
              style={{ backgroundColor: "#857A73" }}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => handleOpen(4)}
            >
              Semaine {props.id} Jour 4
            </Button>

            <Modal
              className={classes.modal}
              open={open.jeudi}
              onClose={() => handleClose(4)}
            >
              <div className={classes.paper}>
                <ObjectifsJour
                  semaine={props.id}
                  jour={4}
                  objectifs={objectifs}
                  setObjectifs={setObjectifs}
                  handleCloseMain={() => handleClose(4)}
                />
              </div>
            </Modal>
          </Grid>
          <Grid item xs>
            <Button
              style={{ backgroundColor: "#857A73" }}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => handleOpen(5)}
            >
              Semaine {props.id} Jour 5
            </Button>

            <Modal
              className={classes.modal}
              open={open.vendredi}
              onClose={() => handleClose(5)}
            >
              <div className={classes.paper}>
                <ObjectifsJour
                  semaine={props.id}
                  jour={5}
                  objectifs={objectifs}
                  setObjectifs={setObjectifs}
                  handleCloseMain={() => handleClose(5)}
                />
              </div>
            </Modal>
          </Grid>
          <Grid item xs>
            <Button
              style={{ backgroundColor: "#857A73" }}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => handleOpen(6)}
            >
              Semaine {props.id} Jour 6
            </Button>

            <Modal
              className={classes.modal}
              open={open.samedi}
              onClose={() => handleClose(6)}
            >
              <div className={classes.paper}>
                <ObjectifsJour
                  semaine={props.id}
                  jour={6}
                  objectifs={objectifs}
                  setObjectifs={setObjectifs}
                  handleCloseMain={() => handleClose(6)}
                />
              </div>
            </Modal>
          </Grid>
          <Grid item xs>
            <Button
              style={{ backgroundColor: "#857A73" }}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => handleOpen(7)}
            >
              Semaine {props.id} Jour 7
            </Button>

            <Modal
              className={classes.modal}
              open={open.dimanche}
              onClose={() => handleClose(7)}
            >
              <div className={classes.paper}>
                <ObjectifsJour
                  semaine={props.id}
                  jour={7}
                  objectifs={objectifs}
                  setObjectifs={setObjectifs}
                  handleCloseMain={() => handleClose(7)}
                />
              </div>
            </Modal>
          </Grid>
        </Grid>
        <br />
      </div>
    );
  }

  return [...Array(nbJours / 7)].map((value, index) => (
    <Semaine id={index + 1} key={index + 1} />
  ));
}
