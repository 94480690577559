import React from "react";
import { Link } from "react-router-dom";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { Grid, Button } from "@material-ui/core";

function MailVerifiedPage() {
  return (
    <div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Typography
          variant="h4"
          style={{
            paddingTop: "15vh",
            paddingBottom: "10vh",
            color: "white",
            fontWeight: "bold",
          }}
        >
          Mail vérifié !
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Link to="/Login" style={{ textDecoration: "none" }}>
              <Button
                style={{ backgroundColor: "#857A73" }}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                {" "}
                Cliquez ici pour vous connecter
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default MailVerifiedPage;
