import React from "react";
import { Link } from "react-router-dom";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { Button, Grid } from "@material-ui/core";
import "../../../css/Register.css";

function MailNotificationPage() {
  // Récupère le mail de l'utilisateur
  function getEmail() {
    let userEmail = localStorage.getItem("user_mail");
    localStorage.removeItem("user_mail");
    return userEmail;
  }

  return (
    <div style={{ paddingTop: "15vh" }}>
      <Container component="main" maxWidth="xs" className="divRegister">
        <CssBaseline />
        <Typography
          variant="h4"
          style={{ paddingBottom: "10vh", fontWeight: "bold" }}
        >
          Un email vient de vous être envoyé à l'adresse : <b>{getEmail()}</b>
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Button
                style={{ backgroundColor: "#857A73" }}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                {" "}
                Retour à l'accueil
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default MailNotificationPage;
