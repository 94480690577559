import React, { useEffect } from "react";
import "../../../css/Home.css";
import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

function Home() {
  // Permet de détérminer la redirection du bouton central selon si l'utilisateur est connecté ou non
  function checkIfLogged() {
    if (localStorage.getItem("cbs_perform_token") != null) {
      window.location.replace("/Plannings");
    } else {
      window.location.replace("/Login");
    }
  }

  useEffect(() => {
    // Textes à écrire
    var dataText = [
      "Préparation aux marathons ?",
      "Remise en forme ?",
      "Adepte de la course à pied ?",
      "Recevez votre plan d'entraînement !",
    ];

    //Ecrit le texte
    function typeWriter(text, i, fnCallback) {
      // Vérifie s'il a terminé le texte
      if (i < text.length) {
        // Ajoute le prochain caractère de l'id
        document.querySelector("#typeEffect").innerHTML =
          text.substring(0, i + 1) +
          '<span id="cursor" aria-hidden="true"></span>';

        // Attendre avant d'écrire le prochain caractère
        setTimeout(function () {
          typeWriter(text, i + 1, fnCallback);
        }, 60);
      } else if (typeof fnCallback == "function") {
        setTimeout(fnCallback, 800);
      }
    }
    //Lance l'animation pour un texte
    function StartTextAnimation(i) {
      //Si on arrive au bout on recommence une animation
      if (dataText[i] == null) {
        setTimeout(function () {
          StartTextAnimation(0);
        }, 2000);
      } else {
        //
        typeWriter(dataText[i], 0, function () {
          // Quand tout le texte a été animé, on commence le prochain texte
          StartTextAnimation(i + 1);
        });
      }
    }
    //Lance l'animation
    StartTextAnimation(0);

    return function cleanup() {
      var highestTimeoutId = setTimeout(";");
      for (var i = 0; i < highestTimeoutId; i++) {
        clearTimeout(i);
      }
    };
  });

  // Affichage de la page
  return (
    <div className="divHome">
      <Typography
        className="titreHome"
        variant="h3"
        component="h3"
        style={{ paddingBottom: "10vh", paddingTop: "15vh" }}
      >
        CBS-Perform
      </Typography>
      <h3 style={{ color: "white", paddingBottom: "5%" }} id="typeEffect"></h3>
      <br />
      <br />
      <Button
        style={{ backgroundColor: "#857A73" }}
        type="submit"
        variant="contained"
        color="primary"
        onClick={checkIfLogged}
      >
        {" "}
        Cliquer ici pour commencer !
      </Button>
    </div>
  );
}

export default Home;
