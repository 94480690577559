import React from "react";
import "../../../css/Footer.css";
import Typography from "@material-ui/core/Typography";

function Footer() {
  return (
    <footer>
      <Typography
        component="h5"
        variant="body1"
        style={{ paddingTop: "1%", color: "white", opacity: 1 }}
      >
        CBS Perform - Tous droits réservés ©
      </Typography>
    </footer>
  );
}

export default Footer;
