import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress, Grid, Button } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { TextField } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { Link } from "react-router-dom";

function ObjectifDetail() {
  const [isReady, setIsReady] = useState(-1);
  // Bien écrire les noms des champs comme dans la BDD (sensible à la CASSE)
  const [objectif, setObjectif] = useState({
    OBJ_ID: null,
    OBJ_NOM: "",
    OBJ_DESCRIPTION: "",
    OBJ_CONSEILS: "",
  });

  const onChangeTitle = (e) => {
    // ...objectif permet de reprendre les autres champs de l'objet Défi.
    setObjectif({ ...objectif, OBJ_NOM: e.target.value });
  };

  const onChangeDescription = (e) => {
    setObjectif({ ...objectif, OBJ_DESCRIPTION: e.target.value });
  };

  const onChangeConseils = (e) => {
    setObjectif({ ...objectif, OBJ_CONSEILS: e.target.value });
  };

  let { objID } = useParams();

  useEffect(() => {
    getObjectifDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getObjectifDetail = async () => {
    let requestURL = window.$apiURL.toString() + "objectifs/" + objID;
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    setObjectif(data);
    if (responsePromise.status === 404) {
      window.location.replace("/404");
    } else {
      if (localStorage.getItem("cbs_perform_token_is_super_admin") != null) {
        setIsReady(0);
      } else {
        setIsReady(1);
      }
    }
  };

  const updateObjectif = async () => {
    let formData = new FormData();
    for (let key in objectif) {
      formData.set(key, objectif[key]);
    }
    let requestURL =
      window.$apiURL.toString() + "objectifs/coachs/updateObjectif/" + objID;
    let requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
      body: formData,
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    // eslint-disable-next-line no-unused-vars
    const data = await responsePromise.json();
    window.location.replace("/objectifs/" + objID);
  };

  const deleteObjectif = async () => {
    let requestURL = window.$apiURL.toString() + "objectifs/coachs/" + objID;
    let requestOptions = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    // eslint-disable-next-line no-unused-vars
    const data = await responsePromise.json();
    window.location.replace("/gestionObjectifs");
  };

  if (isReady === -1) {
    return (
      <div style={{ paddingTop: "50%" }}>
        <CircularProgress style={{ color: "#857A73" }} />
      </div>
    );
  } else if (isReady === 0) {
    return (
      <div>
        <Container component="main" maxWidth="xs" className="divClients">
          <Link
            to={"/gestionObjectifs"}
            color="inherit"
            style={{
              textDecoration: "none",
              color: "#857A73",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <ArrowBackIcon />
          </Link>
          <Typography
            component="h3"
            variant="h4"
            style={{ paddingBottom: "10%", paddingTop: "10%" }}
          >
            Mise à jour de l'objectif <br /> {objectif.OBJ_NOM}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant={"outlined"}
                fullWidth
                label={"Nom"}
                onChange={(e) => onChangeTitle(e)}
                value={objectif.OBJ_NOM}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant={"outlined"}
                fullWidth
                label={"Description"}
                onChange={(e) => onChangeDescription(e)}
                multiline
                rows={5}
                value={objectif.OBJ_DESCRIPTION}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant={"outlined"}
                fullWidth
                label={"Conseils (optionnels)"}
                onChange={(e) => onChangeConseils(e)}
                multiline
                rows={5}
                value={objectif.OBJ_CONSEILS}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={updateObjectif}
                style={{ backgroundColor: "#857A73" }}
                variant={"contained"}
                color={"primary"}
              >
                Mettre à jour
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={deleteObjectif}
                style={{ backgroundColor: "#857A73" }}
                variant={"contained"}
                color={"primary"}
              >
                Supprimer
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  } else {
    return (
      <div style={{ paddingTop: "50%" }}>
        <CircularProgress style={{ color: "#857A73" }} />
      </div>
    );
  }
}
export default ObjectifDetail;
