import React, {useEffect, useState} from "react";
import '../../../css/Plannings.css';
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import {Button, Grid, MenuItem, TextField, InputLabel, Select,} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {fr} from "date-fns/locale";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {addDays} from "material-ui/DatePicker/dateUtils";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import moment from 'moment'
import 'moment/locale/fr-ch'
import FormControl from '@material-ui/core/FormControl';
moment.locale('fr-ch')


function PlanningsGratuit(){


    const [user, setUser] = useState({})

    useEffect(() => {
        fetchUser().then(setUser);
        setFormType(
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        disabled
                    />
                </Grid>
            </Grid>
        )
    },[]);


    function getToday() {
        let date = new Date();
        let newDate = new Date(date.setDate(date.getDate()+1))
        addDays(date, 2);
        return newDate.toISOString();
    }

    const [hasPlanning, setHasPlanning] = useState(-1);
    const [plannings, setPlannings] = useState({});

    const [isVIP, setIsVIP] = useState("");


    // Permet de récupérer les informations de l'utilisateurs connectés
    async function fetchUser(){
        let requestURL = window.$apiURL.toString()+'users'
        let requestOptions = {
            method : 'GET',
            headers : {
                'Accept' : 'application/json',
                'Authorization' : 'Bearer '+localStorage.getItem('cbs_perform_token'),
            },
        }
        return fetch(requestURL, requestOptions)
            .then(res => res.json())
            .then(res =>{
                if (res.cli_abonnement === 2 || res.cli_abonnement === 3) {
                    setIsVIP(1);
                }
                if (res.hasPlanning){
                    setHasPlanning(1);
                    getUserPlannings();
                }else{
                    setHasPlanning(0);
                }
                return res;
            })
    }

    // Retourne les différents plans d'entraînement du client
    const getUserPlannings = async () =>{
        let requestURL = window.$apiURL.toString()+'users/plannings'
        let requestOptions = {
            method : 'GET',
            headers : {
                'Accept' : 'application/json',
                'Authorization' : 'Bearer '+localStorage.getItem('cbs_perform_token'),
            },
        }
        const responsePromise = await fetch(requestURL, requestOptions);
        const data = await responsePromise.json();
        setPlannings(data);
    }



    const [pla_nom, setPla_nom] = useState('');
    const [pla_nomError, setPla_nomError] = useState(false);
    const [pla_nomErrorText, setPla_nomErrorText] = useState('');

    // Permet de stocker le nom du plan entré par l'utilisateur
    const onChangePla_nom = (e) => {
        setPla_nom(e.target.value);
        setPla_nomError(false);
    }

    const [pla_type, setPla_type] = useState('');
    const [pla_typeError, setPla_typeError] = useState(false);
    const [pla_typeErrorText, setPla_typeErrorText] = useState('');
    const [pla_rem_niveau, setPla_rem_niveau] = useState('');
    const [pla_rem_niveauError, setPla_rem_niveauError] = useState(false);
    const [pla_rem_niveauErrorText, setPla_rem_niveauErrorText] = useState('');
    const [pla_duree, setPla_duree] = useState('')
    const [pla_dureeError, setPla_dureeError] = useState(false)
    const [pla_dureeErrorText, setPla_dureeErrorText] = useState('')
    const [pla_repetitions, setPla_repetitions] = useState('');
    const [pla_repetitionsError, setPla_repetitionsError] = useState(false);
    const [pla_repetitionsErrorText, setPla_repetitionsErrorText] = useState('');
    const [pla_objectif_temps, setPla_objectif_temps] = useState('')
    const [pla_objectif_tempsError, setPla_objectif_tempsError] = useState(false)
    const [pla_objectif_tempsErrorText, setPla_objectif_tempsErrorText] = useState('')
    const [pla_datedebut, setPla_datedebut] = useState('')
    const [pla_datedebutError, setPla_datedebutError] = useState(false)
    const [pla_datedebutErrorText, setPla_datedebutErrorText] = useState('')
    const [checkBoxError, setCheckBoxError] = useState(false);
    const [checkBoxErrorText, setCheckBoxErrorText] = useState('');


    const [formType, setFormType] = useState(null);

    //Permet de stocker les changements du champs pla_rem_niveau
    const onChangeRem_niveau = (e) =>{
        setPla_rem_niveau(e.target.value);
        setPla_rem_niveauError(false);
    }
    // Récupère la durée du plan entré par l'utilisateur
    const onChangePla_duree = (e) => {
        setPla_duree(e.target.value)
        setPla_dureeError(false);
    }
    // Récupère le nombre de répétitions entré par l'utilisateur
    const onChangePla_repetitions = (e) =>{
        setPla_repetitions(e.target.value);
        setPla_repetitionsError(false);
    }
    // Récupère l'objectif de temps entré par l'utilisateur
    const onChangePla_objetif_temps = (e) =>{
        setPla_objectif_temps(e.target.value);
        setPla_objectif_tempsError(false)
    }
    // Récupère la date de début du plan entré par l'utilisateur
    const onChangePla_datedebut = (e) => {
        setPla_datedebutError(false)
        setPla_datedebut(e)
    }
    // Vérifie que l'utilisateur a bien coché le bon nombre de jours
    const onCheck = (e) =>{
        setCheckBoxError(false)
        setCheckBoxErrorText('')
    }


    // Permet de charger les bons composants en fonction du choix de l'entraînement choisi
    const onChangePla_type = (e) => {
        setPla_dureeError(false);
        setPla_typeError(false);
        setPla_type(e.target.value);
        setPla_repetitions('');
        setPla_repetitionsError(false);
        setPla_duree('')
        setPla_dureeError(false);
        setFormType(null);
        if (e.target.value === 'r'){
            setFormType(
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" error={pla_rem_niveauError}>
                            <InputLabel htmlFor="rem_niveau">Votre niveau</InputLabel>
                            <Select
                                native
                                label="Votre niveau"
                                required
                                name="rem_niveau"
                                onChange={(e) => onChangeRem_niveau(e)}
                                inputProps={{
                                    id: 'rem_niveau',
                                }}
                            >
                            <option aria-label="None" value="" />
                            <option value={'0'}>Débutant</option>
                            <option value={'1'}>Avancé</option>
                            </Select>
                            <FormHelperText>{pla_rem_niveauError ? pla_rem_niveauErrorText : ''}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" error={pla_dureeError}>
                            <InputLabel htmlFor="rem_pla_duree">Durée de l'entraînement</InputLabel>
                            <Select
                                native
                                label="Durée de l'entraînement"
                                required
                                name="pla_duree"
                                onChange={e => onChangePla_duree(e)}
                                inputProps={{
                                    id: 'rem_pla_duree',
                                }}
                            >
                            <option aria-label="None" value="" />
                            <option value={'6'}>6 semaines</option>
                            <option value={'7'}>7 semaines</option>
                            <option value={'8'}>8 semaines</option>
                            <option value={'9'}>9 semaines</option>
                            <option value={'10'}>10 semaines</option>
                            <option value={'11'}>11 semaines</option>
                            <option value={'12'}>12 semaines</option>
                            </Select>
                            <FormHelperText>{pla_dureeError ? pla_dureeErrorText : ''}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" error={pla_repetitionsError}>
                            <InputLabel htmlFor="rem_pla_repetitions">Entraînements par semaine</InputLabel>
                            <Select
                                native
                                label="Entraînements par semaine"
                                required
                                name="pla_repetitions"
                                onChange={e => onChangePla_repetitions(e)}
                                inputProps={{
                                    id: 'rem_pla_repetitions',
                                }}
                            >
                            <option aria-label="None" value="" />
                            <option value={'2'}>2 fois par semaine</option>
                            <option value={'3'}>3 fois par semaine</option>
                            </Select>
                            <FormHelperText>{pla_repetitionsError ? pla_repetitionsErrorText : ''}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
            )
        }
        else if(e.target.value === 'm'){
            setFormType(
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" error={pla_dureeError}>
                            <InputLabel htmlFor="mara_duree">Durée de l'entraînement</InputLabel>
                            <Select
                                native
                                label="Durée de l'entraînement"
                                required
                                name="pla_duree"
                                onChange={e => onChangePla_duree(e)}
                                inputProps={{
                                    id: 'mara_duree',
                                }}
                            >
                            <option aria-label="None" value="" />
                            <option value={'12'}>12 semaines</option>
                            <option value={'13'}>13 semaines</option>
                            <option value={'14'}>14 semaines</option>
                            <option value={'15'}>15 semaines</option>
                            <option value={'16'}>16 semaines</option>
                            <option value={'17'}>17 semaines</option>
                            <option value={'18'}>18 semaines</option>
                            <option value={'19'}>19 semaines</option>
                            <option value={'20'}>20 semaines</option>
                            </Select>
                            <FormHelperText>{pla_dureeError ? pla_dureeErrorText : ''}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" error={pla_repetitionsError}>
                            <InputLabel htmlFor="mara_repetitions">Entraînements par semaine</InputLabel>
                            <Select
                                native
                                label="Entraînements par semaine"
                                required
                                name="pla_repetitions"
                                onChange={e => onChangePla_repetitions(e)}
                                inputProps={{
                                    id: 'mara_repetitions',
                                }}
                            >
                            <option aria-label="None" value="" />
                            <option value={'3'}>3 fois par semaine</option>
                            <option value={'4'}>4 fois par semaine</option>
                            <option value={'5'}>5 fois par semaine</option>
                            </Select>
                            <FormHelperText>{pla_repetitionsError ? pla_repetitionsErrorText : ''}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" error={pla_objectif_tempsError}>
                            <InputLabel htmlFor="mara_objectif">Objectif Temps</InputLabel>
                            <Select
                                native
                                label="Objectif Temps"
                                required
                                name="pla_objectif_temps"
                                onChange={e => onChangePla_objetif_temps(e)}
                                inputProps={{
                                    id: 'mara_objectif',
                                }}
                            >
                            <option aria-label="None" value="" />
                            <option value={'3'}>Inférieur à 3h</option>
                            <option value={'35'}>Inférieur à 3h30</option>
                            <option value={'4'}>Inférieur à 4h</option>
                            <option value={'45'}>Inférieur à 4h30</option>
                            <option value={'5'}>Inférieur à 5h</option>
                            </Select>
                            <FormHelperText>{pla_objectif_tempsError ? pla_objectif_tempsErrorText : ''}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
            )
        }
        else if(e.target.value === 'sm'){
            setFormType(
                <Grid container spacing={2}>
                    
                    <Grid item xs={12}>
                        <FormControl variant="outlined" error={pla_dureeError}>
                            <InputLabel htmlFor="semi_mara_duree">Durée de l'entraînement</InputLabel>
                            <Select
                                native
                                label="Durée de l'entraînement"
                                required
                                name="pla_duree"
                                onChange={e => onChangePla_duree(e)}
                                inputProps={{
                                    id: 'semi_mara_duree',
                                }}
                            >
                            <option aria-label="None" value="" />
                            <option value={'8'}>8 semaines</option>
                            <option value={'9'}>9 semaines</option>
                            <option value={'10'}>10 semaines</option>
                            <option value={'11'}>11 semaines</option>
                            <option value={'12'}>12 semaines</option>
                            <option value={'13'}>13 semaines</option>
                            <option value={'14'}>14 semaines</option>
                            <option value={'15'}>15 semaines</option>
                            <option value={'16'}>16 semaines</option>
                            </Select>
                            <FormHelperText>{pla_dureeError ? pla_dureeErrorText : ''}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" error={pla_repetitionsError}>
                            <InputLabel htmlFor="semi_mara_repetitions">Durée de l'entraînement</InputLabel>
                            <Select
                                native
                                label="Durée de l'entraînement"
                                required
                                name="pla_repetitions"
                                onChange={e => onChangePla_repetitions(e)}
                                inputProps={{
                                    id: 'semi_mara_repetitions',
                                }}
                            >
                            <option aria-label="None" value="" />
                            <option value={'3'}>3 fois par semaine</option>
                            <option value={'4'}>4 fois par semaine</option>
                            <option value={'5'}>5 fois par semaine</option>
                            </Select>
                            <FormHelperText>{pla_repetitionsError ? pla_repetitionsErrorText : ''}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" error={pla_objectif_tempsError}>
                            <InputLabel htmlFor="semi_mara_objectif">Objectif Temps</InputLabel>
                            <Select
                                native
                                label="Objectif Temps"
                                required
                                name="pla_objectif_temps"
                                onChange={e => onChangePla_objetif_temps(e)}
                                inputProps={{
                                    id: 'semi_mara_objectif',
                                }}
                            >
                            <option aria-label="None" value="" />
                            <option value={'15'}>Inférieur à 1h30</option>
                            <option value={'175'}>Inférieur à 1h45</option>
                            <option value={'2'}>Inférieur à 2h</option>
                            <option value={'225'}>Inférieur à 2h15</option>
                            <option value={'25'}>Inférieur à 2h30</option>
                            </Select>
                            <FormHelperText>{pla_objectif_tempsError ? pla_objectif_tempsErrorText : ''}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
            )
        }
        else if(e.target.value === 'c'){
            setFormType(
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" error={pla_dureeError}>
                            <InputLabel htmlFor="course_duree">Durée de l'entraînement</InputLabel>
                            <Select
                                native
                                label="Durée de l'entraînement"
                                required
                                name="pla_duree"
                                onChange={e => onChangePla_duree(e)}
                                inputProps={{
                                    id: 'course_duree',
                                }}
                            >
                            <option aria-label="None" value="" />
                            <option value={'8'}>8 semaines</option>
                            <option value={'9'}>9 semaines</option>
                            <option value={'10'}>10 semaines</option>
                            <option value={'11'}>11 semaines</option>
                            <option value={'12'}>12 semaines</option>
                            <option value={'13'}>13 semaines</option>
                            <option value={'14'}>14 semaines</option>
                            <option value={'15'}>15 semaines</option>
                            <option value={'16'}>16 semaines</option>
                            </Select>
                            <FormHelperText>{pla_dureeError ? pla_dureeErrorText : ''}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" error={pla_repetitionsError}>
                            <InputLabel htmlFor="course_repetitions">Entraînements par semaine</InputLabel>
                            <Select
                                native
                                label="Entraînements par semaine"
                                required
                                name="pla_repetitions"
                                onChange={e => onChangePla_objetif_temps(e)}
                                inputProps={{
                                    id: 'course_repetitions',
                                }}
                            >
                            <option aria-label="None" value="" />
                            <option value={'2'}>2 fois par semaine</option>
                            <option value={'3'}>3 fois par semaine</option>
                            <option value={'4'}>4 fois par semaine</option>
                            <option value={'5'}>5 fois par semaine</option>
                            </Select>
                            <FormHelperText>{pla_repetitionsError ? pla_repetitionsErrorText : ''}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" error={pla_objectif_tempsError}>
                            <InputLabel htmlFor="course_objectif">Objectif Temps</InputLabel>
                            <Select
                                native
                                label="Objectif Temps"
                                required
                                name="pla_objectif_temps"
                                onChange={e => onChangePla_objetif_temps(e)}
                                inputProps={{
                                    id: 'course_objectif',
                                }}
                            >
                            <option aria-label="None" value="" />
                            <option value={'40'}>Inférieur à 40 minutes</option>
                            <option value={'45'}>Inférieur à 45 minutes</option>
                            <option value={'50'}>Inférieur à 50 minutes</option>
                            <option value={'55'}>Inférieur à 55 minutes</option>
                            <option value={'60'}>Inférieur à 60 minutes</option>
                            </Select>
                            <FormHelperText>{pla_objectif_tempsError ? pla_objectif_tempsErrorText : ''}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
            )
        }
    }

    // Vérification des données entrées par l'utilisateur
    function validate() {
        let errors = false;
        let formData = document.getElementById('newPlanningFreeFrom');
        let form = new FormData(formData);
        let checkboxCount = 0;
        form.forEach(function(value, key){
            if (key === 'daysOfExercice[]'){
                checkboxCount++;
            }
        });
        if (pla_nom.length === 0){
            errors = true;
            setPla_nomError(true)
            setPla_nomErrorText('Vous devez saisir un nom pour votre plan')
        }
        if (pla_type === 'r'){
            if(pla_rem_niveau.length === 0){
                errors = true;
                setPla_rem_niveauError(true);
                setPla_rem_niveauErrorText('Veuillez indiquer votre niveau actuel')
            }
        }else if(pla_type === 'm' || pla_type === 'sm' || pla_type === 'c'){
            if(pla_objectif_temps.length === 0){
                errors = true;
                setPla_objectif_tempsError(true);
                setPla_objectif_tempsErrorText('Veuillez indiquer votre objectif de temps')
            }
        }
        if (pla_type.length === 0){
            errors = true;
            setPla_typeError(true);
            setPla_typeErrorText('Veuillez choisir un type de planning')
        }
        if(pla_duree.length === 0){
            errors = true;
            setPla_dureeError(true);
            setPla_dureeErrorText('Veuillez indiquer la durée du plan souhaitée')
        }
        if (pla_repetitions.length === 0){
            errors = true;
            setPla_repetitionsError(true);
            setPla_repetitionsErrorText('Veuillez indiquer le nombre de répéritions par semaine souhaité')
        }
        if (new Date(pla_datedebut) < new Date()){
            errors = true;
            setPla_datedebutError(true);
            setPla_datedebutErrorText('La date doit se situer dans le futur');
        }
        if (checkboxCount.toString() !== pla_repetitions.toString()){
            errors = true;
            setCheckBoxError(true);
            setCheckBoxErrorText('Veuillez cocher le bon nombre de jours('+pla_repetitions+")");
        }
        if (pla_type.length === 0){
            errors = true;
            setPla_typeError(true);
            setPla_typeErrorText('Veuillez choisir un type de plan')
        }
        return errors;
    }

    // Enregistrement du plan dans la base de données
    const submitPlanningRequest = async (e) =>{
        e.preventDefault();
        const errors = validate();
        if (!errors) {
            let pla_idgratuit;
            if (pla_type === 'r'){
                pla_idgratuit = pla_rem_niveau+pla_type+pla_repetitions+pla_duree;
            }else{
                pla_idgratuit = pla_type+pla_repetitions+pla_duree;
            }
            let newPlanningFreeFromData = document.getElementById('newPlanningFreeFrom');
            let type_course = '';
            switch (pla_type) {
                case 'r' : type_course = '(Remise en forme)'; break;
                case 'm' : type_course = '(Marathon)'; break;
                case 'sm' : type_course = '(Semi-marathon)'; break;
                case 'c' : type_course = '(Course 10km)'; break;
                default : break;
            }
            let formData = new FormData(newPlanningFreeFromData);
            formData.set('pla_nom', formData.get('pla_nom')+" "+type_course);
            formData.set('pla_idgratuit', pla_idgratuit);
            if (pla_datedebut.length === 0){
                formData.set('pla_datedebut', new Date(getToday()).toISOString().substring(0,10))
            }else{
                formData.set('pla_datedebut', new Date(pla_datedebut).toISOString().substring(0,10))
            }
            formData.set('isPlaVip', 0);
            let daysAfter = [], daysBefore = [];
            let cpt1 = 0, cpt2 = 0;
            formData.forEach(function(value, key){
                if (key === 'daysOfExercice[]'){
                    if (value < new Date(pla_datedebut).getDay()){
                        daysBefore[cpt1] = value; cpt1++;
                    }else{
                        daysAfter[cpt2] = value; cpt2++;
                    }
                }
            });
            daysBefore.sort();
            let concat = daysAfter.concat(daysBefore)
            formData.delete('daysOfExercice[]');
            for (let i = 0; i<concat.length; i++){
                formData.append('daysOfExercice[]', concat[i]);
            }
            let requestURL = window.$apiURL.toString()+"users/plannings";
            let requestOptions = {
                method : 'POST',
                headers : {
                    'Accept' : 'application/json',
                    'Authorization' : 'Bearer '+localStorage.getItem('cbs_perform_token'),
                },
                body : formData
            }
            const responsePromise = await fetch(requestURL, requestOptions);
            const data = await responsePromise.json();
            if (responsePromise.ok){
                window.location.replace("/Plannings");
            }
        }
    }


    return(
        <div>
            <Container component="main" maxWidth="xs" className="divPlannings">
                <CssBaseline/>
                <Typography component="h1" variant="h3" style={{paddingBottom : '20%'}}>
                    Nouveau planning d'entraînement
                </Typography>
                <form id="newPlanningFreeFrom">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="off"
                                variant="outlined"
                                name="pla_nom"
                                label='Nom du planning'
                                helperText={pla_nomError ? pla_nomErrorText : ''}
                                error={pla_nomError}
                                onChange={e => onChangePla_nom(e)}
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" error={pla_typeError}>
                                <InputLabel htmlFor="rem_niveau">Type de planning</InputLabel>
                                <Select
                                    value={pla_type}
                                    native
                                    label="Type de Planning"
                                    required
                                    name="pla_type"
                                    onChange={e => onChangePla_type(e)}
                                    inputProps={{
                                        id: 'rem_niveau',
                                    }}
                                >
                                <option aria-label="None" value="" />
                                <option value={'c'}>Course 10 Km</option>
                                <option value={'m'}>Marathon</option>
                                <option value={'sm'}>Semi-Marathon</option>
                                <option value={'r'}>Remise en forme</option>
                                </Select>
                                <FormHelperText>{pla_typeError ? pla_typeErrorText : ''}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <br/>
                    <br/>
                    <br/>
                    {formType}
                    <br/>
                    <br/>
                    <br/>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
                                <KeyboardDatePicker
                                    PopoverProps={{
                                        disableScrollLock: true
                                    }}
                                    autoOk
                                    variant="inline"
                                    inputVariant="outlined"
                                    format={'dd/MM/yyyy'}
                                    error={pla_datedebutError}
                                    helperText={pla_datedebutError ? pla_datedebutErrorText : 'Date de début du planning'}
                                    value={pla_datedebut === '' ? getToday() : pla_datedebut}
                                    minDate={getToday()}
                                    InputAdornmentProps={{ position: 'start'}}
                                    onChange={e => onChangePla_datedebut(e)}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                value="1"
                                name="daysOfExercice[]"
                                onChange={e => onCheck(e)}
                                control={<Checkbox style={{color : '#857A73'}}/>}
                                label="Lundi"
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                onChange={e => onCheck(e)}
                                value="2"
                                name="daysOfExercice[]"
                                control={<Checkbox style={{color : '#857A73'}}/>}
                                label="Mardi"
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                onChange={e => onCheck(e)}
                                value="3"
                                name="daysOfExercice[]"
                                control={<Checkbox style={{color : '#857A73'}}/>}
                                label="Mercredi"
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                onChange={e => onCheck(e)}
                                value="4"
                                name="daysOfExercice[]"
                                control={<Checkbox style={{color : '#857A73'}}/>}
                                label="Jeudi"
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                onChange={e => onCheck(e)}
                                value="5"
                                name="daysOfExercice[]"
                                control={<Checkbox style={{color : '#857A73'}}/>}
                                label="Vendredi"
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                onChange={e => onCheck(e)}
                                value="6"
                                name="daysOfExercice[]"
                                control={<Checkbox style={{color : '#857A73'}}/>}
                                label="Samedi"
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                onChange={e => onCheck(e)}
                                value="0"
                                name="daysOfExercice[]"
                                control={<Checkbox style={{color : '#857A73'}}/>}
                                label="Dimanche"
                                labelPlacement="top"
                            />
                            <FormHelperText
                                error={checkBoxError}
                            >
                                {checkBoxErrorText}
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                style={{backgroundColor : '#857A73'}}
                                onClick={e => submitPlanningRequest(e)}
                                variant="contained"
                                color="primary"
                                fullWidth
                            >
                                Créer votre planning
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            {isVIP === 1 ?
                                <Button
                                    style={{backgroundColor : '#D2BF67'}}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => window.location.href="/PlanningsVIP"}
                                    fullWidth
                                >  Obtenir un plan VIP
                                </Button>
                                :
                                <Button
                                    style={{backgroundColor : '#D2BF67'}}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => window.location.href="/ChangeSubscription"}
                                    fullWidth
                                >  Cliquer ici pour obtenir plus de plans
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </div>
    )

}
export default PlanningsGratuit;
