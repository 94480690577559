/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import "../../../css/Clients.css";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

function HistDiscSub() {
  const [isReady, setIsReady] = useState(-1);
  const [exchanges, setExchanges] = useState({});
  const [requestType, setRequestType] = useState("");
  const [userId, setUserId] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userName, setUserName] = useState("");

  let { id_request } = useParams();

  useEffect(() => {
    getExchanges();
  }, []);

  // Permet de récupérer les messages entre le client et son coach
  const getExchanges = async () => {
    //let requestURL = window.$apiURL.toString()+"coachs/exchanges/"+id_request;
    let requestURL =
      window.$apiURL.toString() +
      "coachs/exchanges/user/" +
      id_request +
      "/vip_subscription";
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    if (responsePromise.ok) {
      setRequestType(data[0].subject);
      setUserId(data[0].cli_id);
      setUserLastName(data[0].cli_nom);
      setUserName(data[0].cli_prenom);
      setExchanges(data);
      setIsReady(1);
    } else if (responsePromise.status === 404) {
      window.location.replace("/404");
    }
  };

  // Permet la mise en forme de l'affichage des messages entre le client et son coach
  const commentsList = Object.keys(exchanges).map((key) => {
    if (exchanges[key].from === "coach") {
      return (
        <Grid container spacing={2} key={key}>
          <Grid item xs={12} style={{ textAlign: "right", fontSize: "12px" }}>
            Vous {new Date(exchanges[key].created_at).toLocaleString("fr")}
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={9} style={{ textAlign: "right", paddingTop: 0 }}>
            <Box
              style={{
                maxWidth: "fit_content",
                backgroundColor: "#cfd8dc",
                borderRadius: 5,
                textAlign: "left",
                margin: 0,
              }}
            >
              <p style={{ padding: "10px", margin: 0 }}>
                {exchanges[key].message}
              </p>
            </Box>
          </Grid>
        </Grid>
      );
    } else if (exchanges[key].from === "user") {
      return (
        <Grid container spacing={2} key={key}>
          <Grid item xs={12} style={{ textAlign: "left", fontSize: "12px" }}>
            Utilisateur : {exchanges[key].cli_nom} {exchanges[key].cli_prenom}{" "}
            {new Date(exchanges[key].created_at).toLocaleString("fr")}
          </Grid>

          <Grid item xs={9} style={{ textAlign: "right", paddingTop: 0 }}>
            <Box
              style={{
                maxWidth: "fit_content",
                backgroundColor: "#eeeeee",
                borderRadius: 5,
                textAlign: "left",
                margin: 0,
              }}
            >
              <p style={{ padding: "10px", margin: 0 }}>
                {exchanges[key].message}
              </p>
            </Box>
          </Grid>
          <Grid item xs={3} />
        </Grid>
      );
    }
  });

  // Affichage de la page
  if (isReady === -1) {
    return (
      <div style={{ paddingTop: "50%" }}>
        <CircularProgress style={{ color: "#857A73" }} />
      </div>
    );
  } else if (isReady === 1) {
    if (requestType === "vip_subscription") {
      return (
        <div>
          <Container component="main" maxWidth="xs" className="divRequest">
            <Typography
              component="h3"
              variant="h5"
              style={{ paddingBottom: "10%" }}
            >
              Message du client {userLastName} {userName}
            </Typography>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                Discussion :
              </Grid>
            </Grid>
            <br />
            <br />
            <div
              style={{
                height: "40vh",
                width: "100%",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  overflow: "scroll",
                  overflowX: "hidden",
                  height: "100%",
                }}
              >
                {commentsList}
              </div>
            </div>
          </Container>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default HistDiscSub;
