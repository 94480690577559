import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Button, Grid, TextField, CircularProgress } from "@material-ui/core";

function NewVideo() {
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [video, setVideo] = useState("");
  const [videoName, setVideoName] = useState("");
  const [videoError, setVideoError] = useState("");
  const [saveButton, setSaveButton] = useState("Enregistrer la vidéo");

  const onChangeTitle = (e) => {
    setTitle(e.target.value);
  };
  const onChangeDesc = (e) => {
    setDesc(e.target.value);
  };
  const handleVideoUpload = (e) => {
    e.preventDefault();
    setVideoError("");
    if (!e.target.files[0].name.match(/.(mp4|webm|ogg)$/i)) {
      e.target.value = null;
      setVideoError("Le type de fichier ne peut être que .mp4 .webm .ogg");
    } else {
      if (e.target.files[0].size > 30e6) {
        setVideoError("La taille du fichier ne peut pas dépasser 10 mb");
      } else {
        setVideo(e.target.files[0]);
        setVideoName(e.target.files[0].name);
      }
    }
  };

  const createVideo = async () => {
    setSaveButton(<CircularProgress size={25} style={{ color: "white" }} />);
    let formData = new FormData();
    formData.set("vid_title", title);
    formData.set("vid_desc", desc);
    formData.set("video", video);
    let requestURL = window.$apiURL.toString() + "videos/coachs";
    let requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
      body: formData,
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    if (responsePromise.ok) {
      setSaveButton("Vidéo enregistrée !");
      setTimeout(function () {
        window.location.replace("/newVideo");
      }, 1200);
    }
  };

  return (
    <div>
      <Container component={"main"} maxWidth={"xs"} className="divRequest">
        <Typography
          component="h1"
          variant="h3"
          style={{ paddingBottom: "15%" }}
        >
          Nouvelle vidéo
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Titre de la vidéo"
              variant="outlined"
              value={title}
              onChange={(e) => onChangeTitle(e)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Description de la vidéo"
              variant="outlined"
              value={desc}
              multiline
              rows={4}
              onChange={(e) => onChangeDesc(e)}
            />
          </Grid>
          <Grid item xs={3}>
            <input
              accept=".mp4, .webm, .ogg"
              style={{ display: "none" }}
              id="video_file"
              multiple
              name="coa_profile_picture"
              type="file"
              onChange={(e) => handleVideoUpload(e)}
            />
            <label htmlFor="video_file">
              <Button
                color="primary"
                variant="contained"
                component="span"
                style={{
                  height: "56px",
                  width: "80px",
                  backgroundColor: "#857A73",
                }}
              >
                Choisir
              </Button>
            </label>
          </Grid>
          <Grid item xs={9}>
            <TextField
              disabled
              error={videoError !== ""}
              helperText={videoError !== null ? videoError : ""}
              placeholder="Video"
              variant="outlined"
              value={videoName !== null ? videoName : " "}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              style={{ backgroundColor: "#857A73" }}
              onClick={createVideo}
            >
              {saveButton}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default NewVideo;
