import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import {Button, Grid, Card, CircularProgress} from '@material-ui/core';
import { Link } from "react-router-dom";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import b64toBlob from "b64-to-blob";

function PlanningVideos(){

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            name: {
                fontSize: 16,
                textAlign: 'left',
                color : 'white',
                padding : '10px',
                backgroundColor : '#857A73',
                opacity : '70%',
                margin : 0
            },
            pos: {
                marginBottom: 12,
            },
        }),
    );
    const classes = useStyles();

    useEffect(() => {
        getVideos();
    },[])

    const [videos, setVideos] = useState([]);
    const [isReady, setIsReady] = useState(-1);
    const [videoData, setVideoData] = useState([])

    const getVideos = async () => {
        let requestURL = window.$apiURL.toString()+"videos/users"
        let requestOptions = {
            method : 'GET',
            headers : {
                'Accept' : 'application/json',
                'Authorization' : 'Bearer '+localStorage.getItem('cbs_perform_token'),
            }
        }
        const responsePromise = await fetch(requestURL, requestOptions);
        const data = await responsePromise.json();
        setVideos(data)
        setIsReady(0)
    }

    const getVideo = async (id_video) => {
        setIsReady(1)
        let requestURL = window.$apiURL.toString()+"videos/users/"+id_video;
        let requestOptions = {
            method : 'GET',
            headers : {
                'Accept' : 'application/json',
                'Authorization' : 'Bearer '+localStorage.getItem('cbs_perform_token'),
            }
        }
        const responsePromise = await fetch(requestURL, requestOptions);
        const data = await responsePromise.json();
        setVideoData(data)
        setIsReady(2)
    }


    const videoCards = Object.keys(videos).map(key =>
        <Grid item xs={12}  key={key}>
            <Button 
                fullWidth
                style={{textTransform : 'none'}}
                onClick={() => getVideo(videos[key].id)}
            >
                <Card variant="outlined"  style={{minWidth :'100%'}}  >
                    <Typography className={classes.name} color="textSecondary" gutterBottom>
                        {videos[key].title}
                    </Typography>
                </Card>
            </Button>
        </Grid>
    )

    if (isReady === -1) {
        return (
            <Container component="main" maxWidth="xs" className="divProfile" style={{paddingTop : '10%', paddingBottom : '10%'}}>
                <CircularProgress style={{color : '#857A73'}}/>
            </Container>
        )
    }else if(isReady === 0){
        return(
            <div>
                <Container component="main" maxWidth="xs" className="divProfile">
                    <Typography component="h5" variant="h5" style={{paddingBottom : '15%'}}>
                        Vidéos annexe
                    </Typography>
                    <div 
                        style={{
                            paddingBottom : '10%',
                            minHeight : '215px',
                            width : '95%'}}>
                        <Typography component="h6" variant="h6" style={{paddingBottom : '10%'}}>
                            Cliquez sur n'importe quel titre de vidéo pour lancer la lecture
                        </Typography>
                    </div>
                    <Grid container spacing={2}>
                        {videoCards}
                    </Grid>
                </Container>
            </div>
        )
    }else if (isReady === 1){
        return(
            <div>
                <Container component="main" maxWidth="xs" className="divProfile">
                    <Typography component="h5" variant="h5" style={{paddingBottom : '15%'}}>
                        Vidéos annexe
                    </Typography>
                    <div 
                        style={{
                            paddingBottom : '10%',
                            minHeight : '215px',
                            width : '95%'}}>
                        <Typography component="h6" variant="h6" style={{paddingBottom : '10%'}}>
                            Chargement de la vidéo
                        </Typography>
                        <CircularProgress style={{color : '#857A73'}}/>
                    </div>                    
                    <Grid container spacing={2}>
                        {videoCards}
                    </Grid>
                </Container>
            </div>
        )
    }else if(isReady === 2){
        let blob = b64toBlob(videoData['video'].file, 'video/'+videoData['video'].fileExtention);
        let type = 'video/'+videoData['video'].fileExtention
        let video = URL.createObjectURL(blob);
        return(
            <div>
                <Container component="main" maxWidth="xs" className="divProfile">
                    <Typography component="h5" variant="h5" style={{paddingBottom : '15%'}}>
                        Vidéos annexe
                    </Typography>
                    <Typography component="h6" variant="h6" style={{paddingBottom : '10%'}}>
                        Description de la vidéo : {videoData['details'].desc}
                    </Typography>
                    <div style={{paddingBottom : '10%'}}>
                        <video height="215" width="95%" disablePictureInPicture controls controlsList="nodownload">
                            <source src={video} type={type.toString}/>
                        </video>
                    </div>
                    
                    <Grid container spacing={2}>
                        {videoCards}
                    </Grid>
                </Container>
            </div>
        )
    }else{
        return (
            <Container component="main" maxWidth="xs" className="divProfile" style={{paddingTop : '10%', paddingBottom : '10%'}}>
                <CircularProgress style={{color : '#857A73'}}/>
            </Container>
        )
    }
    
}

export default PlanningVideos;