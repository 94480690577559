import React, { useState } from "react";
import {
  Container,
  Grid,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";
import AffichageObjectifs from "./AffichageObjectifs";

export default function NewDefi() {
  // `date` est un object `Date`
  const formatYmd = (date) => date.toISOString().slice(0, 10);

  const [defi, setDefi] = useState({
    // Bien écrire les noms des champs comme dans la BDD (sensible à la CASSE)
    DEF_NOM: "",
    DEF_DESCRIPTION: "",
    DEF_NBSEMAINES: 1,
    DEF_CONSEILS: "",
    DEF_VISIBLE: 1,
    DEF_DATE_VISIBLE: formatYmd(new Date()),
    COA_ID: null,
  });
  // On met les objectifs à part du défi pour pouvoir les sauvegarder séparement avant de les lier au défi dans la BDD
  const [objectifs, setObjectifs] = useState({});

  const onChangeTitle = (e) => {
    // `...defi` permet de reprendre les autres champs de l'objet Défi.
    setDefi({ ...defi, DEF_NOM: e.target.value });
  };

  const onChangeDescription = (e) => {
    setDefi({ ...defi, DEF_DESCRIPTION: e.target.value });
  };

  const onChangeSemaines = (e) => {
    setDefi({ ...defi, DEF_NBSEMAINES: e.target.value });
  };

  const onChangeConseils = (e) => {
    setDefi({ ...defi, DEF_CONSEILS: e.target.value });
  };

  const onChangeDate = (e) => {
    setDefi({ ...defi, DEF_DATE_VISIBLE: e.target.value });
  };

  const createDefi = async () => {
    let formData = new FormData();
    for (let key in defi) {
      formData.set(key, defi[key]);
    }
    let requestURL = window.$apiURL.toString() + "defis/coachs";
    let requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
      body: formData,
    };

    const responsePromise = await fetch(requestURL, requestOptions);
    const defiId = await responsePromise.text();

    if (responsePromise.ok) {
      createObjectifs(defiId);
    }
  };

  const createObjectifs = async (idDefi) => {
    let formData = new FormData();
    for (let jour in objectifs) {
      for (let objectif in objectifs[jour]) {
        // On veut enregistrer uniquement les objectifs qui sont dans les semaines choisies dans le défi.
        if (objectifs[jour][objectif].OBJ_SEMAINE <= defi.DEF_NBSEMAINES) {
          formData.set(
            jour + objectif,
            JSON.stringify(objectifs[jour][objectif])
          );
        }
      }
    }
    let requestURL = window.$apiURL.toString() + "objectifs/coachs/" + idDefi;
    let requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
      body: formData,
    };

    const responsePromise = await fetch(requestURL, requestOptions);
    if (responsePromise.ok) {
      window.location.replace("/newDefi");
    }
  };

  return (
    <div>
      <Container component="main" maxWidth="md" className="divProfile">
        <Typography component="h1" variant="h3" style={{ paddingBottom: "5%" }}>
          Nouveau défi
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              variant={"outlined"}
              fullWidth
              label={"Nom du défi"}
              onChange={(e) => onChangeTitle(e)}
              value={defi.DEF_NOM}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              variant={"outlined"}
              fullWidth
              label={"Description du défi"}
              onChange={(e) => onChangeDescription(e)}
              multiline
              rows={5}
              value={defi.DEF_DESCRIPTION}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              variant={"outlined"}
              type="number"
              fullWidth
              inputProps={{ min: 1 }}
              label={"Nombre de semaines"}
              onChange={(e) => onChangeSemaines(e)}
              value={defi.DEF_NBSEMAINES}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <AffichageObjectifs
            nbSemaines={defi.DEF_NBSEMAINES}
            objectifs={objectifs}
            setObjectifs={setObjectifs}
          />
          <Grid item xs={1}></Grid>
          <Grid item xs={12}>
            <TextField
              variant={"outlined"}
              fullWidth
              label={"Conseils pour le défi (optionnels)"}
              onChange={(e) => onChangeConseils(e)}
              multiline
              rows={5}
              value={defi.DEF_CONSEILS}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              type={"date"}
              variant={"outlined"}
              fullWidth
              label={"Visible à partir de "}
              onChange={(e) => onChangeDate(e)}
              value={defi.DEF_DATE_VISIBLE}
            />
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <Button
              onClick={createDefi}
              style={{ backgroundColor: "#857A73" }}
              variant={"contained"}
              color={"primary"}
              fullWidth
            >
              Sauvegarder le défi
            </Button>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Container>
    </div>
  );
}
