import React, { useState } from "react";
import { Link } from "react-router-dom";
import { sha256 } from "js-sha256";
import "../../../css/Login.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { Button, TextField, Grid, CircularProgress } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

function LoginCoach() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailErrorText, setEmailErrorText] = useState("");
  const [emailHasError, setEmailHasError] = useState(false);
  const [passwordErrorText, setPasswordErrorText] = useState("");
  const [passwordHasError, setPasswordHasError] = useState(false);

  const [submitButton, setSubmitButton] = useState("Connexion");

  const useStyles = makeStyles((theme) => ({
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    form: {
      minWidth: "100%",
      marginTop: theme.spacing(3),
      marginRight: "2%",
    },
  }));

  const classes = useStyles();

  // Permet de vérifier que les champs sont correctement rempli
  function validate() {
    let errors = false;
    if (
      !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
        email.toString()
      )
    ) {
      errors = true;
      setEmailHasError(true);
      setEmailErrorText("Le format de l'email n'est pas correct !");
    }
    if (email.length === 0) {
      errors = true;
      setEmailHasError(true);
      setEmailErrorText("Email doit être rempli !");
    }
    if (password.length === 0) {
      errors = true;
      setPasswordHasError(true);
      setPasswordErrorText("Vous devez entrer un mot de passe !");
    }
    if (password.length < 8) {
      errors = true;
      setPasswordHasError(true);
      setPasswordErrorText("Le mot de passe doit faire au moins 8 caractères!");
    }
    return errors;
  }

  // Permet de verifier la connexion et connecter l'utilisateur si tout se passe bien
  const loginHandler = async (e) => {
    e.preventDefault();
    let errors = validate();
    if (!errors) {
      e.preventDefault();
      setSubmitButton(
        <CircularProgress size={25} style={{ color: "white" }} />
      );
      let requestURL = window.$apiURL.toString() + "login/coach";
      let loginFormData = document.getElementById("loginForm");
      let formData = new FormData(loginFormData);
      formData.set("password", sha256(formData.get("password")));
      const requestOptions = {
        crossDomain: true,
        method: "POST",
        headers: { Accept: "application/json" },
        body: formData,
      };
      const responsePromise = await fetch(requestURL, requestOptions);
      const data = await responsePromise.json();
      if (!responsePromise.ok) {
        setSubmitButton("Connexion");
        if (data.message !== null) {
          if (data.message === "Email non vérifié") {
            let message = data.message;
            setEmailHasError(true);
            setEmailErrorText(
              <p>
                {message}{" "}
                <a href="" style={{ color: "res" }} onClick={emailResend}>
                  Renvoyer le mail de validation
                </a>
              </p>
            );
          } else {
            setEmailHasError(true);
            setEmailErrorText(data.message);
          }
        }
      } else {
        localStorage.setItem("cbs_perform_token", data.data.token);
        localStorage.setItem(
          "cbs_perform_token_is_coach",
          sha256(localStorage.getItem("cbs_perform_token").substring(162, 243))
        );
        if (data.data.superAdmin === 1) {
          localStorage.setItem(
            "cbs_perform_token_is_super_admin",
            sha256(
              localStorage.getItem("cbs_perform_token").substring(606, 750)
            )
          );
        }

        window.location.replace("/");
      }
    }
  };

  // Permet de renvoyer un email de validation du compte
  const emailResend = async (e) => {
    e.preventDefault();
    let requestURL = window.$apiURL.toString() + "email/resend?email=" + email;
    const requestOptions = {
      crossDomain: true,
      method: "GET",
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    if (responsePromise.ok) {
      setEmailHasError(true);
      setEmailErrorText(data.message);
      setTimeout(function () {
        window.location.reload();
      }, 2000);
    }
  };

  // Permet de stocker les changements sur le champ email
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    setEmailHasError(false);
  };

  // Permet de stocker les changements sur le champ password
  const onChangePassword = (e) => {
    setPassword(e.target.value);
    setPasswordHasError(false);
  };

  // Affichage de la page
  return (
    <div>
      <Container component="main" maxWidth="xs" className="divLogin">
        <CssBaseline />
        <Typography component="h1" variant="h3">
          Connexion admin
        </Typography>
        <br />
        <br />
        <form id="loginForm" className={classes.form} autoComplete>
          <Grid container spacing={2} style={{ minWidth: "100%" }}>
            <Grid item xs={12} align="center">
              <TextField
                variant="outlined"
                required
                fullWidth
                onChange={(e) => onChangeEmail(e)}
                helperText={emailHasError ? emailErrorText : ""}
                error={emailHasError}
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                helperText={passwordHasError ? passwordErrorText : ""}
                error={passwordHasError}
                onChange={(e) => onChangePassword(e)}
                name="password"
                label="Mot de passe"
                type="password"
                id="password"
                autoComplete="current-password"
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <Button href="/ForgotPassword" color="primary">
                Mot de passe oublié ?
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                style={{ backgroundColor: "#857A73" }}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={(e) => loginHandler(e)}
              >
                {" "}
                {submitButton}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Link to="/Register" style={{ textDecoration: "none" }}>
                <Button
                  style={{ backgroundColor: "#857A73" }}
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  {" "}
                  Nouveau ?
                </Button>
              </Link>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
}

export default LoginCoach;
