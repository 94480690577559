import React, { useEffect, useState } from "react";
import "../../../css/Facture.css";
import { Button, Grid, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Pagination from "@material-ui/lab/Pagination";

function Factures() {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      name: {
        fontSize: 16,
        //textAlign: 'left',
        color: "white",
        padding: "10px",
        backgroundColor: "#857A73",
        opacity: "70%",
        margin: 0,
      },
      subject: {
        fontSize: 14,
        padding: "10px",
        color: "white",
        opacity: "85%",
        backgroundColor: "#857A73",
      },
      score: {
        fontSize: 14,
        padding: "10px",
        color: "white",
        opacity: "100%",
        backgroundColor: "#857A73",
      },
      score2: {
        fontSize: 14,
        padding: "10px",
        color: "white",
        opacity: "85%",
        backgroundColor: "#857A73",
      },
      pos: {
        marginBottom: 12,
      },
    })
  );
  const classes = useStyles();

  // Pour vérifier qu'il y a eu un retour du back
  const [facturesAreSet, setFacturesAreSet] = useState(0);

  // Données de la facture
  const [lst, setLst] = useState({});
  const [pageTotal, setPageTotal] = useState("");
  const [hasFacture, setHasFacture] = useState(-1);

  // Permet de récupérer les factures d'un utilisateur
  const getFactures = async () => {
    let requestURL = window.$apiURL.toString() + "payments";
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    if (data.data[0] != null) {
      setPageTotal(data.last_page);
      setLst(data.data);
      setHasFacture(1);
      window.scrollTo(0, 0);
    } else {
      setHasFacture(0);
    }
    setFacturesAreSet(1);
  };

  // Gestion de la pagination et récupération des données des factures selon la page choisie
  const handlePageChange = async (event, value) => {
    let requestURL = window.$apiURL.toString() + "payments?page=" + value;
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    if (data.data[0] != null) {
      setPageTotal(data.last_page);
      setLst(data.data);
      setHasFacture(1);
      window.scrollTo(0, 0);
    } else {
      setHasFacture(0);
    }
    setFacturesAreSet(1);
  };

  useEffect(() => {
    getFactures({});
  }, []);

  // Permet de mettre en forme l'affichage des factures
  const factureCards = Object.keys(lst).map((key) => (
    <Grid item xs={12} key={key}>
      <Card variant="outlined" style={{ minWidth: "100%" }}>
        <Typography className={classes.name} color="textSecondary" gutterBottom>
          {lst[key].pai_detail}
        </Typography>
        {lst[key].pai_statut === "paid" ? (
          <Typography variant="h5" component="h2" className={classes.score}>
            Montant: {lst[key].pai_montant} CHF&emsp;&emsp;Status: payée le{" "}
            {lst[key].pai_datepaiement}
          </Typography>
        ) : (
          <Typography variant="h5" component="h2" className={classes.score}>
            Montant: {lst[key].pai_montant} CHF&emsp;&emsp;Status: non payée
          </Typography>
        )}

        <Typography variant="h5" component="h2" className={classes.score2}>
          <Link
            to={"/Facture/" + lst[key].id}
            style={{ textDecoration: "none" }}
          >
            <Button
              style={{ backgroundColor: "grey" }}
              variant="contained"
              color="primary"
            >
              Ouvrir
            </Button>
          </Link>
        </Typography>
      </Card>
    </Grid>
  ));

  // Affichage de la page
  if (facturesAreSet === 0) {
    return (
      <div style={{ paddingTop: "50%" }}>
        <CircularProgress style={{ color: "#857A73" }} />
      </div>
    );
  } else if (facturesAreSet === 1) {
    if (hasFacture === -1) {
      return (
        <div>
          <Container component="main" maxWidth="xs" className="divFacture">
            <Typography
              component="h5"
              variant="h5"
              style={{ paddingBottom: "20%" }}
            >
              Pas de factures actuellement
            </Typography>
          </Container>
        </div>
      );
    } else if (hasFacture === 1) {
      return (
        <div>
          <Container component="main" maxWidth="xs" className="divFacture">
            <Typography
              component="h4"
              variant="h4"
              style={{ paddingBottom: "20%" }}
            >
              Liste des factures
            </Typography>
            <Grid container spacing={2}>
              {factureCards}
              <Grid
                item
                xs={12}
                style={{ paddingTop: "10%", alignItems: "center" }}
              >
                <Pagination
                  disabled={lst.length === 0}
                  count={pageTotal}
                  variant="outlined"
                  shape="rounded"
                  onChange={handlePageChange}
                  style={{ display: "inline-flex" }}
                />
              </Grid>
            </Grid>
          </Container>
        </div>
      );
    } else if (hasFacture === 0) {
      return (
        <div>
          <Container component="main" maxWidth="xs" className="divFacture">
            <Typography
              component="h5"
              variant="h5"
              style={{ paddingBottom: "20%" }}
            >
              Pas de factures actuellement
            </Typography>
          </Container>
        </div>
      );
    }
  }
}

export default Factures;
