import React, {useEffect, useState} from "react";
import moment from "moment";
import {Button, Grid, TextField, InputLabel, Select} from "@material-ui/core";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Container from "@material-ui/core/Container";
import {useParams} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import '../../../css/Plannings.css';
import iconProgress from '../../../Icons/icons8-increase-24.png'
import iconExercice from '../../../Icons/icons8-exercise-24.png'
import iconApprox from '../../../Icons/icons8-approximately-equal-24.png'
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
import MenuItem from "@material-ui/core/MenuItem";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import SpeedIcon from '@material-ui/icons/Speed';
import jsPDF from "jspdf";
import FormControl from '@material-ui/core/FormControl';


function Planning() {
    useEffect(()=>{
        getUser()
        getPlanningDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let {id_planning} = useParams();
    const [isFreePlan, setIsFreePlan] = useState(true)
    const [planning, setPlanning] = useState({});
    const [dates, setDates] = useState([])
    const [datesExercices, setDatesExercices] = useState([])
    const [dayDetail, setDayDetail] = useState(null);
    const [userIsSet, setUserIsSet] = useState(0)
    const [user, setUser] = useState({
        'id' : '',
        'cli_nom' : '',
        'cli_prenom' : '',
        'cli_abonnement' : '',
    });
    const [pla_prog, setPla_prog] = useState({
        'done' : '',
        'approx' : '',
        'not_done' : '',
    })
    const [dayHasExercice, setDayHasExercice] = useState(false)
    const [con_id, setCon_id] = useState('')
    const [exe_prog, setExe_prog] = useState(-1)
    const [exe_progError, setExe_progError] = useState('')
    const [enabled, setEnabled] = useState(false);
    const [lastUpdate, setLastUpdate] = useState({
        'hour' : '',
        'date' : ''
    });


    // Permet de récupérer les informations sur l'utilisateur connecté
    const getUser = async () => {
        let requestURL = window.$apiURL.toString()+'users'
        let requestOptions = {
            method : 'GET',
            headers : {
                'Accept' : 'application/json',
                'Authorization' : 'Bearer '+localStorage.getItem('cbs_perform_token'),
            },
        }
        const responsePromise = await fetch(requestURL, requestOptions);
        const data = await responsePromise.json();
        setUser(data);
        setUserIsSet(1)
    }

   
    const createPDF = async (e) =>{
        let dateFormat = require('dateformat');
        e.preventDefault();
        var doc = new jsPDF();
        // Titre
        doc.setFont('helvetica')
        doc.setFontSize(18)
        doc.text('Planning : ', 12, 30);
        doc.setFontType("normal");
        doc.text(planning.pla_nom, 42, 30);
        doc.setFontSize(12);
        doc.text(user.cli_prenom+' '+user.cli_nom, 168, 30);
        doc.setFontType("normal");

        doc.text('Début du planning :', 12, 45)
        doc.setFontType("bold");
        doc.text(dateFormat(planning.pla_datedebut, "dd-mm-yyyy"), 50, 45);
        doc.setFontType("normal");
        doc.text('Fin du planning :', 82, 45)
        doc.setFontType("bold");
        doc.text(dateFormat(planning.pla_datefin, "dd-mm-yyyy"), 114, 45);
        doc.setFontType("normal");
        doc.text("Dernière mise à jour du planning : ", 12, 55);
        doc.setFontType("bold");
        doc.text("Le "+lastUpdate.date+" à "+lastUpdate.hour,77, 55)


        doc.setLineWidth(0.2);
        doc.setFontType("bold");
        doc.setFontSize(14);
        doc.text('Date', 13, 72);
        doc.text('Exercice', 38, 72);
        doc.text('Vitesse', 159, 72);
        doc.text('Prog', 181, 72);
        doc.setFontType("normal");
        doc.setFontSize(12);

        let heightH = 75;
        let heightV = 75;
        let length = 85;
        let heightT = 81;
        doc.line(12, heightH, 195, heightH)
        for(let i = 0; i < datesExercices.length; i++){
            if(length === 285){
                doc.line(12, 275, 195, 275)
                doc.addPage();
                length = 25
                heightH = 5;
                heightV = 15;
                heightT = 21;
                doc.setFontType("bold");
                doc.setFontSize(13);
                doc.text('Date', 13, 13);
                doc.text('Exercice', 38, 13);
                doc.text('Vitesse', 159, 13);
                doc.text('Prog', 181, 13);
                doc.setFontType("normal");
                doc.setFontSize(12);
            }
            //h
            doc.line(12, heightH+10, 195, heightH+10)
            //v
            doc.line(12, heightV, 12, length)
            doc.line(36, heightV, 36, length);
            doc.line(157, heightV, 157, length);
            doc.line(178, heightV, 178, length);
            doc.line(195, heightV, 195, length);  

            doc.setFontSize(11)
            doc.text(dateFormat( new Date(datesExercices[i].date), "dd-mm-yyyy"), 14, heightT)
            doc.setFontSize(10)
            doc.text(datesExercices[i].nom_exercice, 37, heightT-2, {
                maxWidth : 118
            })
            doc.setFontSize(11)
            if(datesExercices[i].con_vitesse > 0){
                doc.text(datesExercices[i].con_vitesse+" km/h", 158, heightT)
            }            
            doc.setFontSize(12)

            heightH = heightH + 10;
            heightT = heightT + 10;
            length = length + 10;      
        }
        doc.line(12, heightH+10, 195, heightH+10)

        //Création du fichier PDF
        doc.save('planning_'+planning.pla_nom+'.pdf');

    }
    const moment = require('moment-timezone');

    // Récupère le détail des exercices d'un planning en particulier
    const getPlanningDetails = async () => {
        let requestURL = window.$apiURL.toString() + 'users/plannings/' + id_planning;
        let requestOptions = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('cbs_perform_token'),
            },
        }
        const responsePromise = await fetch(requestURL, requestOptions);
        const data = await responsePromise.json();
        if (!responsePromise.ok) {
            window.location.replace("/404");
        } else {
            if (data.planning.isPlaVip) {
                setIsFreePlan(false)
            } else {
                setIsFreePlan(true)
            }
            setPlanning(data.planning)
            setPla_prog(data.progression)
            let dates = [];
            let datesExercices = [];
            let dateUTC = moment.utc(data.exercices[0].updated_at)
            console.log(dateUTC);
            let dateCEST = dateUTC.clone().tz("Europe/Zurich").format("DD-MM-yyyy")
            let hourCEST = dateUTC.clone().tz("Europe/Zurich").format("HH:mm")
            let object = {
                'hour' : hourCEST,
                'date' : dateCEST
            }
            setLastUpdate(object);
            for (let i = 0; i < data.exercices.length; i++) {
                dates.push(new Date(data.exercices[i].con_date).toISOString().substring(0, 10))
                let object = {
                    'con_id': data.exercices[i].id,
                    'date': new Date(data.exercices[i].con_date).toISOString().substring(0, 10),
                    'nom_exercice': data.exercices[i].exe_nomexercice,
                    'con_prog': data.exercices[i].con_progression,
                    'con_vitesse': data.exercices[i].con_vitesse
                }
                datesExercices.push(object)
            }
            setDates(dates)
            setDatesExercices(datesExercices)
        }
    }

    //Permet de stocker les changements du champs exe_prog
    const onChangeProgression = (e) => {
        setExe_prog(e.target.value)
        setExe_progError('')
    }

    // Vérifie que l'utilisateur a bien entré une nouvelle progression
    function validate(){
        let errors = false;
        if (exe_prog === -1){
            errors = true;
            setExe_progError('Veuillez entrer la progression de l\'exercice');
        }
        return errors;
    }

    // Enregistre la nouvelle progression entrée par l'utilisateur
    const saveProgression = async () =>{
        let errors = validate();
        if (!errors){
            let formData = new FormData();
            formData.set('con_ids[]', con_id);
            formData.set('con_progressions[]', exe_prog);
            let object = {};
            formData.forEach(function (value, key) {
                object[key] = value;
            });
            let jsonBody = JSON.stringify(object);
            let requestURL = window.$apiURL.toString()+'users/plannings/'+id_planning
            let requestOptions = {
                method : 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('cbs_perform_token'),
                },
                body: jsonBody,
            }
            const responsePromise = await fetch(requestURL, requestOptions);
            // eslint-disable-next-line no-unused-vars
            const data = await responsePromise.json();
            if (responsePromise.ok){
                window.location.replace("/Planning/"+id_planning)
            }
        }
    }

    const [dateObject, setDateObject] = useState(moment())

    let weekdays = ['Lu','Ma','Me','Je','Ve','Sa','Di',];

    let weekdayshortname = weekdays.map(day =>{
        return (
            <Grid item xs style={{fontSize : '12px'}}>
                {day}
            </Grid>
        )
    })
    const firstDayOfMonth = () =>{
        return moment(dateObject).startOf("month").format("d");
    }
    let blanks = [];
    for (let i = 1; i<firstDayOfMonth(); i++){
        blanks.push(
            <Grid key={i} item xs>{''}</Grid>
        )
    }
    // Permet d'afficher les exercices et ses détails lors d'un clic sur une date du calendrier (s'il y en a un de prévu à cette date)
    const handleDateClick = (e) => {
        let hasExercice = false;
        if (moment().format().substring(0,10) < e.currentTarget.value){
            setEnabled(true);
        }else{
            setEnabled(false);
        }
        for (let i = 0; i < datesExercices.length; i++){
            if(datesExercices[i].date === e.currentTarget.value){
                setDayHasExercice(true)
                setCon_id(datesExercices[i].con_id)
                hasExercice = true;
                if (user.cli_abonnement === 2){
                    if (!isFreePlan){
                        if (datesExercices[i].con_vitesse >= 0.01){
                            setDayDetail(
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <img src={iconExercice} alt={''}/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        {datesExercices[i].nom_exercice}
                                    </Grid>
                                    <Grid item xs={3}>
                                        <img src={iconProgress} alt={''}/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        {datesExercices[i].con_prog+' %'}
                                    </Grid>
                                    <Grid item xs={3}>
                                        <SpeedIcon/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        {datesExercices[i].con_vitesse+' km/h'}
                                    </Grid>
                                </Grid>
                            )
                        }else if (datesExercices[i].con_vitesse < 0.01){
                            setDayDetail(
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <img src={iconExercice} alt={''}/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        {datesExercices[i].nom_exercice}
                                    </Grid>
                                    <Grid item xs={3}>
                                        <img src={iconProgress} alt={''}/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        {datesExercices[i].con_prog+'%'}
                                    </Grid>
                                </Grid>
                            )
                        }
                    }else{
                        setDayDetail(
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <img src={iconExercice} alt={''}/>
                                </Grid>
                                <Grid item xs={9}>
                                    {datesExercices[i].nom_exercice}
                                </Grid>
                            </Grid>
                        )
                    }
                }else if(user.cli_abonnement === 0 || user.cli_abonnement === 3){
                    setDayDetail(
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <img src={iconExercice} alt={''}/>
                            </Grid>
                            <Grid item xs={9}>
                                {datesExercices[i].nom_exercice}
                            </Grid>
                        </Grid>
                    )
                }
            }
        }
        if (!hasExercice){
            setDayHasExercice(false)
            setDayDetail(
                <Grid item xs={12}>
                    Pas d'exercices ce jour la
                </Grid>
            )
        }
    }


    let daysInMonth = [];

    // Permet de créer un bouton pour chaque jour du mois
    for (let d = 1; d<=dateObject.daysInMonth(); d++ ){
        let value = moment(dateObject).date(d).format().substring(0,10);
        if (dates.includes(value)) {
            if (moment().format().substring(0,10) < value){
                daysInMonth.push(
                    <Grid item xs>
                        <Button
                            value={value}
                            onClick={e => handleDateClick(e)}
                            color="primary"
                            variant="contained"
                            style={{padding : '0', minWidth : '36px', minHeight : '36px', borderRadius: 25, backgroundColor : '#857A73'}}
                        >
                            {d}
                        </Button>
                    </Grid>
                );
            }else{
                if (user.cli_abonnement === 2){
                    let color = '';
                    for (let i = 0; i< datesExercices.length; i++){
                        if (value === datesExercices[i].date){
                            if (!isFreePlan){
                                if (datesExercices[i].con_prog === '0.00'){
                                    color = "#ef5350";
                                    daysInMonth.push(
                                        <Grid item xs>
                                            <Button
                                                value={value}
                                                onClick={e => handleDateClick(e)}
                                                variant="contained"
                                                style={{padding : '0', minWidth : '36px', minHeight : '36px', borderRadius: 25, backgroundColor : color}}
                                            >
                                                {d}
                                            </Button>
                                        </Grid>
                                    );
                                }else if(datesExercices[i].con_prog === '50.00'){

                                    color = "#ffa726";
                                    daysInMonth.push(
                                        <Grid item xs>
                                            <Button
                                                value={value}
                                                onClick={e => handleDateClick(e)}
                                                variant="contained"
                                                style={{padding : '0', minWidth : '36px', minHeight : '36px', borderRadius: 25, backgroundColor : color}}
                                            >
                                                {d}
                                            </Button>
                                        </Grid>
                                    );
                                }else if(datesExercices[i].con_prog === '100.00'){
                                    color = "#66bb6a";
                                    daysInMonth.push(
                                        <Grid item xs>
                                            <Button
                                                value={value}
                                                onClick={e => handleDateClick(e)}
                                                variant="contained"
                                                style={{padding : '0', minWidth : '36px', minHeight : '36px', borderRadius: 25, backgroundColor : color}}
                                            >
                                                {d}
                                            </Button>
                                        </Grid>
                                    );
                                }
                            }else{
                                daysInMonth.push(
                                    <Grid item xs>
                                        <Button
                                            value={value}
                                            onClick={e => handleDateClick(e)}
                                            color="primary"
                                            variant="contained"
                                            style={{padding : '0', minWidth : '36px', minHeight : '36px', borderRadius: 25, backgroundColor : '#857A73'}}
                                        >
                                            {d}
                                        </Button>
                                    </Grid>
                                );
                            }
                        }
                    }
                }else if (user.cli_abonnement === 0 || user.cli_abonnement === 3){
                    daysInMonth.push(
                        <Grid item xs>
                            <Button
                                value={value}
                                onClick={e => handleDateClick(e)}
                                color="primary"
                                variant="contained"
                                style={{padding : '0', minWidth : '36px', minHeight : '36px', borderRadius: 25, backgroundColor : '#857A73'}}
                            >
                                {d}
                            </Button>
                        </Grid>
                    );
                }

            }
        }else{
            daysInMonth.push(
                <Grid item xs>
                    <Button
                        style={{padding : '0', minWidth : '36px', minHeight : '36px', borderRadius: 25}}
                        value={value}
                        onClick={e => handleDateClick(e)}
                    >
                        {d}
                    </Button>

                </Grid>
            );
        }
    }
    var totalSlots = [...blanks, ...daysInMonth];
    let rows = [];
    let cells = [];
    totalSlots.forEach((row, i) => {
        if (i % 7 !== 0) {
            cells.push(row);
        } else {
            rows.push(cells);
            cells = [];
            cells.push(row);
        }
        if (i === totalSlots.length - 1) {
            while (cells.length < 7){
                cells.push(<Grid item xs>{''}</Grid>)
            }
            rows.push(cells);
        }
    });
    let daysinmonth = rows.map((d, i) => {
        return <Grid container style={{paddingBottom : '10px'}}>{d}</Grid>;
    });
    const month = () => {
        return dateObject.format("MMMM");
    };
    const year = () =>{
        return dateObject.format("Y");
    }
    const prevMonth = (e) =>{
        setDateObject(null);
        let date = dateObject
        setDateObject(moment(date).subtract(1,'month'));
    }
    const nextMonth = (e) =>{
        setDateObject(null);
        let date = dateObject
        setDateObject(moment(date).add(1,'month'));
    }

    const [open, setOpen] = useState(false)

    const handleDialogOpen = () => {
        setOpen(true);
    }
    const handleDialogClose = () => {
        setOpen(false);
    }

    // Permet de supprimer le planning de la base de données
    const handleDeletePlanning = async () => {
        let requestURL = window.$apiURL.toString()+"users/plannings/"+id_planning;
        let requestOptions = {
            method : 'DELETE',
            headers : {
                'Accept' : 'application/json',
                'Authorization' : 'Bearer '+localStorage.getItem('cbs_perform_token'),
            },
        }
        const responsePromise = await fetch(requestURL, requestOptions);
        const data = await responsePromise.json();
        if (responsePromise.ok){
            window.location.replace("/Plannings");
        }
    }

    const closePlanning = async () => {
        window.location.replace("/Plannings");
    }

    const chatPlanning = async () => {
        window.location.replace("/PlanningVipDiscUser/"+id_planning);
    }

    // Permet de changer l'affichage selon l'abonnement du client
    if (userIsSet === 1 && dates.length !== 0){
        if (user.cli_abonnement === 0 || user.cli_abonnement === 3){
            return (
                <div>
                    <Container component="main" maxWidth="xs" className="divPlannings">
                        <Typography component="h1" variant="h3" style={{paddingBottom : '20%'}}>
                            Details de votre planning
                        </Typography>
                        <Grid container>
                            <Grid container xs={12} style={{paddingBottom : '20px'}}>
                                <Grid item xs>
                                    <Button
                                        onClick={e => prevMonth(e)}
                                        style={{
                                            minHeight : '36px',
                                            minWidth : '36px',
                                            borderRadius : 25,
                                        }}>
                                        <NavigateBeforeIcon/>
                                    </Button>
                                </Grid>
                                <Grid item xs style={{textTransform : 'capitalize', paddingTop : '8px'}}>
                                    {month()}{' '}{year()}
                                </Grid>
                                <Grid item xs>
                                    <Button
                                        onClick={e => nextMonth(e)}
                                        style={{
                                            minHeight : '36px',
                                            minWidth : '36px',
                                            borderRadius : 25,
                                        }}
                                    >
                                        <NavigateNextIcon/>
                                    </Button>
                                </Grid>

                            </Grid>
                            <Grid container xs={12} style={{paddingBottom : '20px'}}>
                                {weekdayshortname}
                            </Grid>
                            <br/>
                            {daysinmonth}
                        </Grid>
                        <br/>
                        <br/>
                        <br/>
                        <Grid container>
                            <Grid item xs={12} style={{minHeight : '350px'}}>
                                <Typography component="h3" variant="h5" style={{paddingBottom : '20%'}}>
                                    Exercice de la journée
                                </Typography>
                                {dayDetail}
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    style={{backgroundColor : '#857A73'}}
                                    fullWidth
                                    variant={"contained"}
                                    color={"primary"}
                                    onClick={() => closePlanning()}
                                >
                                    Fermer le planning
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    style={{backgroundColor : '#f44336'}}
                                    fullWidth
                                    variant={"contained"}
                                    color={"primary"}
                                    onClick={() => handleDialogOpen()}
                                >
                                    Supprimer le planning
                                </Button>
                                <Dialog
                                    open={open}
                                    onClose={handleDialogClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{ maxWidth : '100%'}}
                                >
                                    <DialogContent style={{maxWidth : '90%', paddingRight : '5%', paddingLeft: '5%'}}>
                                        <DialogContentText id="alert-dialog-description" style={{color : 'black', fontSize : 20}}>
                                            Êtes-vous sûr de vouloir supprimer votre planning ?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions style={{ maxWidth : '92%', paddingRight : '4%', paddingLeft: '4%'}}>
                                        <Button onClick={handleDialogClose} color="primary" autofocus>
                                            Annuler
                                        </Button>
                                        <Button onClick={handleDeletePlanning} style={{color : '#f44336'}}>
                                            Supprimer
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            )
        }else if(user.cli_abonnement === 2){
            if(isFreePlan){
                return (
                    <div>
                        <Container component="main" maxWidth="xs" className="divPlannings">
                            <Typography component="h1" variant="h3" style={{paddingBottom : '20%'}}>
                                Details de votre planning
                            </Typography>
                            <Grid container>
                                <Grid container style={{paddingBottom : '20px'}}>
                                    <Grid item xs>
                                        <Button
                                            onClick={e => prevMonth(e)}
                                            style={{
                                                minHeight : '36px',
                                                minWidth : '36px',
                                                borderRadius : 25,
                                            }}>
                                            <NavigateBeforeIcon/>
                                        </Button>
                                    </Grid>
                                    <Grid item xs style={{textTransform : 'capitalize', paddingTop : '8px'}}>
                                        {month()}{' '}{year()}
                                    </Grid>
                                    <Grid item xs>
                                        <Button
                                            onClick={e => nextMonth(e)}
                                            style={{
                                                minHeight : '36px',
                                                minWidth : '36px',
                                                borderRadius : 25,
                                            }}
                                        >
                                            <NavigateNextIcon/>
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid container style={{paddingBottom : '20px'}}>
                                    {weekdayshortname}
                                </Grid>
                                <br/>
                                {daysinmonth}
                            </Grid>
                            <br/>
                            <br/>
                            <br/>
                            <Grid container spacing={2}>
                                <Grid item xs={12} style={{minHeight : '350px'}}>
                                    <Typography component="h3" variant="h5" style={{paddingBottom : '50px'}}>
                                        Exercice de la journée
                                    </Typography>
                                    {dayDetail}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} style={{paddingTop : '10%'}}>
                                    <Button
                                        style={{backgroundColor : '#857A73'}}
                                        fullWidth
                                        variant={"contained"}
                                        color={"primary"}
                                        onClick={() => closePlanning()}
                                    >
                                        Fermer le planning
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        style={{backgroundColor : '#f44336'}}
                                        fullWidth
                                        variant={"contained"}
                                        color={"primary"}
                                        onClick={() => handleDialogOpen()}
                                    >
                                        Supprimer le planning
                                    </Button>
                                    <Dialog
                                        open={open}
                                        onClose={handleDialogClose}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                        style={{ maxWidth : '100%'}}
                                    >
                                        <DialogContent style={{maxWidth : '90%', paddingRight : '5%', paddingLeft: '5%'}}>
                                            <DialogContentText id="alert-dialog-description" style={{color : 'black', fontSize : 20}}>
                                                Êtes-vous sûr de vouloir supprimer votre planning ?
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions style={{ maxWidth : '92%', paddingRight : '4%', paddingLeft: '4%'}}>
                                            <Button onClick={handleDialogClose} color="primary" autofocus>
                                                Annuler
                                            </Button>
                                            <Button onClick={handleDeletePlanning} style={{color : '#f44336'}}>
                                                Supprimer
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                )
            }else {
                return (
                    <div>
                        <Container component="main" maxWidth="xl" className="divPlannings">
                            <Typography component="h1" variant="h3" style={{paddingBottom : '5%'}}>
                                Details de votre planning
                            </Typography>                            
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Grid container style={{paddingBottom : '20px'}}>
                                            <Grid item xs>
                                                <Button
                                                    onClick={e => prevMonth(e)}
                                                    style={{
                                                        minHeight : '36px',
                                                        minWidth : '36px',
                                                        borderRadius : 25,
                                                    }}>
                                                    <NavigateBeforeIcon/>
                                                </Button>
                                            </Grid>
                                            <Grid item xs style={{textTransform : 'capitalize', paddingTop : '8px'}}>
                                                {month()}{' '}{year()}
                                            </Grid>
                                            <Grid item xs>
                                                <Button
                                                    onClick={e => nextMonth(e)}
                                                    style={{
                                                        minHeight : '36px',
                                                        minWidth : '36px',
                                                        borderRadius : 25,
                                                    }}
                                                >
                                                    <NavigateNextIcon/>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid container xs={12} style={{paddingBottom : '20px'}}>
                                            {weekdayshortname}
                                        </Grid>
                                        <br/>
                                        {daysinmonth}
                                        <Grid item xs={12} style={{minHeight : '350px'}}>
                                            <Typography component="h3" variant="h5" style={{paddingBottom : '20%'}}>
                                                Exercice de la journée
                                            </Typography>
                                            {dayDetail}
                                        </Grid>
                                        <Grid container spacing={2} style={{minHeight : '100px'}}>
                                            <Grid item xs={9} hidden={!dayHasExercice}>
                                                <FormControl variant="outlined" error={exe_progError !== ''}>
                                                    <InputLabel htmlFor="prog_level">Progression de l'exercice</InputLabel>
                                                    <Select
                                                        disabled={enabled}
                                                        native
                                                        label="Progression de l'exercice"
                                                        onChange={e => onChangeProgression(e)}
                                                        helperText={exe_progError !== '' ? exe_progError : ''}
                                                        inputProps={{
                                                            id: 'prog_level',
                                                        }}
                                                    >
                                                    <option aria-label="None" value="" />
                                                    <option value={0}>Exercice non effectué</option>
                                                    <option value={50}>Exercice effectué en partie</option>
                                                    <option value={100}>Exercice effectué</option>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3}  hidden={!dayHasExercice}>
                                                <Button
                                                    disabled={enabled}
                                                    variant={"contained"}
                                                    color={"primary"}
                                                    style={{backgroundColor : '#857A73',minHeight : '56px'}}
                                                    fullWidth
                                                    onClick={() => saveProgression()}
                                                >
                                                    <SaveIcon/>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs>
                                                <CheckIcon/>
                                            </Grid>
                                            <Grid item xs>
                                                <img src={iconApprox} alt={''}/>
                                            </Grid>
                                            <Grid item xs>
                                                <ClearIcon/>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs>
                                                {pla_prog.done}
                                            </Grid>
                                            <Grid item xs>
                                                {pla_prog.approx}
                                            </Grid>
                                            <Grid item xs>
                                                {pla_prog.not_done}
                                            </Grid>
                                        </Grid>
                                    </Grid>                                        
                                    <Grid item={12} sm={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} >
                                            <Button
                                                style={{backgroundColor : '#857A73'}}
                                                fullWidth
                                                variant={"contained"}
                                                color={"primary"}
                                                onClick={() => closePlanning()}
                                            >
                                                Fermer le planning
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                style={{backgroundColor : '#f44336'}}
                                                fullWidth
                                                variant={"contained"}
                                                color={"primary"}
                                                onClick={() => handleDialogOpen()}
                                            >
                                                Supprimer le planning
                                            </Button>
                                            <Dialog
                                                open={open}
                                                onClose={handleDialogClose}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                                style={{ maxWidth : '100%'}}
                                            >
                                                <DialogContent style={{maxWidth : '90%', paddingRight : '5%', paddingLeft: '5%'}}>
                                                    <DialogContentText id="alert-dialog-description" style={{color : 'black', fontSize : 20}}>
                                                        Êtes-vous sûr de vouloir supprimer votre planning ?
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions style={{ maxWidth : '92%', paddingRight : '4%', paddingLeft: '4%'}}>
                                                    <Button onClick={handleDialogClose} color="primary" autofocus>
                                                        Annuler
                                                    </Button>
                                                    <Button onClick={handleDeletePlanning} style={{color : '#f44336'}}>
                                                        Supprimer
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                style={{backgroundColor : '#857A73'}}
                                                fullWidth
                                                variant={"contained"}
                                                color={"primary"}
                                                onClick={() => chatPlanning()}
                                            >
                                                Conversations du planning
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                style={{backgroundColor : '#857A73'}}
                                                fullWidth
                                                variant={"contained"}
                                                color={"primary"}
                                                onClick={() => window.location.replace("/planningVideos")}
                                            >
                                                Vidéos annexe
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                style={{backgroundColor : '#857A73'}}
                                                fullWidth
                                                variant={"contained"}
                                                color={"primary"}
                                                onClick={createPDF}
                                            >
                                                Version imprimable
                                            </Button>                                    
                                        </Grid>
                                    </Grid>
                                </Grid>                           
                            </Grid>
                        </Container>
                    </div>
                )
            }

        }
    }else{
        return(
            <Container component="main" maxWidth="xs" className="divProfile" style={{paddingTop : '10%', paddingBottom : '10%'}}>
                <CircularProgress style={{color : '#857A73'}}/>
            </Container>
        )
    }

}
export default Planning;