import React, { useState } from "react";
import {
  Container,
  Grid,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";

export default function NewDefi() {
  const [objectif, setObjectif] = useState({
    OBJ_NOM: "",
    OBJ_DESCRIPTION: "",
    OBJ_CONSEILS: "",
  });

  const onChangeName = (e) => {
    // ...objectif permet de reprendre les autres champs de l'objet objectif.
    setObjectif({ ...objectif, OBJ_NOM: e.target.value });
  };

  const onChangeDescription = (e) => {
    setObjectif({ ...objectif, OBJ_DESCRIPTION: e.target.value });
  };

  const onChangeConseils = (e) => {
    setObjectif({ ...objectif, OBJ_CONSEILS: e.target.value });
  };

  const createObjectif = async () => {
    let formData = new FormData();
    for (let key in objectif) {
      formData.set(key, objectif[key]);
    }
    let requestURL = window.$apiURL.toString() + "objectifs/coachs";
    let requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
      body: formData,
    };

    const responsePromise = await fetch(requestURL, requestOptions);
    if (responsePromise.ok) {
      window.location.replace("/newObjectif");
    }
  };

  return (
    <div>
      <Container component="main" maxWidth="sm" className="divProfile">
        <Typography component="h1" variant="h3" style={{ paddingBottom: "5%" }}>
          Nouvel objectif
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              variant={"outlined"}
              fullWidth
              label={"Nom de l'objectif"}
              onChange={(e) => onChangeName(e)}
              value={objectif.OBJ_NOM}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              variant={"outlined"}
              fullWidth
              label={"Description de l'objectif"}
              onChange={(e) => onChangeDescription(e)}
              multiline
              rows={5}
              value={objectif.OBJ_DESCRIPTION}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant={"outlined"}
              fullWidth
              label={"Conseils pour l'objectif' (optionnels)"}
              onChange={(e) => onChangeConseils(e)}
              multiline
              rows={5}
              value={objectif.OBJ_CONSEILS}
            />
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <Button
              onClick={createObjectif}
              style={{ backgroundColor: "#857A73" }}
              variant={"contained"}
              color={"primary"}
              fullWidth
            >
              Sauvegarder l'objectif
            </Button>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Container>
    </div>
  );
}
