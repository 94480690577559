import React, { useState } from "react";

import { Container, Grid, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { TextField } from "@material-ui/core";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function NewFaq() {
  const [title, setTitle] = useState("");
  const onChangeTitle = (e) => {
    setTitle(e.target.value);
  };
  const [content, setContent] = useState("");
  const onChangeContent = (e) => {
    setContent(e.target.value);
  };

  const createFaq = async () => {
    let formData = new FormData();
    formData.set("faq_title", title);
    formData.set("faq_content", content);
    let requestURL = window.$apiURL.toString() + "faqs/coach";
    let requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
      body: formData,
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    if (responsePromise.ok) {
      window.location.replace("/newFaq");
    }
  };

  return (
    <div>
      <Container component="main" maxWidth="xl" className="divProfile">
        <Typography component="h1" variant="h3" style={{ paddingBottom: "5%" }}>
          Nouvelle FAQ
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant={"outlined"}
              fullWidth
              helperText={"Titre de la FAQ"}
              onChange={(e) => onChangeTitle(e)}
              value={title}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant={"outlined"}
              fullWidth
              helperText={"Contenu de la FAQ"}
              onChange={(e) => onChangeContent(e)}
              multiline
              rows={15}
              value={content}
            />
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <Button
              onClick={createFaq}
              style={{ backgroundColor: "#857A73" }}
              variant={"contained"}
              color={"primary"}
              fullWidth
            >
              Sauvgarder la FAQ
            </Button>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default NewFaq;
