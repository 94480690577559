import React, { useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { Button, TextField, Grid } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import "../../../css/Request.css";

function ForgotPassword() {
  const [emailErrorText, setEmailErrorText] = useState("");
  const [emailHasError, setEmailHasError] = useState(false);
  const [email, setEmail] = useState("");

  // Permet de stocker les changements du champ email
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    setEmailHasError(false);
  };

  // Permet de vérifier si les champs sont correctement remplis
  function validate() {
    let errors = false;
    if (
      !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
        email.toString()
      )
    ) {
      errors = true;
      setEmailHasError(true);
      setEmailErrorText("Le format de l'email n'est pas correct !");
    }
    if (email.length === 0) {
      errors = true;
      setEmailHasError(true);
      setEmailErrorText("Email doit être rempli !");
    }
    return errors;
  }

  // Permet d'envoyer un mail pour réinitialiser le mot de passe
  const sendForgotPasswordMail = async (e) => {
    e.preventDefault();
    let errors = validate();
    if (!errors) {
      e.preventDefault();
      let forgotPasswordFormData = document.getElementById(
        "forgotPasswordForm"
      );
      let formData = new FormData(forgotPasswordFormData);
      let requestURL = window.$apiURL.toString() + "password/email";
      let requestOptions = {
        crossDomain: true,
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: formData,
      };
      const responsePromise = await fetch(requestURL, requestOptions);
      const data = await responsePromise.json();
      if (data.error != null) {
        setEmailHasError(true);
        setEmailErrorText(data.error);
      } else {
        localStorage.setItem("user_mail", email);
        window.location.replace("/MailNotificationPage");
      }
    }
  };

  // Affichage de la page
  return (
    <div>
      <Container component="main" maxWidth="xs" className="divRequest">
        <CssBaseline />
        <Typography
          component="h3"
          variant="h5"
          style={{ paddingTop: "10%", paddingBottom: "10%" }}
        >
          Réinitialisation du mot de passe
        </Typography>
        <br />
        <br />
        <form id="forgotPasswordForm" autoComplete>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                onChange={(e) => onChangeEmail(e)}
                helperText={emailHasError ? emailErrorText : ""}
                error={emailHasError}
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                style={{ paddingBottom: "10%" }}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <Button
                style={{ backgroundColor: "#857A73" }}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={(e) => sendForgotPasswordMail(e)}
              >
                {" "}
                Réinitialiser le mot de passe
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
}

export default ForgotPassword;
