import React, { useEffect, useState } from "react";
import "../../../css/Clients.css";
import { Button, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { Link, useParams } from "react-router-dom";
import Card from "@material-ui/core/Card";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { keys } from "@material-ui/core/styles/createBreakpoints";

function HistReqVipPlan() {
  const [isReady, setIsReady] = useState(-1);
  const [exchanges, setExchanges] = useState("");
  const [pageTotal, setPageTotal] = useState("");

  const [lst, setLst] = useState("");
  const [hasRequest, setHasRequest] = useState(-1);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      name: {
        fontSize: 16,
        textAlign: "left",
        color: "white",
        padding: "10px",
        backgroundColor: "#857A73",
        opacity: "70%",
        margin: 0,
      },
      pos: {
        marginBottom: 12,
      },
    })
  );
  const classes = useStyles();

  let { id_user } = useParams();

  // Permet de récupérer les demandes de plan VIP d'un client
  const getVipPlanningReq = async () => {
    let requestURL =
      window.$apiURL.toString() +
      "coachs/exchanges/" +
      id_user +
      "?subject=plannings";
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    setLst(data.data);
    setHasRequest(1);

    setExchanges(data["data"]);
    setPageTotal(data.last_page);
    setIsReady(1);
  };

  useEffect(() => {
    getVipPlanningReq({});
  }, []);

  // Permet la mise en forme de l'affichage des demandes de plan VIP d'un client
  const requestCards = Object.keys(lst).map((key) => (
    <Grid item xs={12} key={key}>
      <Link
        to={"/PlanningVipDisc/request/" + lst[key].uce_id}
        style={{ textDecoration: "none" }}
      >
        <Card variant="outlined" style={{ minWidth: "100%" }}>
          <Typography
            className={classes.name}
            color="textSecondary"
            gutterBottom
          >
            {lst[key].cli_nom} {lst[key].cli_prenom} : {lst[key].pla_id}
          </Typography>
        </Card>
      </Link>
    </Grid>
  ));

  // Affichage de la page (différent selon s'il y a des demandes de plan VIP ou non)
  if (hasRequest === -1) {
    return (
      <div>
        <Container component="main" maxWidth="xs" className="divClients">
          <Typography
            component="h5"
            variant="h5"
            style={{ paddingBottom: "20%" }}
          >
            Pas de demande actuellement
          </Typography>
        </Container>
      </div>
    );
  } else if (hasRequest === 1) {
    return (
      <div>
        <Container component="main" maxWidth="xs" className="divClients">
          <Typography
            component="h4"
            variant="h4"
            style={{ paddingBottom: "20%" }}
          >
            Liste des demandes de plan VIP
          </Typography>
          <Grid container spacing={2}>
            {requestCards}
          </Grid>
        </Container>
      </div>
    );
  } else if (hasRequest === 0) {
    return (
      <div>
        <Container component="main" maxWidth="xs" className="divClients">
          <Typography
            component="h5"
            variant="h5"
            style={{ paddingBottom: "20%" }}
          >
            Pas de demande actuellement
          </Typography>
        </Container>
      </div>
    );
  }
}

export default HistReqVipPlan;
