import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import Container from "@material-ui/core/Container";

function FreeUserManagement() {
  const [nbFreeUser, setNbFreeUser] = useState("");

  // Permet de récupéprer le nombre de clients gratuits du coach connecté
  const getStatsFreeClients = async () => {
    let requestURL = window.$apiURL.toString() + "coachs/nb_users";
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    if (responsePromise.ok) {
      setNbFreeUser(data.nb_users_free);
    } else if (responsePromise.status === 404) {
      window.location.replace("/404");
    }
  };

  useEffect(() => {
    getStatsFreeClients({});
  }, []);

  // Affichage de la page
  return (
    <div>
      <Container component={"main"} maxWidth={"xs"} className="divRequest">
        <Typography
          component="h1"
          variant="h3"
          style={{ paddingBottom: "15%" }}
        >
          Utilisateurs gratuits
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid item xs={12} style={{ textAlign: "left" }}>
              <b>Utilisateurs ayant l'abonnement gratuit : </b> {nbFreeUser}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
export default FreeUserManagement;
