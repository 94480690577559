import React, { useEffect, useState } from "react";
import "../../../css/Clients.css";
import {
  Button,
  Grid,
  TextField,
  Card,
  CircularProgress,
  MenuItem,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";

function Clients() {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      name: {
        fontSize: 16,
        textAlign: "left",
        color: "white",
        padding: "10px",
        backgroundColor: "#857A73",
        opacity: "70%",
        margin: 0,
      },
      pos: {
        marginBottom: 12,
      },
    })
  );

  useEffect(() => {
    getClients({});
  }, []);

  const classes = useStyles();

  const [isReady, setIsReady] = useState(-1);

  const [clients, setClients] = useState("");

  const [query, setQuery] = useState("");

  const [sort, setSort] = useState("ASC");

  const onChangeQuery = (e) => {
    setQuery(e.target.value);
  };
  const searchOnEnter = (e) => {
    if (e.key === "Enter") {
      searchClients();
    }
  };
  const onChangeSort = (e) => {
    setSort(e.target.value);
  };

  // Permet de récupérer les clients du coach connecté
  const getClients = async () => {
    let requestURL = window.$apiURL.toString() + "coachs/users";
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    if (data.length > 0) {
      setClients(data);
      setIsReady(0);
    } else {
      setIsReady(3);
    }
  };

  const searchClients = async () => {
    let requestURL;
    if (query.length === 0) {
      requestURL =
        window.$apiURL.toString() + "coachs/users/search/empty/" + sort;
    } else {
      requestURL =
        window.$apiURL.toString() + "coachs/users/search/" + query + "/" + sort;
    }
    setIsReady(0);
    setClients([]);
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    setClients(data);
    if (data.length > 0) {
      setIsReady(0);
    } else {
      setIsReady(2);
    }
  };

  // Permet la mise en forme de l'affichage des clients du coach
  const clientCards = Object.keys(clients).map((key) => (
    <Grid item xs={12} key={key}>
      <Link
        to={"/DetailClient/" + clients[key].id}
        style={{ textDecoration: "none" }}
      >
        <Card variant="outlined" style={{ minWidth: "100%" }}>
          <Typography
            className={classes.name}
            color="textSecondary"
            gutterBottom
          >
            {clients[key].cli_nom} {clients[key].cli_prenom}
          </Typography>
        </Card>
      </Link>
    </Grid>
  ));

  // Affichage de la page (différent selon si le coach possède des clients ou non)
  if (isReady === -1) {
    return (
      <div style={{ paddingTop: "50%" }}>
        <CircularProgress style={{ color: "#857A73" }} />
      </div>
    );
  } else if (isReady === 0) {
    return (
      <div>
        <Container component="main" maxWidth="xl" className="divClients">
          <Typography
            component="h4"
            variant="h4"
            style={{ paddingBottom: "5%" }}
          >
            Liste des clients
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6} style={{ paddingBottom: "10%" }}>
              <TextField
                variant="outlined"
                label="Recherche"
                value={query}
                onChange={(e) => onChangeQuery(e)}
                onKeyPress={(e) => searchOnEnter(e)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                select
                label="Tri"
                variant="outlined"
                value={sort}
                onChange={(e) => onChangeSort(e)}
              >
                <MenuItem value={"ASC"}>Croissant</MenuItem>
                <MenuItem value={"DESC"}>Décroissant</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <Button style={{ height: "56px" }} onClick={searchClients}>
                <SearchIcon />
              </Button>
            </Grid>
            {clientCards}
          </Grid>
        </Container>
      </div>
    );
  } else if (isReady === 1) {
    return (
      <div>
        <Container component="main" maxWidth="xl" className="divClients">
          <Typography
            component="h4"
            variant="h4"
            style={{ paddingBottom: "20%" }}
          >
            Liste des clients
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6} style={{ paddingBottom: "10%" }}>
              <TextField
                variant="outlined"
                label="Recherche"
                value={query}
                onChange={(e) => onChangeQuery(e)}
                onKeyPress={(e) => searchOnEnter(e)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                select
                label="Tri"
                variant="outlined"
                value={sort}
                onChange={(e) => onChangeSort(e)}
              >
                <MenuItem value={"ASC"}>Croissant</MenuItem>
                <MenuItem value={"DESC"}>Décroissant</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <Button style={{ height: "56px" }} onClick={searchClients}>
                <SearchIcon />
              </Button>
            </Grid>
            <Grid style={{ paddingBottom: "10%" }}>
              <CircularProgress style={{ color: "#857A73" }} />
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  } else if (isReady === 2) {
    return (
      <div>
        <Container component="main" maxWidth="xl" className="divClients">
          <Typography
            component="h4"
            variant="h4"
            style={{ paddingBottom: "5%" }}
          >
            Liste des clients
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6} style={{ paddingBottom: "10%" }}>
              <TextField
                variant="outlined"
                label="Recherche"
                value={query}
                onChange={(e) => onChangeQuery(e)}
                onKeyPress={(e) => searchOnEnter(e)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                select
                label="Tri"
                variant="outlined"
                value={sort}
                onChange={(e) => onChangeSort(e)}
              >
                <MenuItem value={"ASC"}>Croissant</MenuItem>
                <MenuItem value={"DESC"}>Décroissant</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <Button style={{ height: "56px" }} onClick={searchClients}>
                <SearchIcon />
              </Button>
            </Grid>
            <Typography
              component="h5"
              variant="h5"
              style={{ paddingBottom: "20%" }}
            >
              Aucun résultat
            </Typography>
          </Grid>
        </Container>
      </div>
    );
  } else if (isReady === 3) {
    return (
      <div>
        <Container component="main" maxWidth="xl" className="divClients">
          <Typography
            component="h5"
            variant="h5"
            style={{ paddingBottom: "20%" }}
          >
            Pas de clients actuellement
          </Typography>
        </Container>
      </div>
    );
  } else {
    return (
      <div style={{ paddingTop: "50%" }}>
        <CircularProgress style={{ color: "#857A73" }} />
      </div>
    );
  }
}

export default Clients;
