import React from "react";
import "../../../css/Infos.css";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import icon from "../../../Icons/icons8-vip-24.png";

function InfoPlans() {
  // Affichage de la page
  return (
    <div>
      <Container component="main" maxWidth="xs" className="divInfos">
        <CssBaseline />
        <Typography component="h4" variant="h4">
          Information sur les abonnements
        </Typography>
        <br />
        <br />
        <Grid className="divInfo" container spacing={2}>
          <Grid item xs={12}>
            La plateforme CBS-Perfom vous propose d'obtenir un plan
            d'entraînement pour vous préparer à un marathon, à une course ou
            tout simplement pour vous remettre en forme. Vous avez le choix
            entre une version gratuite et un abonnement qui offre d'avantage de
            personnalisation.
          </Grid>
        </Grid>
        <br />
        <br />
        <Grid className="divInfo" container spacing={2}>
          <Grid item xs={3}>
            <MoneyOffIcon />
          </Grid>
          <Grid item xs={6}>
            <Typography style={{ "font-weight": "bold" }}>
              Abonnement gratuit
            </Typography>
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={12} style={{ paddingBottom: "10%" }}>
            Il s'agit de l'offre de base, totalement gratuite, destinée aux
            personnes souhaitant découvrir CBS Perform. Elle permet d'obtenir un
            plan d'entraînement selon vos choix.
          </Grid>
          <Grid item xs={3}>
            <img src={icon} alt={""} />
          </Grid>
          <Grid item xs={6}>
            <Typography style={{ "font-weight": "bold" }}>
              Abonnement VIP
            </Typography>
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={12}>
            Il s'agit de l'offre VIP, proposée au prix de ... CHF par mois.
            Cette offre comprend un plan d'entraînement totalement personnalisé
            et conçu directement par un coach spécialisé. Elle contient
            également des conseils de pro en tout genre.
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default InfoPlans;
