import React from "react";
import { Grid } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import "../../../../css/Request.css";

function RequestsMenu() {
  // Affichage de la page
  return (
    <div>
      <Container component={"main"} maxWidth={"xs"} className="divRequest">
        <Typography
          component="h1"
          variant="h3"
          style={{ paddingBottom: "15%" }}
        >
          Demandes des clients
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              style={{ backgroundColor: "#857A73" }}
              onClick={() => window.location.replace("/VipRequests")}
              variant={"contained"}
              fullWidth
              color={"primary"}
            >
              Demandes d'abonnement Vip
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              style={{ backgroundColor: "#857A73" }}
              onClick={() => window.location.replace("/VipPlanRequests")}
              variant={"contained"}
              fullWidth
              color={"primary"}
            >
              Demandes de plans Vip
            </Button>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default RequestsMenu;
