import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress, Grid, Button } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

Date.prototype.addWeeks = function (weeks) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + (7 * weeks));
    return date;
}

function DetailCliDefi() {
    const [isReady, setIsReady] = useState(-1);
    // Bien écrire les noms des champs comme dans la BDD (sensible à la CASSE)
    const [defi, setDefi] = useState({
        DEF_ID: null,
        DEF_NOM: "",
        DEF_DESCRIPTION: "",
        DEF_NBSEMAINES: 1,
        DEF_CONSEILS: "",
        DEF_VISIBLE: 1,
        DEF_DATE_VISIBLE: "2021-01-01",
        COA_ID: null,
    });

    const [objectifs, setObjectifs] = useState([]);

    let { defiID } = useParams();

    useEffect(() => {
        getDefiDetail();
        getObjectifs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDefiDetail = async () => {
        let requestURL = window.$apiURL.toString() + "defis/" + defiID;
        let requestOptions = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        };
        const responsePromise = await fetch(requestURL, requestOptions);
        const data = await responsePromise.json();
        setDefi(data);
        if (responsePromise.status === 404) {
            window.location.replace("/404");
        } else {
            setIsReady(1);
        }
    };

    const deinscriptionDefi = async () => {
        let requestURL = window.$apiURL.toString() + "desinscription/" + defiID;
        let requestOptions = {
            method: "PUT",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
            },
        };
        const responsePromise = await fetch(requestURL, requestOptions);
        await responsePromise.json();
        window.location.replace("/Defis");
    };

    const toggleJoker = async (par_id) => {
        let requestURL = window.$apiURL.toString() + "toggle_joker/" + par_id;
        let requestOptions = {
            method: "PUT",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
            },
        };
        await fetch(requestURL, requestOptions);
        await getObjectifs();
    }

    const objectifFait = async (par_id) => {
        let requestURL = window.$apiURL.toString() + "toggle_objectif/" + par_id;
        console.log(requestURL);
        let requestOptions = {
            method: "PUT",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
            },
        };
        await fetch(requestURL, requestOptions);
        await getObjectifs();
    }

    const getObjectifs = async () => {
        let requestURL = window.$apiURL.toString() + "objectifs_cli/" + defiID;

        let requestOptions = {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
            },
        };
        const responsePromise = await fetch(requestURL, requestOptions);
        const data = await responsePromise.json();

        setObjectifs(!!data.exception && [] || data);
    }

    if (isReady === -1) {
        return (
            <div style={{ paddingTop: "50%" }}>
                <CircularProgress style={{ color: "#857A73" }} />
            </div>
        );

    } else if (isReady === 1) {
        return (
            <div>
                <Container component="main" maxWidth="md" className="divClients">
                    <Typography
                        component="h4"
                        variant="h4"
                    >
                        {defi.DEF_NOM}
                    </Typography>
                    <Typography
                        variant="body1"
                        align="left"
                        style={{
                            paddingBottom: "1%",
                            paddingTop: "5%",
                            whiteSpace: "pre-wrap",
                        }}
                    >
                        Durée du défi: {defi.DEF_NBSEMAINES} semaines
                    </Typography>

                    <Typography
                        variant="body1"
                        align="left"
                        style={{
                            paddingBottom: "1%",
                            whiteSpace: "pre-wrap",
                        }}
                    >
                        Description du défi: {defi.DEF_DESCRIPTION}
                    </Typography>

                    <Typography
                        variant="body1"
                        align="left"
                        style={{
                            paddingBottom: "5%",
                            whiteSpace: "pre-wrap",
                        }}
                    >
                        Conseils pour le défi: {defi.DEF_CONSEILS}
                    </Typography>


                    <Grid container spacing={1}>
                        <Grid>
                            <Typography variant="body1" align="left">
                                Objectifs
                            </Typography>
                            <div>
                                {
                                    objectifs.length == 0 ? <p>Ce defi n'a pas d'objectifs</p> :
                                        <table style={{ width: "100%" }}>

                                            <th>Date</th>
                                            <th>Nom</th>
                                            <th>Points</th>
                                            <th>Description</th>
                                            <th>Conseils</th>
                                            <th>Joker</th>
                                            <th>Fait</th>

                                            <tbody>
                                                {objectifs.map(obj => {
                                                    return (
                                                        <tr key={obj.OBJ_ID}>
                                                            <td>{new Date(obj.PAR_DEBUT).addDays(obj.OBJ_JOUR - 1).addWeeks(obj.OBJ_SEMAINE - 1).toLocaleDateString()}</td>
                                                            <td>{obj.OBJ_NOM}</td>
                                                            <td>{obj.OBJ_POINTS}</td>
                                                            <td>{obj.OBJ_DESCRIPTION}</td>
                                                            <td>{obj.OBJ_CONSEILS}</td>
                                                            <td>
                                                                <Button
                                                                    onClick={(e) => toggleJoker(obj.PAR_ID)}
                                                                    style={{ backgroundColor: "#857A73" }}
                                                                    variant={"contained"}
                                                                    color={"primary"}
                                                                    disabled={obj.OBJ_JOKER == 0}>
                                                                    {!!obj.OBJ_JOKER ? <>Annuler</> : <>Utiliser</>}
                                                                </Button>
                                                            </td>
                                                            <td><Button
                                                                onClick={(e) => objectifFait(obj.PAR_ID, obj.OBJ_ID)}
                                                                style={{ backgroundColor: "#857A73" }}
                                                                variant={"contained"}
                                                                color={"primary"}>
                                                                {!!obj.ACC_FAIT ? <>Annuler</> : <>Fait</>}
                                                            </Button> </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                }
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                onClick={deinscriptionDefi}
                                style={{ backgroundColor: "#857A73", marginTop: "5%" }}
                                variant={"contained"}
                                color={"primary"}
                            >
                                Désincription
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </div >
        );
    } else {
        return (
            <div style={{ paddingTop: "50%" }}>
                <CircularProgress style={{ color: "#857A73" }} />
            </div>
        );
    }
}
export default DetailCliDefi;
