import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import "../../../css/Profile.css";
import Typography from "@material-ui/core/Typography";
import { Grid, MenuItem, TextField, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { fr } from "date-fns/locale";
import CircularProgress from "@material-ui/core/CircularProgress";

function Profile() {
  const [cli_isSet, setCli_isSet] = useState(0);
  const [cli_nom, setCli_nom] = useState("");
  const [cli_nomErrorText, setCli_nomErrorText] = useState("");
  const [cli_nomHasError, setCli_nomHasError] = useState(false);

  const [Cli_prenom, setCli_prenom] = useState("");
  const [cli_prenomErrorText, setCli_prenomErrorText] = useState("");
  const [cli_prenomHasError, setCli_prenomHasError] = useState(false);

  const [email, setEmail] = useState("");
  const [emailErrorText, setEmailErrorText] = useState("");
  const [emailHasError, setEmailHasError] = useState(false);

  const [cli_pseudo, setCli_pseudo] = useState();
  const [cli_pseudolErrorText, setCli_pseudoErrorText] = useState("");
  const [cli_pseudolHasError, setCli_pseudoHasError] = useState(false);

  const [cli_abonnement, setCli_abonnement] = useState("");
  const [cli_abonnement_number, setCli_abonnement_number] = useState("");
  const [isVip, setIsVip] = useState(false);

  const [cli_niveau, setCli_niveau] = useState("");
  const [cli_poids, setCli_poids] = useState("");
  const [cli_taille, setCli_taille] = useState("");

  const [cli_dob, setCli_dob] = useState("");
  const [cli_dobIsSet, setCli_dobIsSet] = useState(false);
  const [cli_dobHasError, setCli_dobHasError] = useState(false);
  const [cli_dobErrorText, setCli_dobErrorText] = useState("");

  const [modifyProfile, setModifyProfile] = useState(false);

  const [saveChangesButton, setSaveChangesButton] = useState(
    "Enregistrer les modifications"
  );

  // Permet de retourner l'année maximum sélectionnable par l'utilisateur
  function getMaxDate() {
    let newDate = new Date();
    newDate.setFullYear(newDate.getFullYear() - 18);
    return newDate.toISOString().substring(0, 10);
  }
  // Permet rendre les champs du profil éditables
  function setModify() {
    setModifyProfile(true);
  }

  //Permet de stocker les changements du champs cli_nom
  const onChangeCli_nom = (e) => {
    setCli_nom(e.target.value);
    setCli_nomHasError(false);
  };
  //Permet de stocker les changements du champs cli_prenom
  const onChangeCli_prenom = (e) => {
    setCli_prenom(e.target.value);
    setCli_prenomHasError(false);
  };
  //Permet de stocker les changements du champs email
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    setEmailHasError(false);
  };
  //Permet de stocker les changements du champs cli_dob (date de naissance)
  const onChangeCli_dob = (e) => {
    setCli_dobIsSet(true);
    setCli_dob(e);
    setCli_dobHasError(false);
  };
  //Permet de stocker les changements du champs cli_pseudo
  const onChangeCli_pseudo = (e) => {
    setCli_pseudo(e.target.value);
    setCli_pseudoHasError(false);
  };
  //Permet de stocker les changements du champs cli_taille
  const onChangeCli_taille = (e) => {};
  //Permet de stocker les changements du champs cli_niveau
  const onChangeCli_niveau = (e) => {
    setCli_niveau(e.target.value);
  };
  //Permet de stocker les changements du champs cli_poids
  const onChangeCli_poids = (e) => {};

  // Vérifie que les champs obligatoires sont bien remplis et correctement
  function validate() {
    let errors = false;
    if (cli_nom.length === 0) {
      errors = true;
      setCli_nomHasError(true);
      setCli_nomErrorText("Le nom de famille doit être rempli !");
    }
    if (Cli_prenom.length === 0) {
      errors = true;
      setCli_prenomHasError(true);
      setCli_prenomErrorText("Le prénom doit être rempli !");
    }
    if (
      !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
        email.toString()
      )
    ) {
      errors = true;
      setEmailHasError(true);
      setEmailErrorText("Le format de l'email n'est pas correct !");
    }
    if (email.length === 0) {
      errors = true;
      setEmailHasError(true);
      setEmailErrorText("Email doit être rempli !");
    }
    if (
      new Date(cli_dob).toISOString() < new Date("1900-01-01").toISOString() ||
      new Date(cli_dob).toISOString() > new Date(getMaxDate()).toISOString()
    ) {
      errors = true;
      setCli_dobHasError(true);
      setCli_dobErrorText("La date entrée n'est pas valable !");
    }
    return errors;
  }

  //Permet de sauver les modifications dans la base de données
  const saveChanges = async (e) => {
    e.preventDefault();
    const errors = validate();
    if (!errors) {
      setSaveChangesButton(
        <CircularProgress size={25} style={{ color: "white" }} />
      );
      let updateProfileformData = document.getElementById("updateForm");
      let formData = new FormData(updateProfileformData);
      if (cli_dobIsSet) {
        let date = new Date(cli_dob);
        formData.set("cli_dob", date.toISOString().substring(0, 10));
      } else {
        formData.delete("cli_dob");
      }
      if (formData.get("cli_pseudo") === "") {
        formData.delete("cli_pseudo");
      }
      let object = {};
      formData.forEach(function (value, key) {
        object[key] = value;
      });
      let jsonBody = JSON.stringify(object);
      let errors = validate();
      if (!errors) {
        e.preventDefault();
        let requestURL = window.$apiURL.toString() + "users";
        let requestOptions = {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + localStorage.getItem("cbs_perform_token"),
          },
          body: jsonBody,
        };
        const responsePromise = await fetch(requestURL, requestOptions);
        const data = await responsePromise.json();
        if (!responsePromise.ok) {
          setCli_pseudoHasError(true);
          setCli_pseudoErrorText(data);
        } else {
          window.location.replace("/profile");
        }
      }
    }
  };

  // Permet de récupérer les informations de l'utilisateur connecté
  const getUser = async () => {
    let requestURL = window.$apiURL.toString() + "users";
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    setCli_nom(data.cli_nom);
    setCli_prenom(data.cli_prenom);
    setEmail(data.email);
    setCli_dob(data.cli_dob);
    setCli_pseudo(data.cli_pseudo);
    if (data.cli_abonnement === 0 || data.cli_abonnement === 3) {
      setCli_abonnement_number(data.cli_abonnement);
      setCli_abonnement("Abonnement gratuit");
    } else if (data.cli_abonnement === 2) {
      setIsVip(true);
      setCli_abonnement("Abonnement VIP");
      setCli_taille(data.cli_taille);
      setCli_poids(data.cli_poids);
      setCli_niveau(data.cli_niveau);
    }
    setCli_isSet(1);
  };

  useEffect(() => {
    getUser({});
  }, []);

  if (cli_isSet === 0) {
    return (
      <Container
        component="main"
        maxWidth="xs"
        className="divProfile"
        style={{ paddingTop: "10%", paddingBottom: "10%" }}
      >
        <CircularProgress style={{ color: "#857A73" }} />
      </Container>
    );
  } else if (cli_isSet === 1) {
    return (
      <div>
        <Container component="main" maxWidth="xs" className="divProfile">
          <CssBaseline />
          <Typography component="h1" variant="h3">
            Votre profil
          </Typography>
          <br />
          <br />
          <form id="updateForm" autoComplete="on">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  disabled={!modifyProfile}
                  autoComplete="lName"
                  name="cli_nom"
                  value={cli_nom}
                  helperText={
                    cli_nomHasError ? cli_nomErrorText : "Nom de Famille"
                  }
                  error={cli_nomHasError}
                  onChange={(e) => onChangeCli_nom(e)}
                  required
                  fullWidth
                  id="lastName"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={!modifyProfile}
                  variant="outlined"
                  onChange={(e) => onChangeCli_prenom(e)}
                  autoComplete="fName"
                  name="cli_prenom"
                  value={Cli_prenom}
                  required
                  helperText={
                    cli_prenomHasError ? cli_prenomErrorText : "Prénom"
                  }
                  error={cli_prenomHasError}
                  fullWidth
                  id="firstName"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={!modifyProfile}
                  variant="outlined"
                  required
                  fullWidth
                  value={email}
                  onChange={(e) => onChangeEmail(e)}
                  helperText={emailHasError ? emailErrorText : "Email"}
                  error={emailHasError}
                  id="email"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={!modifyProfile}
                  variant="outlined"
                  onChange={(e) => onChangeCli_pseudo(e)}
                  name="cli_pseudo"
                  value={cli_pseudo}
                  helperText={
                    cli_pseudolHasError ? cli_pseudolErrorText : "Pseudo"
                  }
                  error={cli_pseudolHasError}
                  fullWidth
                  id="firstName"
                />
              </Grid>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
                  <KeyboardDatePicker
                    PopoverProps={{
                      disableScrollLock: true,
                    }}
                    autoOk
                    disabled={!modifyProfile}
                    inputVariant="outlined"
                    variant="inline"
                    onChange={(e) => onChangeCli_dob(e)}
                    error={cli_dobHasError}
                    helperText={
                      cli_dobHasError ? cli_dobErrorText : "Date de naissance"
                    }
                    value={cli_dob === "" ? "" : cli_dob}
                    minDate="1900-01-01"
                    maxDate={getMaxDate()}
                    name="cli_dob"
                    required
                    defaultValue=""
                    format={"dd/MM/yyyy"}
                    InputAdornmentProps={{ position: "start" }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={!modifyProfile}
                  variant="outlined"
                  value={cli_abonnement}
                  helperText={"Votre abonnement actuel"}
                  fullWidth
                  id="firstName"
                />
              </Grid>
              <Grid item xs={6} hidden={!isVip}>
                <TextField
                  type="number"
                  disabled={!modifyProfile}
                  variant="outlined"
                  onChange={(e) => onChangeCli_taille(e)}
                  value={cli_taille}
                  helperText={"Votre taille"}
                  fullWidth
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 3);
                  }}
                  inputProps={{
                    min: 100,
                    max: 250,
                    step: 1,
                    style: { textAlign: "center" },
                  }}
                  name="cli_taille"
                />
              </Grid>
              <Grid item xs={6} hidden={!isVip}>
                <TextField
                  type="number"
                  disabled={!modifyProfile}
                  variant="outlined"
                  onChange={(e) => onChangeCli_poids(e)}
                  value={cli_poids}
                  helperText={"Votre poids"}
                  fullWidth
                  name="cli_poids"
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 3);
                  }}
                  inputProps={{
                    min: 50,
                    max: 300,
                    step: 1,
                    style: { textAlign: "center" },
                  }}
                />
              </Grid>
              <Grid item xs={12} hidden={!isVip}>
                <Button
                  style={{ backgroundColor: "#857A73" }}
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => window.location.replace("/mescourses")}
                >
                  Mes courses
                </Button>
              </Grid>
              <Grid item xs={12}>
                {!modifyProfile ? (
                  <Button
                    style={{ backgroundColor: "#857A73" }}
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={setModify}
                  >
                    {" "}
                    Modifier le Profil
                  </Button>
                ) : (
                  <Button
                    style={{ backgroundColor: "#857A73" }}
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={(e) => saveChanges(e)}
                  >
                    {" "}
                    {saveChangesButton}
                  </Button>
                )}
              </Grid>
              {modifyProfile ? (
                <Grid item xs={12}>
                  <Button
                    style={{ backgroundColor: "#857A73" }}
                    variant={"contained"}
                    color={"primary"}
                    fullWidth
                    onClick={() => window.location.replace("/profile")}
                  >
                    Annuler
                  </Button>
                </Grid>
              ) : (
                ""
              )}
              <Grid item xs={12}>
                <Link to="/changepassword" style={{ textDecoration: "none" }}>
                  <Button
                    fullWidth
                    style={{ backgroundColor: "#857A73" }}
                    variant="contained"
                    color="primary"
                  >
                    {" "}
                    Changer le mot de passe
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12}>
                {!isVip ? (
                  [
                    cli_abonnement_number === 0 ? (
                      <Link
                        to="/ChangeSubscription"
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          style={{ backgroundColor: "#D2BF67" }}
                          fullWidth
                          variant="contained"
                          color="primary"
                        >
                          {" "}
                          Passer à l'abonnement VIP
                        </Button>
                      </Link>
                    ) : (
                      <Link
                        to="/CheckSubscriptionStatus"
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          style={{ backgroundColor: "#D2BF67" }}
                          fullWidth
                          variant="contained"
                          color="primary"
                        >
                          {" "}
                          Suivi de votre demande
                        </Button>
                      </Link>
                    ),
                  ]
                ) : (
                  <Link
                    to="/ChangeSubscription"
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      fullWidth
                      style={{ backgroundColor: "#f44336" }}
                      variant="contained"
                      color="primary"
                    >
                      {" "}
                      Repasser à l'abonnement gratuit
                    </Button>
                  </Link>
                )}
              </Grid>
              <Grid item xs={12}>
                <Link to="/factures" style={{ textDecoration: "none" }}>
                  <Button
                    style={{ backgroundColor: "#857A73" }}
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    {" "}
                    Vos factures
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </form>
        </Container>
      </div>
    );
  }
}

export default Profile;
