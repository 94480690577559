import React, { useState } from "react";
import { sha256 } from "js-sha256";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { Grid, TextField } from "@material-ui/core";
import Container from "@material-ui/core/Container/Container";
import Button from "@material-ui/core/Button";
import "../../../css/Profile.css";

function NewPassword() {
  const [email, setEmail] = useState("");
  const [emailErrorText, setEmailErrorText] = useState("");
  const [emailHasError, setEmailHasError] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [newPasswordErrorText, setNewPasswordErrorText] = useState("");
  const [newPasswordHasError, setNewPasswordHasError] = useState(false);
  const [passwordChangeResultText, setPasswordChangeResultText] = useState("");

  // Permet de stocker les changements du champ email
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    setEmailHasError(false);
  };

  // Permet de stocker les changements du champ nouveau password
  const onChangeNewPassword = (e) => {
    setNewPassword(e.target.value);
    setNewPasswordHasError(false);
  };

  // Permet de stocker les changements du champ confirmation nouveau password
  const onChangeNewPasswordConfirmation = (e) => {
    setNewPasswordConfirmation(e.target.value);
    setNewPasswordHasError(false);
  };

  // Permet de vérifier que les champs sont correctement rempli
  function validate() {
    let errors = false;
    if (
      !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
        email.toString()
      )
    ) {
      errors = true;
      setEmailHasError(true);
      setEmailErrorText("Le format de l'email n'est pas correct !");
    }
    if (email.length === 0) {
      errors = true;
      setEmailHasError(true);
      setEmailErrorText("Email doit être rempli !");
    }
    if (newPassword.length === 0 || newPasswordConfirmation.length === 0) {
      errors = true;
      setNewPasswordHasError(true);
      setNewPasswordErrorText("Vous devez entrer un nouveau mot de passe !");
    }
    if (newPassword !== newPasswordConfirmation) {
      errors = true;
      setNewPasswordHasError(true);
      setNewPasswordErrorText("Les mots de passe ne correspondent pas !");
    }
    return errors;
  }

  // Permet de récupérer le paramètre token
  function getToken() {
    let url = new URL(window.location.href.toString());
    return url.searchParams.get("token");
  }

  // Permet de sauvegarder la réinitialisation du mot de page
  const resetPassword = async (e) => {
    e.preventDefault();
    if (!validate()) {
      e.preventDefault();
      let newPasswordFormData = document.getElementById("newPasswordForm");
      let formData = new FormData(newPasswordFormData);
      //formData.delete('email')
      formData.set("token", getToken());
      formData.set("password", sha256(newPassword));
      formData.set("password_confirmation", sha256(newPasswordConfirmation));
      let requestURL = window.$apiURL.toString() + "password/reset";
      let requestOptions = {
        crossDomain: true,
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: formData,
      };
      const responsePromise = await fetch(requestURL, requestOptions);
      const data = await responsePromise.json();
      if (responsePromise.ok) {
        setPasswordChangeResultText(data.message);
        setTimeout(function () {
          window.location.replace("/Login");
        }, 1500);
      } else {
        setPasswordChangeResultText(data.error);
      }
    }
  };

  // Affichage de la page
  return (
    <div>
      <Container component="main" maxWidth="xs" className="divProfile">
        <CssBaseline />
        <Typography component="h1" variant="h3">
          Changement de mot de passe
        </Typography>
        <br />
        <Typography component="h3" variant="h6" style={{ minHeight: "32px" }}>
          {passwordChangeResultText}
        </Typography>
        <br />
        <form id="newPasswordForm" autoComplete>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                onChange={(e) => onChangeEmail(e)}
                helperText={emailHasError ? emailErrorText : ""}
                error={emailHasError}
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                helperText={newPasswordHasError ? newPasswordErrorText : ""}
                error={newPasswordHasError}
                onChange={(e) => onChangeNewPassword(e)}
                name="password"
                label="Nouveau mot de passe"
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                helperText={newPasswordHasError ? newPasswordErrorText : ""}
                error={newPasswordHasError}
                onChange={(e) => onChangeNewPasswordConfirmation(e)}
                name="password_confirmation"
                label="Confirmation du nouveau mot de passe"
                type="password"
              />
            </Grid>
            <br />
            <Grid item xs={12}>
              <Button
                style={{ backgroundColor: "#857A73" }}
                type="submit"
                fullWidth
                onClick={(e) => resetPassword(e)}
                variant="contained"
                color="primary"
              >
                {" "}
                Changer le mot de passe
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
}

export default NewPassword;
