import React from "react";
import HomeIcon from "@material-ui/icons/Home";
import { Component } from "react";
import { IconButton, Toolbar } from "material-ui";
import MenuIcon from "@material-ui/icons/Menu";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import { sha256 } from "js-sha256";
import { NavDrawer } from "./NavDrawer";
import PersonIcon from "@material-ui/icons/Person";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      drawerOpened: false,
    };
    this.state.menuStyles = {
      color: "white",
      textDecoration: "none",
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    if (window.innerWidth < "700") {
      this.setState({ isMobile: true });
    } else if (window.innerWidth >= 480) {
      this.setState({ isMobile: false });
    }
  }

  // Permet de déconnecter l'utilisateur de la plateforme
  logoutHandler() {
    let URL = window.$apiURL;
    let rqst = URL + "logout";
    fetch(rqst, {
      crossDomain: true,
      method: "POST",
    }).then((response) => {
      localStorage.removeItem("cbs_perform_token");
      localStorage.removeItem("cbs_perform_token_is_coach");
      localStorage.removeItem("cbs_perform_token_is_super_admin");
      window.location.replace("/");
    });
  }

  toggleDrawer = (booleanValue) => () => {
    this.setState({
      drawerOpened: booleanValue,
    });
  };

  render() {
    if (this.state.isMobile) {
      return (
        <MuiThemeProvider>
          <Toolbar style={{ background: "#181001", padding: 0 }}>
            <IconButton
              style={{
                color: "white",
                paddingTop: "16px",
                paddingBottom: "16px",
                paddingLeft: "20px",
              }}
              aria-label="Menu"
              onClick={this.toggleDrawer(true)}
            >
              <MenuIcon style={{ background: "white" }} />
            </IconButton>
          </Toolbar>
          <NavDrawer
            drawerOpened={this.state.drawerOpened}
            toggleDrawer={this.toggleDrawer}
          />
        </MuiThemeProvider>
      );
    } else if (!this.state.isMobile) {
      if (localStorage.getItem("cbs_perform_token") !== null) {
        if (localStorage.getItem("cbs_perform_token_is_coach") !== null) {
          if (
            localStorage.getItem("cbs_perform_token_is_coach") ===
            sha256(
              localStorage.getItem("cbs_perform_token").substring(162, 243)
            )
          ) {
            if (
              localStorage.getItem("cbs_perform_token_is_super_admin") !==
                null &&
              localStorage.getItem("cbs_perform_token_is_super_admin") ===
                sha256(
                  localStorage.getItem("cbs_perform_token").substring(606, 750)
                )
            ) {
              //connected superadmin coach menu
              return (
                <MuiThemeProvider>
                  <AppBar position="static">
                    <Toolbar style={{ backgroundColor: "#181001" }}>
                      <Button
                        onClick={() => (window.location.href = "/")}
                        color="inherit"
                      >
                        <HomeIcon />
                      </Button>
                      <Button
                        onClick={() => (window.location.href = "/ProfileCoach")}
                        color="inherit"
                      >
                        Profil
                      </Button>
                      <Button
                        onClick={() => (window.location.href = "/Clients")}
                        color="inherit"
                      >
                        Clients
                      </Button>
                      <Button
                        onClick={() => (window.location.href = "/Requests")}
                        color="inherit"
                      >
                        Demandes
                      </Button>
                      <Button
                        onClick={() => (window.location.href = "/management")}
                        color="inherit"
                      >
                        Admin
                      </Button>
                      <Button onClick={this.logoutHandler} color="inherit">
                        Logout
                      </Button>
                    </Toolbar>
                  </AppBar>
                </MuiThemeProvider>
              );
            } else {
              return (
                //connected coach menu
                <MuiThemeProvider>
                  <AppBar position="static">
                    <Toolbar style={{ backgroundColor: "#181001" }}>
                      <Button
                        onClick={() => (window.location.href = "/")}
                        color="inherit"
                      >
                        <HomeIcon />
                      </Button>
                      <Button
                        onClick={() => (window.location.href = "/ProfileCoach")}
                        color="inherit"
                      >
                        Profil
                      </Button>
                      <Button
                        onClick={() => (window.location.href = "/Clients")}
                        color="inherit"
                      >
                        Clients
                      </Button>
                      <Button
                        onClick={() => (window.location.href = "/Requests")}
                        color="inherit"
                      >
                        Demandes
                      </Button>
                      <Button
                        onClick={() => (window.location.href = "/")}
                        color="inherit"
                      ></Button>
                      <Button onClick={this.logoutHandler} color="inherit">
                        Logout
                      </Button>
                    </Toolbar>
                  </AppBar>
                </MuiThemeProvider>
              );
            }
          } else {
            return (
              //unvalidated coach menu
              <MuiThemeProvider>
                <AppBar position="static">
                  <Toolbar style={{ backgroundColor: "#181001" }}>
                    <Button
                      onClick={() => (window.location.href = "/")}
                      color="inherit"
                    >
                      <HomeIcon />
                    </Button>
                    <Button
                      onClick={() => (window.location.href = "/Plannings")}
                      color="inherit"
                    >
                      Plans
                    </Button>
                    <Button
                      onClick={() => (window.location.href = "/Contact")}
                      color="inherit"
                    >
                      Contact
                    </Button>
                    <Button
                      onClick={() => (window.location.href = "/FAQ")}
                      color="inherit"
                    >
                      FAQ
                    </Button>
                    <Button disabled color="inherit"></Button>
                    <Button
                      onClick={() => (window.location.href = "/Profile")}
                      color="inherit"
                    >
                      <PersonIcon />
                    </Button>
                    <Button onClick={this.logoutHandler} color="inherit">
                      Déconnexion
                    </Button>
                  </Toolbar>
                </AppBar>
              </MuiThemeProvider>
            );
          }
        } else {
          return (
            //logged user menu
            <MuiThemeProvider>
              <AppBar position="static">
                <Toolbar style={{ backgroundColor: "#181001" }}>
                  <Button
                    onClick={() => (window.location.href = "/")}
                    color="inherit"
                  >
                    <HomeIcon />
                  </Button>
                  <Button
                    onClick={() => (window.location.href = "/Plannings")}
                    color="inherit"
                  >
                    Plans
                  </Button>
                  <Button
                    onClick={() => (window.location.href = "/Contact")}
                    color="inherit"
                  >
                    Contact
                  </Button>
                  <Button
                    onClick={() => (window.location.href = "/FAQ")}
                    color="inherit"
                  >
                    FAQ
                  </Button>
                  <Button disabled color="inherit"></Button>
                  <Button
                    onClick={() => (window.location.href = "/Profile")}
                    color="inherit"
                  >
                    <PersonIcon />
                  </Button>
                  <Button onClick={this.logoutHandler} color="inherit">
                    Déconnexion
                  </Button>
                </Toolbar>
              </AppBar>
            </MuiThemeProvider>
          );
        }
      } else {
        return (
          //not logged user menu
          <MuiThemeProvider>
            <AppBar position="static">
              <Toolbar style={{ backgroundColor: "#181001" }}>
                <Button
                  onClick={() => (window.location.href = "/")}
                  color="inherit"
                >
                  <HomeIcon />
                </Button>
                <Button
                  onClick={() => (window.location.href = "/Register")}
                  color="inherit"
                >
                  Inscription
                </Button>
                <Button
                  onClick={() => (window.location.href = "/InfoPlans")}
                  color="inherit"
                >
                  Abonnements
                </Button>
                <Button
                  onClick={() => (window.location.href = "/Contact")}
                  color="inherit"
                >
                  Contact
                </Button>
                <Button
                  onClick={() => (window.location.href = "/FAQ")}
                  color="inherit"
                >
                  FAQ
                </Button>
                <Button disabled color="inherit" />
                <Button disabled color="inherit" />
                <Button
                  onClick={() => (window.location.href = "/Login")}
                  color="inherit"
                >
                  Connexion
                </Button>
              </Toolbar>
            </AppBar>
          </MuiThemeProvider>
        );
      }
    }
  }
}

export default Menu;
