import React from 'react';
import {Link} from "react-router-dom";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import {Button, Grid} from "@material-ui/core";
import '../../../css/Profile.css';

function SubscriptionRequestSubmitted() {

    return (
        <div>
            <Container component="main" maxWidth="xs" className="divProfile">
                <CssBaseline/>
                <Typography className="body" variant="body1" component="h5" style={{paddingBottom : '20%', paddingTop : '15%'}}>
                    Votre demande à bien été transmise au coach. Vous recevrez une réponse sous quelques jours!
                </Typography>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        <Link to="/" style={{textDecoration : 'none'}}>
                            <Button
                                style={{backgroundColor : '#857A73'}}
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                            >  Retour à l'accueil
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default SubscriptionRequestSubmitted;