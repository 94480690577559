import React, { useEffect, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import { useParams } from "react-router-dom";
import "../../../../css/Request.css";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";

function RequestDetail() {
  const [isReady, setIsReady] = useState(-1);
  const [exchanges, setExchanges] = useState({});
  const [requestType, setRequestType] = useState("");
  const [userId, setUserId] = useState("");

  const [coa_isSuperAdmin, setCoaIsSuperAdmin] = useState(false);

  let { id_request } = useParams();

  useEffect(() => {
    getCoach();
    getExchanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Permet d'obtenir les informations du coach connecté et determiner s'il s'agit d'un super admin ou non
  const getCoach = async () => {
    let requestURL = window.$apiURL.toString() + "coachs";
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    if (data.isSuperAdmin === 1) {
      setCoaIsSuperAdmin(true);
    }
  };

  // Permet d'obtenir les échanges entre le client et le coach
  const getExchanges = async () => {
    let requestURL =
      window.$apiURL.toString() + "coachs/exchanges/request/" + id_request;
    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();
    if (responsePromise.ok) {
      setRequestType(data[0].subject);
      setUserId(data[0].cli_id);
      setExchanges(data);
      setIsReady(1);
    } else if (responsePromise.status === 404) {
      window.location.replace("/404");
    }
  };

  // Permet la mise en forme des messages échangés entre le client et le coach
  // eslint-disable-next-line array-callback-return
  const commentsList = Object.keys(exchanges).map((key) => {
    if (exchanges[key].from === "coach") {
      return (
        <Grid container spacing={2} key={key}>
          <Grid item xs={12} style={{ textAlign: "right", fontSize: "12px" }}>
            Vous {new Date(exchanges[key].created_at).toLocaleString("fr")}
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={9} style={{ textAlign: "right", paddingTop: 0 }}>
            <Box
              style={{
                maxWidth: "fit_content",
                backgroundColor: "#cfd8dc",
                borderRadius: 5,
                textAlign: "left",
                margin: 0,
              }}
            >
              <p style={{ padding: "10px", margin: 0 }}>
                {exchanges[key].message}
              </p>
            </Box>
          </Grid>
        </Grid>
      );
    } else if (exchanges[key].from === "user") {
      return (
        <Grid container spacing={2} key={key}>
          <Grid item xs={12} style={{ textAlign: "left", fontSize: "12px" }}>
            Utilisateur : {exchanges[key].cli_nom} {exchanges[key].cli_prenom}{" "}
            {new Date(exchanges[key].created_at).toLocaleString("fr")}
          </Grid>

          <Grid item xs={9} style={{ textAlign: "right", paddingTop: 0 }}>
            <Box
              style={{
                maxWidth: "fit_content",
                backgroundColor: "#eeeeee",
                borderRadius: 5,
                textAlign: "left",
                margin: 0,
              }}
            >
              <p style={{ padding: "10px", margin: 0 }}>
                {exchanges[key].message}
              </p>
            </Box>
          </Grid>
          <Grid item xs={3} />
        </Grid>
      );
    }
  });
  const [comment, setComment] = useState("");
  const [commentError, setCommentError] = useState("");

  // Permet de stocker un nouveau message
  const onChangeComment = (e) => {
    setComment(e.target.value);
    setCommentError("");
  };

  // Permet de vérifier qu'un message a bien été écrit avant l'envoi
  function validate() {
    let errors = false;
    if (comment.length === 0) {
      errors = true;
      setCommentError("Veuillez saisir un commentaire");
    }
    return errors;
  }

  // Permet de sauvegarder le nouveau message dans la base de donnée
  const handleNewMessage = async () => {
    let errors = validate();
    if (!errors) {
      let subscriptionChangeFormData = new FormData();
      subscriptionChangeFormData.set("uce_id", id_request);
      subscriptionChangeFormData.set("message", comment);
      let requestURL =
        window.$apiURL.toString() + "coachs/exchanges/send_message_to_user";
      let requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
        },
        body: subscriptionChangeFormData,
      };
      const responsePromise = await fetch(requestURL, requestOptions);
      if (responsePromise.ok) {
        window.location.replace("/RequestDetail/" + id_request);
      }
    }
  };

  const [open, setOpen] = React.useState(false);

  // Permet d'ouvrir une boite de dialogue
  const handleDialogOpen = () => {
    setOpen(true);
  };

  // Permet de fermer la boite de dialogue
  const handleDialogClose = () => {
    setOpen(false);
  };

  // Permet de sauvegarder la suppression de la demande d'abonnement VIP d'un utilisateur
  const handleDeleteVipRequest = async () => {
    let requestURL =
      window.$apiURL.toString() +
      "coachs/cancel_subscription_request/" +
      id_request;
    let requestOptions = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
      },
    };
    const responsePromise = await fetch(requestURL, requestOptions);
    const data = await responsePromise.json();

    if (responsePromise.ok) {
      window.location.replace("/Requests");
    }
  };

  const [paymentDetail, setPaymentDetail] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("0");
  const [paymentMethod, setPaymentMethod] = useState("");

  const [paymentDetailError, setPaymentDetailError] = useState("");
  const [paymentAmountError, setPaymentAmountError] = useState("");
  const [paymentMethodError, setPaymentMethodError] = useState("");

  // Permet de stocker les changements du champs détail facture
  const onChangeDetailFacture = (e) => {
    setPaymentDetail(e.target.value);
    setPaymentDetailError("");
  };

  // Permet de stocker les changements du champs montant de la facture
  const onChangeSubscriptionAmount = (e) => {
    setPaymentAmount(e.target.value);
    setPaymentAmountError("");
  };

  // Permet de stocker les changements du champs méthode de paiement
  const onChangePaymentMethod = (e) => {
    setPaymentMethod(e.target.value);
    setPaymentMethodError("");
  };

  // Permet de vérifier que les différents champs sont bien remplis
  function validateFacture() {
    let errors = false;
    if (paymentDetail.length === 0) {
      errors = true;
      setPaymentDetailError("Veuillez saisir le détail");
    }
    if (paymentAmount < 0) {
      errors = true;
      setPaymentAmountError("Veuillez saisir le montant");
    }
    if (paymentMethod.length === 0) {
      errors = true;
      setPaymentMethodError("Veuillez choisir la méthode de paiement");
    }
    return errors;
  }

  // Permet de sauvegarder l'acceptation du passage VIP d'un client dans la base de donnée en lui attribuant également un coach_id
  const handleConfirmVip = async () => {
    let error = validateFacture();
    if (!error) {
      let formData = new FormData();
      formData.set("cli_id", userId);
      formData.set("detail", paymentDetail);
      formData.set("montant", paymentAmount);
      formData.set("type", paymentMethod);
      let requestURLFacture =
        window.$apiURL.toString() + "payments/coach/add/" + userId;
      let requestOptionsFacture = {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
        },
        body: formData,
      };
      const responsePromiseFacture = await fetch(
        requestURLFacture,
        requestOptionsFacture
      );
      const data_facture = await responsePromiseFacture.json();

      let formDataFaUCE = new FormData();
      formDataFaUCE.set("subject", "payment");
      formDataFaUCE.set("cli_id", userId);
      formDataFaUCE.set("pay_id", data_facture.id);

      //Ajout de la facture dans un UCE
      let requestURLFactureUCE =
        window.$apiURL.toString() + "coachs/exchanges/facture";
      let requestOptionsFactureUCE = {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("cbs_perform_token"),
        },
        body: formDataFaUCE,
      };
      const responsePromiseFactureUCE = await fetch(
        requestURLFactureUCE,
        requestOptionsFactureUCE
      );

      if (responsePromiseFactureUCE.ok) {
        //window.location.replace("/Requests")
        let object = {};
        formData.forEach(function (value, key) {
          object[key] = value;
        });
        let jsonBody = JSON.stringify(object);
        let requestURL =
          window.$apiURL.toString() + "coachs/accept_vip_subscription";
        let requestOptions = {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + localStorage.getItem("cbs_perform_token"),
          },
          body: jsonBody,
        };
        const responsePromise = await fetch(requestURL, requestOptions);
        if (responsePromise.ok) {
          window.location.replace("/Requests");
        }
      }
    }
  };

  // Affichage de la page
  if (isReady === -1) {
    return (
      <div style={{ paddingTop: "50%" }}>
        <CircularProgress style={{ color: "#857A73" }} />
      </div>
    );
  } else if (isReady === 1) {
    if (requestType === "vip_subscription") {
      return (
        <div>
          <Container component="main" maxWidth="xs" className="divRequest">
            <Typography
              component="h3"
              variant="h5"
              style={{ paddingBottom: "20%" }}
            >
              Demandes VIP n° {id_request}
            </Typography>
            <div
              style={{
                height: "40vh",
                width: "100%",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  overflow: "scroll",
                  overflowX: "hidden",
                  height: "100%",
                }}
              >
                {commentsList}
              </div>
            </div>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                style={{ paddingTop: "15%", textAlign: "left" }}
              >
                Nouveau message
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: "10%" }}>
                <TextField
                  variant={"outlined"}
                  multiline
                  rows={4}
                  name={"userComments"}
                  onChange={(e) => onChangeComment(e)}
                  error={commentError !== ""}
                  helperText={commentError !== "" ? commentError : ""}
                />
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: "10%" }}>
                <TextField
                  label={"Détail de la facture"}
                  value={paymentDetail}
                  variant={"outlined"}
                  onChange={(e) => onChangeDetailFacture(e)}
                  helperText={
                    paymentDetailError !== "" ? paymentDetailError : ""
                  }
                  error={paymentDetailError !== ""}
                />
              </Grid>
              <Grid item xs={6} style={{ paddingBottom: "10%" }}>
                <TextField
                  label={"Montant"}
                  type={"number"}
                  value={paymentAmount}
                  variant={"outlined"}
                  onChange={(e) => onChangeSubscriptionAmount(e)}
                  inputProps={{ style: { textAlign: "center" } }}
                  helperText={
                    paymentAmountError !== "" ? paymentAmountError : ""
                  }
                  error={paymentAmountError !== ""}
                />
              </Grid>
              {coa_isSuperAdmin ? (
                <Grid item xs={6}>
                  <TextField
                    variant={"outlined"}
                    select
                    onChange={(e) => onChangePaymentMethod(e)}
                    label={"Type paiement"}
                    helperText={
                      paymentMethodError !== "" ? paymentMethodError : ""
                    }
                    error={paymentMethodError !== ""}
                  >
                    <MenuItem value={"paypal"}>Paypal</MenuItem>
                    <MenuItem value={"facture"}>Facture</MenuItem>
                    <MenuItem value={"other"}>Autre</MenuItem>
                  </TextField>
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <TextField
                    variant={"outlined"}
                    select
                    onChange={(e) => onChangePaymentMethod(e)}
                    label={"Type paiement"}
                    helperText={
                      paymentMethodError !== "" ? paymentMethodError : ""
                    }
                    error={paymentMethodError !== ""}
                  >
                    <MenuItem value={"facture"}>Facture</MenuItem>
                    <MenuItem value={"other"}>Autre</MenuItem>
                  </TextField>
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  style={{ backgroundColor: "#857A73" }}
                  variant={"contained"}
                  color={"primary"}
                  onClick={(e) => handleNewMessage(e)}
                  fullWidth
                >
                  Envoyer votre message
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant={"contained"}
                  onClick={(e) => handleConfirmVip(e)}
                  fullWidth
                  style={{ backgroundColor: "#43a047", color: "white" }}
                >
                  Accepter la demande
                </Button>
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: "10%" }}>
                <Button
                  style={{ backgroundColor: "#f44336", color: "white" }}
                  variant={"contained"}
                  onClick={handleDialogOpen}
                  fullWidth
                >
                  Supprimer la demande
                </Button>
                <Dialog
                  open={open}
                  onClose={handleDialogClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  style={{ maxWidth: "100%" }}
                >
                  <DialogContent
                    style={{
                      maxWidth: "90%",
                      paddingRight: "5%",
                      paddingLeft: "5%",
                    }}
                  >
                    <DialogContentText
                      id="alert-dialog-description"
                      style={{ color: "black", fontSize: 20 }}
                    >
                      Êtes-vous sûr de vouloir supprimer la demande d'abonnement
                      VIP de l'utilisateur ?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    style={{
                      maxWidth: "92%",
                      paddingRight: "4%",
                      paddingLeft: "4%",
                    }}
                  >
                    <Button
                      onClick={handleDialogClose}
                      color="primary"
                      autofocus
                    >
                      Annuler
                    </Button>
                    <Button
                      onClick={handleDeleteVipRequest}
                      style={{ color: "#f44336" }}
                    >
                      Supprimer
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Grid>
          </Container>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default RequestDetail;
