// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { sha256 } from "js-sha256";

const PrivateRouteAdmin = ({ component: Component, ...rest }) => {
  // Add your own authentication on the below line.

  let isAdmin = false;

  //Permet de vérigier si l'utilisateur est connecté en tant que coach
  if (localStorage.getItem("cbs_perform_token") !== null) {
    if (
      localStorage.getItem("cbs_perform_token_is_coach") === null ||
      localStorage.getItem("cbs_perform_token_is_coach") !==
        sha256(localStorage.getItem("cbs_perform_token").substring(162, 243))
    ) {
      isAdmin = false;
    } else {
      isAdmin = true;
    }
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

export default PrivateRouteAdmin;
